import React, {useMemo} from 'react';
import MDBox from '../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDBox';
import DefaultDoughnutChart from '../../../CompanyDashboardChartsCardsLayouts/Charts/DoughnutCharts/DefaultDoughnutChart';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import { CheckCircleOutline, Cancel, AccessTime, Folder } from '@mui/icons-material';
 

function DocAIQuoteBatchProcessView({ reportData }) {

 // Memoize the chart data so it's recalculated only when reportData changes
 const chartData = useMemo(() => {
  const transReportData = (data) => {
    const successCount = data?.Report?.success || 0;
    const failureCount = data?.Report?.failure || 0;

    return {
      docAIQuoteBatchReportStatus: {
        labels: ["Success", "Failed"],
        datasets: {
          label: "Quotes",
          data: [successCount, failureCount],
          backgroundColor: ["#4CB140", "#C9190B"],
        },
        cutout: 105,
      },
    };
  };

  return reportData ? transReportData(reportData) : {
    docAIQuoteBatchReportStatus: {
      labels: ["Success", "Failed"],
      datasets: {
        label: "Quotes",
        data: [0, 0],
        backgroundColor: ["#4CB140", "#C9190B"],
      },
      cutout: 105,
    },
  };
}, [reportData]); // Only recompute when reportData changes

  const { total_files, success, failure, processing_time } = reportData?.Report || {
    total_files: 0,
    success: 0,
    failure: 0,
    processing_time: 0
  };

  return (
    <MDBox py={3}>
      <Grid container spacing={3}>
        {/* Left side - Cards */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            {/* Total Files */}
            <Grid item xs={12} md={6}>
              <Card sx={{ backgroundColor: '#2D2A33', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.4)', borderRadius: '10px', height: '100%' }}>
                <CardContent sx={{ textAlign: 'center', padding: '24px' }}>
                  <Folder sx={{ fontSize: 40, color: '#FFC107' }} />
                  <Typography variant="h5" sx={{ color: '#FFFFFF', marginTop: '12px' }}>Total Files</Typography>
                  <Typography variant="h3" sx={{ color: '#FFFFFF' }}>{total_files}</Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Success */}
            <Grid item xs={12} md={6}>
              <Card sx={{ backgroundColor: '#2D2A33', boxShadow: '0 4px 20px rgba(0, 255, 100, 0.4)', borderRadius: '10px', height: '100%' }}>
                <CardContent sx={{ textAlign: 'center', padding: '24px' }}>
                  <CheckCircleOutline sx={{ fontSize: 40, color: '#4CB140' }} />
                  <Typography variant="h5" sx={{ color: '#FFFFFF', marginTop: '12px' }}>Success</Typography>
                  <Typography variant="h3" sx={{ color: '#FFFFFF' }}>{success}</Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Failure */}
            <Grid item xs={12} md={6}>
              <Card sx={{ backgroundColor: '#2D2A33', boxShadow: '0 4px 20px rgba(255, 0, 0, 0.4)', borderRadius: '10px', height: '100%' }}>
                <CardContent sx={{ textAlign: 'center', padding: '24px' }}>
                  <Cancel sx={{ fontSize: 40, color: '#C9190B' }} />
                  <Typography variant="h5" sx={{ color: '#FFFFFF', marginTop: '12px' }}>Failure</Typography>
                  <Typography variant="h3" sx={{ color: '#FFFFFF' }}>{failure}</Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Processing Time */}
            <Grid item xs={12} md={6}>
              <Card sx={{ backgroundColor: '#2D2A33', boxShadow: '0 4px 20px rgba(0, 0, 255, 0.4)', borderRadius: '10px', height: '100%' }}>
                <CardContent sx={{ textAlign: 'center', padding: '24px' }}>
                  <AccessTime sx={{ fontSize: 40, color: '#00BCD4' }} />
                  <Typography variant="h5" sx={{ color: '#FFFFFF', marginTop: '12px' }}>Processing Time</Typography>
                  <Typography variant="h3" sx={{ color: '#FFFFFF' }}>{processing_time}s</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {/* Right side - Doughnut chart */}
        <Grid item xs={12} md={6}>
          <MDBox mb={3} sx={{ height: '100%' }}>
            <DefaultDoughnutChart
              icon={{ color: "secondary", component: "pie_chart" }}
              title="DocAI Quote Batch Process Quotes Status"
              height="20rem"
              chart={chartData.docAIQuoteBatchReportStatus}
            />
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  )
}

export default DocAIQuoteBatchProcessView