// routeTitles.js
const RouteTitles = {
    '/login': 'SignIn - Innovon Tech',
    '/claimcapture': 'Claim Capture - Innovon Tech',
    '/claimsuccess': 'Claim Success - Innovon Tech',
    '/quotesuccess': 'Quote Success - Innovon Tech',
    '/companysuccess': 'Company Success - Innovon Tech',
    '/fileextract': 'File Upload - Innovon Tech',
    '/aboutus': 'About Us - Innovon Tech',
    '/signin': 'Sign In - Innovon Tech',
    '/smart-claim': 'SmartClaim - Innovon Tech',
    '/docai/claim': 'FNOL Automation Software for Insurance | Innovontech',
    '/Mail2Claim': 'Mail2Claim - Innovon Tech',
    '/doc-ai-med-bill': 'DocAI Medbill Extraction Innovon Tech Solution | Innovontech',
    '/insur-ai': 'P&C Insurance Solutions Innovon Tech | Innovontech',
    '/insur-ai/Agent': 'InsurAI Agent - Innovon Tech',
    '/all/services': 'All Services - Innovon Tech',
    '/doc-ai-loss-run-report': 'DocAI Loss Run Extraction Using AI | Innovontech',
    '/insuredsignup': 'Insured Signup - Innovon Tech',
    '/Demo/SmartQuote': 'Demo SmartQuote - Innovon Tech',
    '/DocAIQuote': 'DocAI Quote - Innovon Tech',
    '/requestdemo': 'Request Demo - Innovon Tech',
    '/TermsofUse': 'Terms of Use - Innovon Tech',
    '/demo/insur-ai': 'IVAN (Innovon Virtual Assistant) Unlock the Power of InsurAI Services for P&C Insurance - Innovon Tech',
    '/Demo/Mail2Claim': 'Mail2Claim-Innovon Tech InsurAI End -to-End FNOL processing, Zero Touch. - Innovon Tech',
    '/Demo/doc-ai-loss-run-report': 'Reimagine Risk Assessment with AI-Advanced Loss Run Analysis - Innovon Tech',
    '/demo/DocAI': 'Automating Claims Intake with DocAI Claim - Innovon Tech',
    '/Demo/doc-ai-med-bill': 'DocAI Med Bill Demo - Innovon Tech',
    '/addUser': 'Add User - Innovon Tech',
    '/mail-2-quote': 'Submission Workflow With Ai | Email to Submission | Innovontech',
    '/Demo/mail-2-quote': 'Mail2Quote Demo - Innovon Tech',
    '/UserDashboard': 'User Admin - Innovon Tech',
    '/docai/idcardextraction': 'DocAI ID Extraction - Innovon Tech',
    '/demo/idcardextraction': `Innovon's Next-Gen AI IDP ID Card Extraction with Speed & Precision - Innovon Tech`,
    '/SmartQuote': 'SmartQuote - Innovon Tech',
    '/Demo/DocAIQuote': 'Revolutionizing Insurance Submission - Innovon Tech',
    '/insur-admin-platform': 'Insurer Admin Solutions Innovon Tech | Innovontech',
    '/SucessMessage': 'Success Message - Innovon Tech',
    '/dashboard/*': 'Dashboard - Innovon Tech',
    '/customercompany': 'Customer Insurance Company - Innovon Tech',
    '/innovonadmindashboard': 'Innovon Admin Dashboard - Innovon Tech',
    // Add more routes as needed
  };
  
  export default RouteTitles;
  