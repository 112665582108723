export const states = [
        { value: "AL", select: "Alabama" },
        { value: "AK", select: "Alaska" },
        { value: "AS", select: "American Samoa" },
        { value: "AZ", select: "Arizona" },
        { value: "AR", select: "Arkansas" },
        { value: "CA", select: "California" },
        { value: "CO", select: "Colorado" },
        { value: "CT", select: "Connecticut" },
        { value: "DE", select: "Delaware" },
        { value: "DC", select: "District Of Columbia" },
        { value: "FM", select: "Federated States Of Micronesia" },
        { value: "FL", select: "Florida" },
        { value: "GA", select: "Georgia" },
        { value: "GU", select: "Guam" },
        { value: "HI", select: "Hawaii" },
        { value: "ID", select: "Idaho" },
        { value: "IL", select: "Illinois" },
        { value: "IN", select: "Indiana" },
        { value: "IA", select: "Iowa" },
        { value: "KS", select: "Kansas" },
        { value: "KY", select: "Kentucky" },
        { value: "LA", select: "Louisiana" },
        { value: "ME", select: "Maine" },
        { value: "MH", select: "Marshall Islands" },
        { value: "MD", select: "Maryland" },
        { value: "MA", select: "Massachusetts" },
        { value: "MI", select: "Michigan" },
        { value: "MN", select: "Minnesota" },
        { value: "MS", select: "Mississippi" },
        { value: "MO", select: "Missouri" },
        { value: "MT", select: "Montana" },
        { value: "NE", select: "Nebraska" },
        { value: "NV", select: "Nevada" },
        { value: "NH", select: "New Hampshire" },
        { value: "NJ", select: "New Jersey" },
        { value: "NM", select: "New Mexico" },
        { value: "NY", select: "New York" },
        { value: "NC", select: "North Carolina" },
        { value: "ND", select: "North Dakota" },
        { value: "MP", select: "Northern Mariana Islands" },
        { value: "OH", select: "Ohio" },
        { value: "OK", select: "Oklahoma" },
        { value: "OR", select: "Oregon" },
        { value: "PW", select: "Palau" },
        { value: "PA", select: "Pennsylvania" },
        { value: "PR", select: "Puerto Rico" },
        { value: "RI", select: "Rhode Island" },
        { value: "SC", select: "South Carolina" },
        { value: "SD", select: "South Dakota" },
        { value: "TN", select: "Tennessee" },
        { value: "TX", select: "Texas" },
        { value: "UT", select: "Utah" },
        { value: "VT", select: "Vermont" },
        { value: "VI", select: "Virgin Islands" },
        { value: "VA", select: "Virginia" },
        { value: "WA", select: "Washington" },
        { value: "WV", select: "West Virginia" },
        { value: "WI", select: "Wisconsin" },
        { value: "WY", select: "Wyoming" }
]