import React, { useState, useEffect } from "react";
import { Event, LocationOn, Person, Assignment } from "@mui/icons-material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import
  {
    Grid, createTheme,
    ThemeProvider,
    useMediaQuery, Box, Stepper,
    Step,
    StepLabel,
    Paper, Button, Typography, AppBar, Toolbar, Accordion, AccordionSummary, AccordionDetails, Container, Tooltip, MenuItem, OutlinedInput, InputLabel, FormControl, TextField, Select, Switch, Card, FormHelperText, Backdrop, CircularProgress, Snackbar,
    Alert,
  } from "@mui/material";
import dayjs from 'dayjs';
// import InfoOutlinedIcon from '@mui/icons-material/ErrorOutline'; // Import error icon
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { states } from "../data/states";
import EditIcon from "@mui/icons-material/Edit";
import { useFormControl } from "@mui/material/FormControl";
import FileUpload from "../components/fileupload.js";
import Header from "../components/header.js";
import Footer from "../components/footer.js";
import "./pagesstyles.css";
import axios from "axios";
import lightbulbicon from "../assets/lightbulbicon.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GroupIcon from "@mui/icons-material/Group";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import StyledButtonComponent from "../components/StyledButton.js";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
const LOSSDESCRIPTION_REGEX = /^.{10,500}$/s;
const STREETNUMBER_REGEX = /^(?=.*[0-9])[A-Z0-9]{1,12}$/;
// eslint-disable-next-line
const STREETNAME_REGEX =
  /^(?=.*[a-zA-Z])[A-Za-z0-9.,!@#$%^&*()-_+=|{}[\]:;"'<>,.?\/\\ ]{3,80}$/;
const CITY_REGEX = /^(?=.*[a-z])[A-Za-z ]{3,50}$/;
const ZIP_REGEX = /^(?:\d{5}(?: \d{4})?|\d{10})$/;

const stepsForInsured = [
  { label: "Loss Details", icon: <Event /> },
  { label: "Loss Location", icon: <LocationOn /> },
  { label: "Claim Reporter", icon: <Person /> },
  { label: "Review Details", icon: <Assignment /> },
];

const stepsForNonInsured = [
  { label: "Loss Details", icon: <Event /> },
  { label: "Loss Location", icon: <LocationOn /> },
  { label: "Claim Reporter", icon: <Person /> },
  { label: "Contact Details", icon: <ContactMailIcon /> },
  { label: "Review Details", icon: <Assignment /> },
];
const initialValues = {
  policy_holder_id: "",
  policy_number: "",
  loss_date_and_time: "",
  loss_type: "",
  loss_property: "",
  loss_damage_description: "",
  street_number: "",
  street_name: "",
  loss_city: "",
  loss_state: "",
  loss_zip: "",
  loss_country: "",
  police_fire_contacted: false,
  report_number: null,
  claim_reported_by: "",
  claim_witness_document_names: [],
  claim_witness_document_urls: [],
  claim_witness_document_name_url: [],
  claim_process_document_name: [],
  claim_process_document_url: [],
  claim_process_document_name_url: [],
  claim_storage_type: "",
  claimant_first_name: "",
  claimant_middle_name: "",
  claimant_last_name: "",
  claimant_email: "",
  claimant_phone: "",
  claimant_relationship_with_insured: "",
  claimant_other_relationship_with_insured: "",
  claimant_street_number: "",
  claimant_street_name: "",
  claimant_city: "",
  claimant_zip: "",
  claimant_state: "",
  claimant_country: "",
  claimant_proof_of_identity: "",
  claimant_proof_of_identity_number: "",
};

const Claimcapture =() =>
{
  const { company } = useSelector( ( state ) => state.auth ); console.log( company );
  /* eslint-disable no-unused-vars */
  const [ claimFormValue, setClaimFormValue ] = useState( initialValues );
  const [ reportedAs, setReportedAs ] = React.useState( false );
  const [ checked, setChecked ] = React.useState( false );
  const [ activeStep, setActiveStep ] = useState( 0 );
  const [ anchorElNav, setAnchorElNav ] = React.useState( null );
  const [ anchorElUser, setAnchorElUser ] = React.useState( null );
  const [ selectProperty, setSelectProperty ] = useState( false );
  const [ isDisabled, setIsDisabled ] = useState( true )
  const [ user, setUser ] = useState( "" );
  const [ localCompany, setLocalCompany ] = useState( "" );
  const [ lossState, setLossState ] = useState( "" );
  const [ lossCountry, setLossCountry ] = useState( "" );
  const [ isSubmitDisabled, setIsSubmitDisabled ] = useState( false );
  const [ selectedFiles, setSelectedFiles ] = useState( [] );
  const [ previews, setPreviews ] = useState( [] );
  const [ filesUploadedInChild, setFilesUploadedInChild ] = useState( false );
  const [ uploadIn, setUploadIn ] = useState( "portal" )
  const [ getFileNameUrlSuccess, setGetFileNameUrlSuccess ] = useState( false );
  const [ processSubmit, setProcessSubmit ] = useState( false );
  const [ processLocation, setProcessLocation ] = useState( false );
  const [ error, setError ] = useState( null );
  const [ errors, setErrors ] = useState( {} ); // non-insured contact details validator
  const [ touched, setTouched ] = useState( {} );
  const [ canSaveStep4, setCanSaveStep4 ] = useState( false );
  const [ nonInsuredEmail, setNonInsuredEmail ] = useState( "" );
  const [ isInsured, setISInsured ] = useState( "" );
  const [ stepsCompleted, setStepsCompleted ] = useState( [] );
  const [ locationAddress, setLocationAddress ] = useState( "" );
  const [ isAddressValid, setIsAddressValid ] = useState( false ); // State for validation button
  const [ isValidatingAddress, setIsValidatingAddress ] = useState( false ); // Loading state
  const [ snackbarOpen, setSnackbarOpen ] = useState( false );
  const [ snackbarMessage, setSnackbarMessage ] = useState( '' );
  const [ snackbarSeverity, setSnackbarSeverity ] = useState( 'success' );
  const [ suggestedAddress, setSuggestedAddress ] = useState( null );
  const [ spittedAddress, setSpittedAddress ] = useState( null );
  const [ showAddress, setShowAddress ] = useState( false );
  const [ confirmAddress, setConfirmAddress ] = useState( false );
  const [ editAddress, setEditAddress ] = useState( false );
  const [ confirmedAddressValues, setConfirmedAddressValues ] = useState( null );
  const [ locationApiCalled, setLocationApiCalled ] = useState( false );

  const [selectedLocation, setSelectedLocation] = useState('America/New_York');
  const getMaxDateTimeForLocation = (location) => dayjs().tz(location);
  // const maxDateTime = dayjs().tz(selectedLocation);
  const maxDateTime = dayjs().tz(selectedLocation); 
  const axiosInstance = axios.create( {
    baseURL: process.env.REACT_APP_URL,
    withCredentials: true
  } );

  const theme = createTheme( {
    breakpoints: {
      values: {
        xs: 0,
        sm: 650,
        md: 970,
        lg: 1280,
        xl: 1920,
      },
    },
  } );
  const isMobile = useMediaQuery( theme.breakpoints.down( "sm" ) );
  let {
    policy_holder_id,
    policy_number,
    loss_date_and_time,
    loss_type,
    loss_property,
    loss_damage_description,
    street_number,
    street_name,
    loss_city,
    loss_state,
    loss_zip,
    loss_country,
    police_fire_contacted,
    report_number,
    claim_reported_by,
    claimant_first_name,
    claimant_middle_name,
    claimant_last_name,
    claimant_phone,
    claimant_relationship_with_insured,
    claimant_other_relationship_with_insured,
    claimant_street_number,
    claimant_street_name,
    claimant_city,
    claimant_zip,
    claimant_state,
    claimant_country,
    claimant_proof_of_identity,
    claimant_proof_of_identity_number,
  } = claimFormValue;

  const displayFieldNames = [
    "Policy Number",
    "Property Address",
    "Loss Date and Time",
    "Type of Loss",
    "Loss Description",
    "Loss Location",
    // "Street Number",
    // "Street Name",
    // "City",
    // "State",
    // "Zip Code",
    // "Country",
    "Police/Fire Department Contacted?",
    "Report Number",
    "Reported By",
    "First Name",
    "Middle Name",
    "Last Name",
    "Relationship to Insured",
    "Email Id",
    "Phone Number",
    "Address",
    "Proof of Identity",
    "Proof of Identity Number",
  ]
  const showFormateedAddresOnREview = ( street_number, street_name, loss_city, loss_state, loss_zip, loss_country ) =>
  {
    return `${ street_number } ${ street_name }, ${ loss_city }, ${ loss_state }, ${ loss_country }, ${ loss_zip }`;
  };

  const fieldMapping = {
    "Policy Number": "policy_number",
    "Loss Date and Time": "loss_date_and_time",
    "Type of Loss": "loss_type",
    "Property Address": "loss_property",
    "Loss Description": "loss_damage_description",
    "Loss Location": "loss_location", // Add this line
    // "Loss Location": `${showFormateedAddresOnREview(street_number, street_name,loss_city, loss_state,loss_zip, loss_country )}`,
    // "Street Number": "street_number",
    // "Street Name": "street_name",
    // "City": "loss_city",
    // "State": "loss_state",
    // "Zip Code": "loss_zip",
    // "Country": "loss_country",
    "Reported By": `${ isInsured === "yes" ? "Insured" : "Non-Insured" }`,
    "Police/Fire Department Contacted?": `${ police_fire_contacted === true ? "True" : "False" }`,
    "Report Number": "report_number",
  };

  const contactMapping = {
    "First Name": "claimant_first_name",
    "Middle Name": "claimant_middle_name",
    "Last Name": "claimant_last_name",
    "Relationship to Insured": "claimant_relationship_with_insured",
    "Email Id": "claimant_email",
    "Phone Number": "claimant_phone",
    Address: "",
    "Proof of Identity": "claimant_proof_of_identity",
    "Proof of Identity Number": "claimant_proof_of_identity_number",
  };

  function renderValue ( fieldName )
  {
    if ( fieldName === "Reported By" )
    {
      return isInsured === "yes" ? "Insured" : "Non-Insured";
    } else if ( fieldName === "Police/Fire Department Contacted?" )
    {
      return police_fire_contacted === true ? "True" : "False";
    } else
    {
      return claimFormValue[ fieldMapping[ fieldName ] ];
    }
  }

  function renderContactValue ( fieldName )
  {
    if ( fieldName === "Email Id" )
    {
      const noninsuredemail = localStorage.getItem( "NonInsuredEmail" );
      return noninsuredemail && nonInsuredEmail ? nonInsuredEmail : "";
    }
    if ( fieldName === "Relationship to Insured" )
    {
      return claimant_relationship_with_insured === "Other" ? claimant_other_relationship_with_insured : claimant_relationship_with_insured
    }
    if ( fieldName === "Address" )
    {
      return `${ claimant_street_number } ${ claimant_street_name } ${ claimant_city } ${ claimant_state } ${ claimant_zip } ${ claimant_country }`;
    }

    return claimFormValue[ contactMapping[ fieldName ] ];

  }
  /* eslint-disable no-unused-vars */
  const [ validLossType, setValidLossType ] = useState( false );
  const [ validInsuredPropertyAddress, setValidInsuredPropertyAddress ] = useState( false );
  const [ validTextArea, setValidTextArea ] = useState( false );
  const [ validStreetNumber, setValidStreetNumber ] = useState( false );
  const [ validStreetName, setValidStreetName ] = useState( false );
  const [ validCity, setValidCity ] = useState( false );
  const [ validState, setValidState ] = useState( false );
  const [ validZip, setValidZip ] = useState( false );
  const [ validCountry, setValidCountry ] = useState( false );
  const [ validClaimReporter, setValidClaimReporter ] = useState( false );
  const [ validLossDateAndTime, setValidLossDateAndTime ] = useState( false );
  const [ lossTypeError, setLossTypeError ] = useState( false );
  const [ insuredPropertyAddressError, setInsuredPropertyAddressError ] = useState( false );
  const [ textAreaError, setTextAreaError ] = useState( false );
  const [ streetNumberError, setStreetNumberError ] = useState( false );
  const [ streetNameError, setStreetNameError ] = useState( false );
  const [ cityError, setCityError ] = useState( false );
  const [ StateError, setStateError ] = useState( false );
  const [ zipError, setZipError ] = useState( false );
  const [ countryError, setCountryError ] = useState( false );
  const [ claimReporterError, setClaimReporterError ] = useState( false );
  const [ lossDateAndTimeError, setLossDateAndTimeError ] = useState( true );
  const [ locationConfirmed, setLocationConfirmed ] = useState( null ); // Track confirmation (null: no action, true: confirmed, false: rejected)
  const [ streetNameTouched, setStreetNameTouched ] = useState( false );
  const [ addressError, setAddressError ] = useState( '' )
  // address validation
  const [ Validate, setValidate ] = useState( true )

  const [ Address, setAddress ] = useState( {
    street_number: '',
    streetName: '',
    lossCity: '',
    lossState: '',
    lossZip: '',
    lossCountry: ''
  } );

  // const [errors, setErrors] = useState({
  //   street_number: false,
  //   streetName: false,
  //   lossCity: false,
  //   lossState: false,
  //   lossZip: false,
  //   lossCountry: false
  // });

  const [ validationState, setValidationState ] = useState( {
    street_number: true,
    streetName: true,
    lossCity: true,
    lossState: true,
    lossZip: true,
    lossCountry: true
  } );

  const [ locationStatus, setLocationStatus ] = useState( 'Confirm Location' );
  const [ combinedAddress, setCombinedAddress ] = useState( '' );
  const [ UpdatedAddress, setUpdatedAddress ] = useState( false )
  const [ newAddress, setNewAddress ] = useState( false )

  const ValidateAddress = async () =>
  {

    console.log( claimFormValue, "locationConfirmedlocationConfirmedlocationConfirmed" );

    const isValid = Object.values( Address ).every( ( value ) => value );
    console.log( Address, 'isValidisValidisValidisValidisValid' );

    try
    {
      const response = await axiosInstance.post( 'validate_address/', { address: combinedAddress } );
      if ( response.status === 200 )
      {
        setNewAddress( true )
        setValidate( false )
        setAddressError( '' )
        console.log( 'Validated Address:', response );
        setUpdatedAddress( response.data.validated_address )
        setLocationAddress( {
          street_number: response.data.splitted_address.street_number,
          street_name: response.data.splitted_address.street_name,
          loss_city: response.data.splitted_address.city,
          loss_state: response.data.splitted_address.state,
          loss_zip: response.data.splitted_address.zip_code,
          loss_country: response.data.splitted_address.country
        } );
        setLocationAddress( {
          street_number: response.data.address.street_number,
          street_name: response.data.address.street_name,
          loss_city: response.data.address.city,
          loss_state: response.data.address.state,
          loss_zip: response.data.address.zipcode,
          loss_country: response.data.address.country
        } );
        console.log( response.data.splitted_address.country, 'response.data.splitted_address.country' );
        console.log( 'Splitted Address:', locationAddress );
        localStorage.setItem( 'validatedAddress', JSON.stringify( response.data.data.validated_address ) );
        setLocationStatus( 'Location Confirmed' );
        console.log( response.error );
      }
    } catch ( error )
    {
      if ( addressError === 'Address not validated' )
      {
        setAddressError( '' )
        setNewAddress( true )
        setValidate( false )
      } else
      {

        setAddressError( 'Address not validated' )
      }
      // console.error('Address not validated');
    }
  };

  const mandatoryFields = [
    "claimant_first_name",
    "claimant_last_name",
    "claimant_relationship_with_insured",
    "claimant_phone",
    "claimant_street_number",
    "claimant_street_name",
    "claimant_city",
    "claimant_state",
    "claimant_zip",
    "claimant_country",
    "claimant_proof_of_identity",
    "claimant_proof_of_identity_number",
  ];
  const onBlur = ( e ) =>
  {
    const { name } = e.target;
    setTouched( ( prevState ) => ( {
      ...prevState,
      [ name ]: true,
    } ) );
  };
  const validateField = ( name, value ) =>
  {
    let tempErrors = { ...errors };
    let errorMessage = "";

    switch ( name )
    {
      case 'claimant_first_name':
        errorMessage = validateFirstName( value );
        break;
      case 'claimant_relationship_with_insured':
        errorMessage = validateRelationship( value );
        break;
      case 'claimant_street_number':
        errorMessage = validateStreetNumber( value );
        break;
      case 'claimant_state':
        errorMessage = validateState( value );
        break;
      case 'claimant_street_name':
        errorMessage = validateStreetName( value );
        break;
      case 'claimant_zip':
        errorMessage = validateZip( value );
        break;
      case 'claimant_last_name':
        errorMessage = validateLastName( value );
        break;
      case 'claimant_phone':
        errorMessage = validatePhone( value );
        break;
      case 'claimant_city':
        errorMessage = validateCity( value );
        break;
      case 'claimant_country':
        errorMessage = validateCountry( value );
        break;
      case 'claimant_proof_of_identity':
        errorMessage = validateProofOfIdentity( value );
        break;
      case 'claimant_proof_of_identity_number':
        errorMessage = validateProofOfIdentityNumber( value );
        break;
      case 'claimant_other_relationship_with_insured':
        errorMessage = validateOtherRelationship( value );
        break;
      default:
        break;
    }
    tempErrors[ name ] = errorMessage;
    setErrors( tempErrors );
    setTouched( ( prevState ) => ( {
      ...prevState,
      [ name ]: true,
    } ) );
  };

  const validateFirstName = ( value ) =>
  {
    if ( !value || value.length < 2 || value.length > 20 )
    {
      return "First name must be in between 2-20 characters";
    }
    return ""; // No error
  };

  const validateRelationship = ( value ) =>
  {
    if ( !value || value.length < 2 || value.length > 20 )
    {
      return "please select the one value";
    }
    return ""; // No error
  };

  const validateOtherRelationship = ( value ) =>
  {
    if ( !value || value.length < 2 || value.length > 50 )
    {
      return "Relationship must in between 2-50 characters";
    }
    return ""; // No error
  };

  const validateStreetNumber = ( value ) =>
  {
    if ( !value || !/^\d+$/.test( value ) )
    {
      return "Street number is not valid";
    }
    return ""; // No error
  };

  const validateState = ( value ) =>
  {
    if ( !value )
    {
      return "State is required";
    }
    return ""; // No error
  };

  const validateStreetName = ( value ) =>
  {
    if ( !value || value.length < 2 || value.length > 50 )
    {
      return "Street name must be in between 2-50 characters";
    }
    return ""; // No error
  };

  const validateZip = ( value ) =>
  {
    if ( !value || value.length !== 5 || !/^\d{5}$/.test( value ) )
    {
      return "ZIP code must be 5 digits";
    }
    return ""; // No error
  };

  const validateLastName = ( value ) =>
  {
    if ( !value || value.length < 2 || value.length > 20 )
    {
      return "Last name must be in between 2-20 characters";
    }
    return ""; // No error
  };

  const validatePhone = ( value ) =>
  {
    if ( !value || !/^\d{10}$/.test( value ) )
    {
      return "Phone number must be 10 digits";
    }
    return ""; // No error
  };

  const validateCity = ( value ) =>
  {
    if ( !value || value.length < 2 || value.length > 50 )
    {
      return "City must be in between 2-50 characters";
    }
    return ""; // No error
  };

  const validateCountry = ( value ) =>
  {
    if ( !value )
    {
      return "Country is required";
    }
    return ""; // No error
  };
  const validateProofOfIdentity = ( value ) =>
  {
    if ( !value )
    {
      return "Proof of identity is required";
    }
    return ""; // No error
  };

  const validateProofOfIdentityNumber = ( value ) =>
  {
    if ( !value )
    {
      return "Proof of identity number is required";
    }
    return ""; // No error
  };

  useEffect( () =>
  {
    validateForm();
    // eslint-disable-next-line 
  }, [ claimFormValue ] );

  const validateForm = () =>
  {
    let tempErrors = {};
    let valid = true;
    let allMandatoryFieldsFilled = true; // Flag to check if all mandatory fields are filled


    for ( const [ name, value ] of Object.entries( claimFormValue ) )
    {
      validateField( name, value );
      if ( errors[ name ] )
      {
        valid = false; // If any field has an error, form is invalid
      }
      if ( mandatoryFields.includes( name ) && !value )
      {
        allMandatoryFieldsFilled = false; // Set flag to false if a mandatory field is not filled
      }
    }
    if ( claimant_relationship_with_insured === 'Other' )
    {
      validateField( 'claimant_other_relationship_with_insured', claimant_other_relationship_with_insured );
      if ( !claimant_other_relationship_with_insured )
      {
        allMandatoryFieldsFilled = false;
      }
    }
    setCanSaveStep4( valid && allMandatoryFieldsFilled );
  };
  //validations
  // useEffect(() => {
  //   setLossTypeError(false);
  //   setValidLossType(true);
  // }, [loss_type]);

  useEffect( () =>
  {
    setValidLossType( loss_type !== '' && loss_type !== null );
  }, [ loss_type ] );


  useEffect( () =>
  {
    setValidInsuredPropertyAddress( loss_property !== '' && loss_property !== null );
  }, [ loss_property ] );

  // useEffect(() => {
  //   setInsuredPropertyAddressError(false);
  //   setValidInsuredPropertyAddress(true);
  // }, [loss_property]);

  useEffect( () =>
  {
    setStateError( false );
    setValidState( true );
  }, [ loss_state ] );

  useEffect( () =>
  {
    setCountryError( false );
    setValidCountry( true );
  }, [ loss_country ] );

  useEffect( () =>
  {
    setClaimReporterError( false );
    setValidClaimReporter( true );
  }, [ claim_reported_by ] );

  useEffect( () =>
  {
    setValidTextArea( LOSSDESCRIPTION_REGEX.test( loss_damage_description ) );
  }, [ loss_damage_description ] );

  useEffect( () =>
  {
    if ( validTextArea ) setTextAreaError( false );
    else if ( loss_damage_description !== "" ) setTextAreaError( true );
  }, [ validTextArea, loss_damage_description ] );

  useEffect( () =>
  {
    setValidStreetNumber( STREETNUMBER_REGEX.test( street_number ) );
  }, [ street_number ] );

  useEffect( () =>
  {
    if ( validStreetNumber ) setStreetNumberError( false );
    else if ( street_number !== "" ) setStreetNumberError( true );
  }, [ validStreetNumber, street_number ] );

  useEffect( () =>
  {
    setValidStreetName( STREETNAME_REGEX.test( street_name ) );
  }, [ street_name ] );


  useEffect( () =>
  {
    if ( validStreetName ) setStreetNameError( false );
    else if ( street_name !== "" ) setStreetNameError( true );
  }, [ validStreetName, street_name ] );

  // useEffect(() => {
  //   if (validStreetName) {
  //     setStreetNameError(false);
  //   } else if (streetNameTouched && (street_name !== "" || street_name !== null)) {
  //     setStreetNameError(true);
  //   }
  // }, [validStreetName, street_name, streetNameTouched]);

  useEffect( () =>
  {
    setValidCity( CITY_REGEX.test( loss_city ) );
  }, [ loss_city ] );

  useEffect( () =>
  {
    if ( validCity ) setCityError( false );
    else if ( loss_city !== "" ) setCityError( true );
  }, [ validCity, loss_city ] );

  useEffect( () =>
  {
    setValidZip( ZIP_REGEX.test( loss_zip ) );
  }, [ loss_zip ] );

  useEffect( () =>
  {
    if ( validZip ) setZipError( false );
    else if ( loss_zip !== "" ) setZipError( true );
  }, [ validZip, loss_zip ] );

  const onLossTypeSelectFocus = () =>
  {
    // if (loss_type.length === 0) {
    if ( !loss_type || loss_type?.loss_type === "" )
    {
      console.log( "clicked when focus", loss_type );
      setLossTypeError( true );
      setValidLossType( false );
    } else
    {
      setLossTypeError( false );
      setValidLossType( true );
    }
  };
  const onLossTypeSelectBlur = () =>
  {
    console.log( "clicked when blur", loss_type );
    // if (loss_type.length === 0) {
    if ( !loss_type || loss_type?.loss_type === "" )
    {

      setLossTypeError( false );
      setValidLossType( false );
    } else
    {
      setLossTypeError( false );
      setValidLossType( true );
    }
  };

  const onInsuredPropertyAddressSelectFocus = () =>
  {
    console.log( loss_property );
    if ( !loss_property || loss_property?.loss_property === "" )
    {
      setInsuredPropertyAddressError( true );
      setValidInsuredPropertyAddress( false );
    } else
    {
      setInsuredPropertyAddressError( false );
      setValidInsuredPropertyAddress( true );
    }
  };
  const onInsuredPropertyAddressSelectBlur = () =>
  {
    console.log( "clicked", loss_property );
    if ( !loss_property || loss_property?.loss_property === "" )
    {
      setInsuredPropertyAddressError( false );
      setValidInsuredPropertyAddress( false );
    } else
    {
      setInsuredPropertyAddressError( false );
      setValidInsuredPropertyAddress( true );
    }
  };

  const onLossStateSelectFocus = () =>
  {
    if ( !loss_state || loss_state?.loss_state === "" )
    {
      setStateError( true );
      setValidState( false );
    } else
    {
      setStateError( false );
      setValidState( true );
    }
  };
  const onLossStateSelectBlur = () =>
  {
    console.log( "clicked", loss_state );
    if ( !loss_state || loss_state?.loss_state === "" )
    {
      setStateError( false );
      setValidState( true );
    } else
    {
      setStateError( false );
      setValidState( true );
    }
  };

  const onLossCountrySelectFocus = () =>
  {
    if ( !loss_country || loss_country?.loss_country === "" )
    {
      setCountryError( true );
      setValidCountry( false );
    } else
    {
      setCountryError( false );
      setValidCountry( true );
    }
  };
  const onLossCountrySelectBlur = () =>
  {
    console.log( "clicked", loss_country );
    if ( !loss_country || loss_country?.loss_country === "" )
    {
      setCountryError( false );
      setValidCountry( true );
    } else
    {
      setCountryError( false );
      setValidCountry( true );
    }
  };

  const onClaimReporterSelectFocus = () =>
  {
    if ( !claim_reported_by || claim_reported_by?.claim_reported_by === "" )
    {
      setClaimReporterError( true );
      setValidClaimReporter( false );
    } else
    {
      setClaimReporterError( false );
      setValidClaimReporter( true );
    }
  };
  const onClaimReporterSelectBlur = () =>
  {
    console.log( "clicked", loss_country );
    if ( !claim_reported_by || claim_reported_by?.claim_reported_by === "" )
    {
      setClaimReporterError( false );
      setValidClaimReporter( true );
    } else
    {
      setClaimReporterError( false );
      setValidClaimReporter( true );
    }
  };

  const handleOnTextAreaFocus = ( e ) =>
  {
    if ( validTextArea ) setTextAreaError( false );
    else setTextAreaError( true );
  };
  const handleOnTextAreaBlur = () =>
  {
    console.log( "clicked" );
    if ( validTextArea || loss_damage_description.length === 0 )
      setTextAreaError( false );
    else if ( loss_damage_description.length !== 0 ) setTextAreaError( true );
  };

  const handleOnStreetNumberFocus = ( e ) =>
  {
    if ( validStreetNumber ) setStreetNumberError( false );
    else setStreetNumberError( true );
  };
  const handleOnStreetNumberBlur = () =>
  {
    console.log( "clicked" );
    if ( validStreetNumber || !street_number || street_number.length === 0 )
      setStreetNumberError( false );
    else if ( street_number.length !== 0 ) setStreetNumberError( true );
  };

  const handleOnStreetNameFocus = ( e ) =>
  {
    console.log( "street name focused" );
    if ( validStreetName ) setStreetNameError( false );
    else setStreetNameError( true );
  };

  const handleOnStreetNameBlur = () =>
  {
    console.log( "street name blur" );
    // if (validStreetName || street_name === "" || street_name === null) setStreetNameError(false);
    if ( validStreetName || !street_name || street_name.length === 0 )
      setStreetNumberError( false );
    else if ( street_name.length !== 0 ) setStreetNameError( true );
  };

  const handleOnCityFocus = ( e ) =>
  {
    if ( validCity ) setCityError( false );
    else setCityError( true );
  };
  const handleOnCityBlur = () =>
  {
    console.log( "clicked" );
    if ( validCity || loss_city === "" ) setCityError( false );
    else if ( loss_city.length !== 0 ) setCityError( true );
  };

  const handleOnZipFocus = ( e ) =>
  {
    if ( validZip ) setZipError( false );
    else setZipError( true );
  };
  const handleOnZipBlur = () =>
  {
    console.log( "clicked" );
    if ( validZip || loss_zip.length === 0 ) setZipError( false );
    else if ( loss_zip.length !== 0 ) setZipError( true );
  };

  function TextareaHelperText ()
  {
    const { focused } = useFormControl() || {};
    const helperText = React.useMemo( () =>
    {
      if ( focused )
      {
        return validTextArea
          ? ""
          : "Please enter a description between 10-500 characters";
      }
      return "";
    }, [ focused ] );
    return helperText;
  }

  function LossAddressHelperText ()
  {
    const { focused } = useFormControl() || {};
    const helperText = React.useMemo( () =>
    {
      if ( focused )
      {
        return validStreetNumber ? "" : "Enter a valid Street Number";
      }
      return "";
    }, [ focused ] );
    return helperText;
  }

  function LossStreetHelperText ()
  {
    const { focused } = useFormControl() || {};
    const helperText = React.useMemo( () =>
    {
      if ( focused )
      {
        return validStreetName ? "" : "Enter a valid Street Name";
      }
      return "";
    }, [ focused ] );
    return helperText;
  }

  function LossCityHelperText ()
  {
    const { focused } = useFormControl() || {};
    const helperText = React.useMemo( () =>
    {
      if ( focused )
      {
        return validCity ? "" : "Enter a valid City";
      }
      return "";
    }, [ focused ] );
    return helperText;
  }

  function LossZiptHelperText ()
  {
    const { focused } = useFormControl() || {};
    const helperText = React.useMemo( () =>
    {
      if ( focused )
      {
        return validZip ? "" : "Enter a valid 5 0r 10 digit Zipcode";
      }
      return "";
    }, [ focused ] );
    return helperText;
  }
  // end of validation



  const IdentityDetailsError = ( ( !!errors.claimant_proof_of_identity ) || ( !!errors.claimant_proof_of_identity_number ) || ( !!errors.claimant_social_security_number ) );
  const ContactDetailsError = ( ( !!errors.claimant_phone ) && ( touched.claimant_phone ) );
  const hasRelationshipError = ( ( !!errors.claimant_relationship_with_insured ) && ( touched.claimant_relationship_with_insured ) );
  const AddressDetailsError = ( ( touched.claimant_street_number ) && ( errors.claimant_street_number ) ) || ( ( touched.claimant_street_name ) && ( errors.claimant_street_name ) ) || ( ( touched.claimant_zip ) && ( errors.claimant_zip ) ) || ( ( touched.claimant_country ) && ( errors.claimant_country ) ) || ( ( touched.claimant_state ) && ( errors.claimant_state ) ) || ( ( touched.claimant_city ) && ( errors.claimant_city ) );
  const PersonalDetailsError = ( ( ( touched.claimant_first_name ) && ( errors.claimant_first_name ) ) || ( ( touched.claimant_last_name ) && ( errors.claimant_last_name ) ) );
  const [ isEmpty, setIsEmpty ] = useState( true );

  const onInputChange = ( e ) =>
  {
    const { name, value } = e.target;
    if ( name === "report_number" )
    {
      setIsEmpty( value )
      // Check if the value is empty or not and update the state
      if ( value.trim() === "" )
      {
        setNextButtonDisabled( true ); // Empty state
      } else
      {
        setNextButtonDisabled( false ); // Value present
      }

    }
    if ( name === "claimant_relationship_with_insured" && claimant_relationship_with_insured !== "Other" )
    {
      setClaimFormValue( ( prevState ) => ( {
        ...prevState,
        claimant_other_relationship_with_insured: "",
      } ) );
    }
    console.log( name, value );
    const isApiField = [
      "loss_city",
      "loss_state",
      "loss_zip",
      "loss_country",
    ].includes( name );
    // const isEmptyValue = value === "";
    // const isApiField = ["loss_city", "loss_state", "loss_zip", "loss_country"].includes(name);
    const isEmptyValue = value === "";

    if ( isApiField && isEmptyValue )
    {
      setAddress( ( prevState ) => ( {
        ...prevState,
        [ name ]: "",
      } ) );
    } else
    {
      setAddress( ( prevState ) => ( {
        ...prevState,
        [ name ]: value,
      } ) );
      validateField( name, value );
    }

    if ( value === "Insured" )
    {
      setReportedAs( false );
    } else if ( value === "claimant" )
    {
      setReportedAs( true );
    }
    if ( name === 'street_number' || name === 'street_name' )
    {
      const updatedStreetNumber = name === 'street_number' ? value : street_number;
      const updatedStreetName = name === 'street_name' ? value : street_name;
      setCombinedAddress( `${ updatedStreetNumber } ${ updatedStreetName }` );
    }
    updateClaimFormValue( { [ name ]: value } );

    if ( isApiField && isEmptyValue )
    {
      setClaimFormValue( ( prevState ) => ( {
        ...prevState,
        [ name ]: "",
      } ) );
    } else
    {
      setClaimFormValue( ( prevState ) => ( {
        ...prevState,
        [ name ]: value,
      } ) );
      validateField( name, value );
    }

    if ( value === "Insured" )
    {
      setReportedAs( false );
    } else if ( value === "claimant" )
    {
      setReportedAs( true );
    }

    if ( [ 'street_number', 'street_name', 'loss_zip' ].includes( name ) )
    {
      checkAddressValidity();
    }
    validateField( name, value );

    updateClaimFormValue( { [ name ]: value } );
  };

  // Function to check if address fields are filled
  const checkAddressValidity = () =>
  {
    setIsAddressValid(
      street_number &&
        street_name &&
        loss_zip &&
        validStreetNumber && // Check for errors
        validStreetName &&
        validZip
    );
  };

  console.log( "is address valids", isAddressValid );







  const onReviewChange = ( e ) =>
  {
    console.log( "onInputchange", e.target.value );
    let { name, value } = e.target;
    setClaimFormValue( { ...claimFormValue, [ name ]: value } );
  };

  useEffect( () =>
  {
    setUser( JSON.parse( localStorage.getItem( "user" ) ) );
  }, [] );

  useEffect( () =>
  {
    setLocalCompany( JSON.parse( localStorage.getItem( "company" ) ) );
  }, [] );
  useEffect( () =>
  {
    const isInsured = localStorage.getItem( "isInsured" );
    setISInsured( isInsured );
    const NonInsuredEmailFromLocalStorage = localStorage.getItem( "NonInsuredEmail" );
    if ( NonInsuredEmailFromLocalStorage )
    {
      setNonInsuredEmail( NonInsuredEmailFromLocalStorage );
      setClaimFormValue( prevState => ( {
        ...prevState,
        claimant_email: NonInsuredEmailFromLocalStorage
      } ) );
    }
  }, [] );
  const steps = isInsured === "yes" ? stepsForInsured : stepsForNonInsured;

  const [ nextButtonDisabled, setNextButtonDisabled ] = useState( false );

  useEffect( () =>
  {
    // Update the nextButtonDisabled state based on the police/fire checkbox and report number
    if ( police_fire_contacted === false )
    {
      setClaimFormValue( {
        ...claimFormValue,
        report_number: "",
      } );
    }
    console.log( report_number, 'report_number' );

    if ( police_fire_contacted === true )
    {

      setNextButtonDisabled( true );
    } else
    {
      setNextButtonDisabled( false ); // Enable otherwise
    }
    // eslint-disable-next-line 
  }, [ police_fire_contacted ] );




  const onSelectChange = ( e ) =>
  {
    const { name, value } = e.target;
    setClaimFormValue( ( prevState ) => ( {
      ...prevState,
      [ name ]: value,
    } ) );
    validateField( name, value ); // Validate field immediately on change
  };
  useEffect( () =>
  {
    setClaimFormValue( {
      ...claimFormValue,
      policy_holder_id: user.policy_holder_id,
      policy_number: user.policy_number,
    } );
    // eslint-disable-next-line 
  }, [ user ] );


  const navigate = useNavigate();


  const canSaveStep1 =
    [
      validLossDateAndTime,
      validLossType,
      validInsuredPropertyAddress,
      validTextArea,
    ].every( Boolean )



  const canSaveStep2 =
    [
      validStreetNumber,
      validStreetName,
      validCity,
      validState,
      validZip,
      validCountry,
    ].every( Boolean ) && confirmAddress;

  console.log( "can savestep2", canSaveStep2 );
  console.log( "validStreetNumber", validStreetNumber, "validStreetName", validStreetName, "validCity", validCity, "validState", validState, "validZip", validZip, "validCountry", validCountry );

  const canSaveStep3 = claim_reported_by !== "";

  const handleChangeBoolean = ( event ) =>
  {
    console.log( event.target.value );
    setChecked( event.target.checked );
    setClaimFormValue( {
      ...claimFormValue,
      police_fire_contacted: event.target.checked === true ? true : false,
    } );
  };

  const handleNext = () =>
  {
    window.scrollTo( 0, 0 );

    if ( !stepsCompleted.includes( activeStep ) )
    {
      setStepsCompleted( ( prevStepsCompleted ) =>
      {
        let newStepsCompleted = [ ...prevStepsCompleted, activeStep ];

        // Correct the conditional logic here:
        if ( ( isInsured === "yes" && activeStep === 2 ) ||
          ( isInsured !== "yes" && activeStep === 3 ) )
        {
          newStepsCompleted.push( newStepsCompleted.length ); // Push the next index
        }

        return newStepsCompleted;
      } );
    }

    setActiveStep( ( prevActiveStep ) => prevActiveStep + 1 );
  };

  // const handleGotoReview = () => {
  //   window.scrollTo(0, 0);
  //   setActiveStep(Stepper.length)
  //   setActiveStep(4);
  // }

  const handleGotoReview = () =>
  {
    window.scrollTo( 0, 0 );
    setActiveStep( steps.length - 1 ); // Go to the last step index
  };

  const onEditStep1 = () =>
  {
    window.scrollTo( 0, 0 );
    setActiveStep( 0 );
  }
  const onEditStep2 = () =>
  {
    window.scrollTo( 0, 0 );
    setActiveStep( 1 );
  }
  const onEditStep3 = () =>
  {
    window.scrollTo( 0, 0 );
    setActiveStep( 2 );
  }
  const onEditStep4 = () =>
  {
    window.scrollTo( 0, 0 );
    setActiveStep( 3 );
  }


  const handleBack = () =>
  {
    setValidate( true )
    window.scrollTo( 0, 0 ); // Scroll to the top of the page
    setActiveStep( ( prevActiveStep ) => prevActiveStep - 1 );
  };
  const [ locationCoordinates, setLocationCoordinates ] = useState( null );
  const [ locationDataState, setLocationDataState ] = useState( null );
  useEffect( () =>
  {
    if ( navigator.geolocation )
    {
      navigator.geolocation.getCurrentPosition(
        ( position ) =>
        {
          setLocationCoordinates( {
            longitude: position.coords.longitude,
            latitude: position.coords.latitude,
          } );
        },
        () =>
        {
          console.error( "Error getting user location, pls enable your location" );
          setError( { message: "Error getting user location, pls enable your location / fill manually" } )
        }
      );
    } else
    {
      console.error( "Geolocation is not supported by this browser." );
      setError( { message: "Geolocation is not supported by this browser" } )
    }
  }, [] );
  console.log( locationCoordinates );

  const formatAddress = ( address ) =>
  {
    // Filter out null, undefined, or empty string values
    const addressComponents = [
      address.street_number,
      address.street_name,
      address.city,
      address.state,
      address.zipcode,
      address.country
    ].filter( component => component );

    // Join the valid address components with a space
    return addressComponents.join( ' ' );
  };

  const url = `${ process.env.REACT_APP_URL }get-location/`;
  const makeLocationAPIRequest = async ( locationCoordinates ) =>
  {
    setProcessLocation( true );
    if ( !locationCoordinates || !locationCoordinates.latitude || !locationCoordinates.longitude )
    {
      setError( { message: "Location not available, please try again later." } );
      setProcessLocation( false );
      return; // Exit the function without making the API call
    }
    await axiosInstance.get( 'get-location/', {
      params: {
        latitude: locationCoordinates.latitude,
        longitude: locationCoordinates.longitude
      }
    } ).then( response =>
    {
      console.log( "location res", response );
      if ( response.data && response.data.address )
      {
        const formattedAddress = formatAddress( response.data.address );

        setSuggestedAddress( formattedAddress );
        setSpittedAddress( response.data.address );
        setShowAddress( true );
        setLocationDataState( formattedAddress );
        setLocationAddress( {
          street_number: response.data.address.street_number,
          street_name: response.data.address.street_name,
          loss_city: response.data.address.city,
          loss_state: response.data.address.state,
          loss_zip: response.data.address.zipcode,
          loss_country: response.data.address.country
        } );


        setProcessLocation( false );
      }
    } ).catch( err =>
    {
      console.log( err );
      setError( { message: "No location found, pls try again" } )
      setProcessLocation( false );
    } )
  };

  useEffect( () =>
  {
    setLossCountry( loss_country );
  }, [ loss_country ] );
  useEffect( () =>
  {
    setLossState( loss_state );
  }, [ loss_state ] );

  const updateClaimFormValue = async ( newValues ) =>
  {
    console.log( "new values", newValues );
    setClaimFormValue( prevValues => ( {
      ...prevValues,
      ...newValues
    } ) );
  };

  console.log( "updated location claim formvalues", claimFormValue );


  useEffect( () =>
  {
    if ( activeStep >= 1 )
    {
      setSelectProperty( true );
    } else
    {
      setSelectProperty( false );
    }
  }, [ activeStep ] );

  const [ selectedDateTime, setSelectedDateTime ] = useState( null );
  // const onDateTimeChange = ( date ) =>
  // {
  //   setSelectedDateTime( date );
  //   // setValidLossDateAndTime(true);
  //   if ( date )
  //   {
  //     const lossDate = new Date( date.$d );
  //     const month = String( lossDate.getMonth() + 1 ).padStart( 2, "0" );
  //     const formattedDate = lossDate.getFullYear() + "/" + month + "/" + lossDate.getDate() + "  " + lossDate.getHours() + ":" + lossDate.getMinutes() + ":" + lossDate.getSeconds();
  //     // setClaimFormValue({ ...claimFormValue, loss_date_and_time: lossDate.getFullYear() + "/" + month + "/" + lossDate.getDate() + "  " + lossDate.getHours() + ":" + lossDate.getMinutes() + ":" + lossDate.getSeconds(), });
  //     setClaimFormValue( { ...claimFormValue, loss_date_and_time: formattedDate } );
  //     console.log( "validation of date and time", isValidDateTime( formattedDate ) );
  //     setValidLossDateAndTime( isValidDateTime( formattedDate ) );
  //   } else
  //   {
  //     setClaimFormValue( { ...claimFormValue, loss_date_and_time: "" } );
  //     setValidLossDateAndTime( false );
  //   }
  // };
  const onDateTimeChange = (date) => {
    // Calculate maxDateTime dynamically to reflect the current time in the selected timezone
    const maxDateTime = dayjs().tz(selectedLocation).startOf('minute'); // Round down to the nearest minute
  
    if (date) {
      // Ensure selected date is in the correct timezone and rounded down to the nearest minute
      const selectedDate = dayjs(date.$d).tz(selectedLocation).startOf('minute'); 
  
      // Check if the selected date exceeds maxDateTime (i.e., future time)
      if (selectedDate.isAfter(maxDateTime)) {
        console.log("Selected date and time exceed the allowed maximum.");
        setValidLossDateAndTime(false);  // Mark it as invalid
        return; // Stop further processing if the time is in the future
      }
  
      // Proceed with setting the selected date and time
      setSelectedDateTime(date);
      const lossDate = new Date(date.$d);
      const month = String(lossDate.getMonth() + 1).padStart(2, "0");
      const formattedDate =
        lossDate.getFullYear() +
        "/" +
        month +
        "/" +
        lossDate.getDate() +
        " " +
        lossDate.getHours() +
        ":" +
        lossDate.getMinutes() +
        ":" +
        lossDate.getSeconds();
  
      setClaimFormValue({
        ...claimFormValue,
        loss_date_and_time: formattedDate,
      });
  
      console.log("validation of date and time", isValidDateTime(formattedDate));
      setValidLossDateAndTime(isValidDateTime(formattedDate));
    } else {
      setClaimFormValue({ ...claimFormValue, loss_date_and_time: "" });
      setValidLossDateAndTime(false);
    }
  };                                                                   
                                                                                                                                 
  const isValidDateTime = ( dateTimeString ) =>
  {
    const dateTimeRegex = /^\d{4}\/\d{1,2}\/\d{1,2}\s+\d{1,2}:\d{1,2}:\d{1,2}$/;
    return dateTimeRegex.test( dateTimeString );
  };

  const handleFilesUploadToAWSByPortal = ( selectedFiles, previews ) =>
  {
    setSelectedFiles( selectedFiles );
    const claimWitnessDocumentNames = selectedFiles.map( file => file.name );
    setClaimFormValue( prevState => ( {
      ...prevState,
      claim_witness_document_names: claimWitnessDocumentNames,
    } ) );
  }

  const handlewitnessFileRemove = ( fileName ) =>
  {
    // Filter out the removed file from selectedFiles
    const updatedFiles = selectedFiles.filter( file => file.name !== fileName );
    setSelectedFiles( updatedFiles );

    // Map the updated file list to names
    const updatedDocumentNames = updatedFiles.map( file => file.name );


    // Update claim form state with the updated document names
    setClaimFormValue( prevState => ( {
      ...prevState,
      claim_witness_document_names: updatedDocumentNames,
    } ) );

  }
  console.log( selectedFiles );
  console.log( claimFormValue );

  const handleSubmit = () =>
  {
    setProcessSubmit( true );
    const formData = new FormData();
    formData.append( 'policy_holder_id', claimFormValue.policy_holder_id );
    formData.append( 'policy_number', claimFormValue.policy_number );
    formData.append( 'loss_date_and_time', claimFormValue.loss_date_and_time );
    formData.append( 'loss_type', claimFormValue.loss_type );
    formData.append( 'loss_property', claimFormValue.loss_property );
    formData.append( 'loss_damage_description', claimFormValue.loss_damage_description );
    formData.append( 'street_number', claimFormValue.street_number );
    formData.append( 'street_name', claimFormValue.street_name );
    formData.append( 'loss_city', claimFormValue.loss_city );
    formData.append( 'loss_state', claimFormValue.loss_state );
    formData.append( 'loss_zip', claimFormValue.loss_zip );
    formData.append( 'loss_country', claimFormValue.loss_country );
    formData.append( 'police_fire_contacted', claimFormValue.police_fire_contacted );
    formData.append( 'report_number', claimFormValue.report_number );
    formData.append( 'claim_reported_by', isInsured === "yes" ? "Insured" : "Non-Insured" );
    formData.append( 'claim_storage_type', localCompany.claim_storage_type );
    formData.append( 'role', isInsured === "yes" ? "Insured" : "Non-Insured" );
    formData.append( 'email', isInsured === "yes" ? user.pol_email : nonInsuredEmail );

    if ( isInsured === "no" )
    {
      formData.append( 'First_Name', claimFormValue.claimant_first_name );
      formData.append( 'Middle_Name', claimFormValue.claimant_middle_name );
      formData.append( 'Last_Name', claimFormValue.claimant_last_name );
      formData.append( 'Mobile_Number', claimFormValue.claimant_phone );
      formData.append( 'relationship_with_insured', claimFormValue.claimant_relationship_with_insured === "Other" ? claimFormValue.claimant_other_relationship_with_insured : claimFormValue.claimant_relationship_with_insured );
      formData.append( 'Claimant_street_number', claimFormValue.claimant_street_number );
      formData.append( 'Claimant_street_name', claimFormValue.claimant_street_name );
      formData.append( 'Claimant_city', claimFormValue.claimant_city );
      formData.append( 'Claimant_zip', claimFormValue.claimant_zip );
      formData.append( 'Claimant_state', claimFormValue.claimant_state );
      formData.append( 'Claimant_country', claimFormValue.claimant_country );
      formData.append( 'Proof_of_Identity', claimFormValue.claimant_proof_of_identity );
      formData.append( 'Proof_of_Identity_Number', claimFormValue.claimant_proof_of_identity_number );
    }

    for ( let i = 0; i < selectedFiles.length; i++ )
    {
      formData.append( 'documents', selectedFiles[ i ] );
    }
    uploadFiles( formData );
  };

  const uploadFiles = async ( formData ) =>
  {
    await axiosInstance.post( 'add-claim/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    } )
      .then( response =>
      {
        setProcessSubmit( false );
        navigate( '/claimsuccess', { state: { claimid: JSON.stringify( response.data ) } } );
        setSelectedFiles( [] );
        setClaimFormValue( initialValues );

      } ).catch( error =>
      {
        console.log( "error", error );
      } )
  }

  const imageSrc = `data:image/${ localCompany.image_type };base64,${ localCompany.image_data }`

  // Function to handle step completion
  const handleStepComplete = ( stepIndex ) =>
  {
    if ( !stepsCompleted.includes( stepIndex ) )
    {
      setStepsCompleted( [ ...stepsCompleted, stepIndex ] );
    }
  };

  // Function to handle step click
  // const handleStepClick = (stepIndex) => {
  //   if (stepsCompleted.includes(stepIndex)) {
  //     if(stepIndex === stepsCompleted.length-1 && allStepsValid){
  //       setActiveStep(stepIndex);
  //     }else if (!stepIndex === stepsCompleted.length-1 && stepIndex< stepsCompleted.length-1){
  //       setActiveStep(stepIndex);
  //     }
  //   }
  // };

  let allStepsValid;
  if ( isInsured === "yes" )
  {
    allStepsValid = canSaveStep1 && canSaveStep2 && !nextButtonDisabled; // Include nextButtonDisabled
  } else
  {
    allStepsValid = canSaveStep1 && canSaveStep2 && canSaveStep4 && !nextButtonDisabled; // Include nextButtonDisabled
  }
  console.log( allStepsValid, canSaveStep1, canSaveStep2, canSaveStep3, canSaveStep4 );

  const handleStepClick = ( stepIndex ) =>
  {
    console.log( stepIndex );

    if ( stepsCompleted.includes( stepIndex ) )
    {
      console.log( stepIndex, stepsCompleted.length - 1 );

      if ( stepIndex === stepsCompleted.length - 1 )
      {
        // If it's the last step, check allStepsValid
        console.log( allStepsValid );

        if ( allStepsValid )
        {
          console.log( allStepsValid );

          setActiveStep( stepIndex );
        }
      } else
      {
        console.log( "excepted step" );

        // For any step other than the last, allow navigation
        setActiveStep( stepIndex );
      }
    }
  };
  // Calculate if all steps are valid (you might have more canSave variables)




  useEffect( () =>
  {
    // Fetch location on Step 2 if not already acted upon
    if ( activeStep === 1 && locationConfirmed === null && !locationApiCalled )
    {
      makeLocationAPIRequest( locationCoordinates );
      setLocationApiCalled( true );
    }
    // eslint-disable-next-line 
  }, [ activeStep, locationConfirmed, locationApiCalled ] );



  const handleConfirmAddress = ( spittedAddress ) =>
  {
    console.log( "clicked for changing fields", spittedAddress );
    setLocationDataState( "" );

    // Store the confirmed address values for comparison
    setConfirmedAddressValues( {
      street_number: spittedAddress.street_number || '',
      street_name: spittedAddress.street_name || '',
      loss_city: spittedAddress.city || '',
      loss_state: spittedAddress.state || '',
      // Handle both zip_code and zipcode
      loss_zip: spittedAddress.zip_code || spittedAddress.zipcode || '',
      loss_country: spittedAddress.country || '',
    } );

    setClaimFormValue( ( prevState ) => ( {
      ...prevState,
      street_number: spittedAddress.street_number || '',
      street_name: spittedAddress.street_name || '',
      loss_city: spittedAddress.city || '',
      loss_state: spittedAddress.state || '',
      loss_zip: spittedAddress.zip_code || spittedAddress.zipcode || '',
      loss_country: spittedAddress.country || '',
    } ) );

    const isValidStreetNumber = !!spittedAddress.street_number;
    const isValidStreetName = !!spittedAddress.street_name;
    const isValidCity = !!spittedAddress.city;
    const isValidState = !!spittedAddress.state;
    const isValidZip = !!spittedAddress.zip_code || !!spittedAddress.zipcode;
    const isValidCountry = !!spittedAddress.country;

    // Set validation states
    setValidStreetNumber( isValidStreetNumber );
    setValidStreetName( isValidStreetName );
    setValidCity( isValidCity );
    setValidState( isValidState );
    setValidZip( isValidZip );
    setValidCountry( isValidCountry );

    // Set confirmAddress based on all validation flags
    setConfirmAddress(
      isValidStreetNumber &&
        isValidStreetName &&
        isValidCity &&
        isValidState &&
        isValidZip &&
        isValidCountry
    );

    setEditAddress( false );
    setShowAddress( false );
  };

  // useEffect to check for changes in address fields
  useEffect( () =>
  {
    if ( confirmAddress )
    {
      // Create an object for easy comparison
      const currentAddress = {
        street_number: claimFormValue.street_number,
        street_name: claimFormValue.street_name,
        loss_city: claimFormValue.loss_city,
        loss_state: claimFormValue.loss_state,
        loss_zip: claimFormValue.loss_zip,
        loss_country: claimFormValue.loss_country,
      };

      // Compare currentAddress with confirmedAddressValues
      const isAddressChanged = Object.entries( confirmedAddressValues ).some(
        ( [ key, value ] ) => currentAddress[ key ] !== value
      );

      if ( isAddressChanged )
      {
        setConfirmAddress( false );
      }
    }
  }, [ claimFormValue, confirmAddress, confirmedAddressValues ] );

  const handleEditFields = () =>
  {
    setConfirmAddress( false );
    setEditAddress( true );
  };

  // useEffect(() => {
  //   checkAddressValidity();
  // }, [claimFormValue]); // Run once when the component mounts

  // Function to handle address validation API call (update with your API logic)
  const handleValidateAddress = async () =>
  {
    setIsValidatingAddress( true );
    try
    {
      const address = `${ claimFormValue.street_number } ${ claimFormValue.street_name }, ${ claimFormValue.loss_city }, ${ claimFormValue.loss_state } ${ claimFormValue.loss_zip } ${ claimFormValue.loss_country }`;
      const response = await axiosInstance.post( 'validate_address/', { address: address } );
      console.log( 'API Response:', response.data );

      if ( response.data && response.data.validated_address && response.data.splitted_address )
      {
        setSuggestedAddress( response.data.validated_address );
        setSpittedAddress( response.data.splitted_address );
        setSnackbarSeverity( 'info' );
        setShowAddress( true );
      }


    } catch ( error )
    {
      console.error( 'Error during address validation:', error );
      setSnackbarSeverity( 'error' );
      setSnackbarMessage(
        error.response && error.response.data && error.response.data.error
          ? `${ error.response.data.error }, Please check you address.`
          : 'An error occurred during address validation. Please try again later.'
      );
      setSnackbarOpen( true );

    } finally
    {
      setIsValidatingAddress( false );
    }
  };

  // Calculate if the current step is valid
  const isCurrentStepValid = () =>
  {
    switch ( activeStep )
    {
      case 0:
        return canSaveStep1;
      case 1:
        return canSaveStep2;
      case 2:
        return !nextButtonDisabled;
      case 3:
        return isInsured === "yes" ? true : canSaveStep4;
      default:
        return true; // For the review step
    }
  };

  // Function to check if step navigation is allowed
  // const isStepNavigationAllowed = (stepIndex) => {
  //   // Allow navigation to previous steps or if the current step is valid
  //   return stepIndex < activeStep || isCurrentStepValid();
  // };




  const [ touchedSteps, setTouchedSteps ] = useState( [ false, false, false ] );
  const [ filledSteps, setFilledSteps ] = useState( [ false, false, false ] );
  const [ hasEnteredValue, setHasEnteredValue ] = useState( false );
  // Mark step as touched when user interacts with any field in the step
  const handleFieldChange = ( stepIndex, value ) =>
  {
    setTouchedSteps( ( prevTouchedSteps ) =>
    {
      const updatedTouchedSteps = [ ...prevTouchedSteps ];
      updatedTouchedSteps[ stepIndex ] = true;  // Mark step as touched
      return updatedTouchedSteps;
    } );

  }

  const validateStep3Fields = () =>
  {
    // If "checked" is true, reportNumber must be filled, otherwise it's valid.
    if ( checked )
    {
      return !!report_number;  // If checked, return true if reportNumber is filled, false if not (to show error)
    }
    return true; // If not checked, skip validation and mark the step as valid.
  };

  // Function to check if a step is valid (updated to handle all cases)
  const isStepValid = ( stepIndex ) =>
  {
    switch ( stepIndex )
    {
      case 0: return canSaveStep1;
      case 1: return canSaveStep2;
      case 2: return !nextButtonDisabled;
      case 3: return isInsured === "yes" ? true : canSaveStep4;
      default: return true; // For the review step
    }
  };
  // Function to determine if the step should show errors (only after interaction)
  const shouldShowError = ( stepIndex ) =>
  {
    return touchedSteps[ stepIndex ] && !isStepValid( stepIndex );
  };
  // Function to check if there are invalid steps before a given step
  const hasInvalidStepsBefore = ( targetStepIndex ) =>
  {
    for ( let i = 0; i < targetStepIndex; i++ )
    {
      if ( !isStepValid( i ) )
      {
        return true;
      }
    }
    return false;
  };

  // Updated function to check if step navigation is allowed
  const isStepNavigationAllowed = ( stepIndex ) =>
  {
    if ( allStepsValid )
    {
      return true; // All steps are valid, allow free navigation
    } else
    {
      return stepIndex < activeStep ||  // Allow navigating back 
        ( isStepValid( activeStep ) && !hasInvalidStepsBefore( stepIndex ) );
    }
  };

  return (
    <>
      <Header />
      <ThemeProvider theme={ theme }>

        <Grid container className="claim-capture">
          <Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 } className="navbarmain" style={ { marginTop: "30px" } }>
            <AppBar position="static" sx={ { backgroundColor: localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066", padding: '5px' } }>
              <Container maxWidth="xl">
                <Toolbar disableGutters>
                  { isMobile ?
                    <>

                      <img src={ imageSrc } alt="ic-logo" style={ { width: "70px" } } />&nbsp;&nbsp;&nbsp;
                    </>
                   : 
                    <>

                      <img src={ imageSrc } alt="ic-logo" style={ { width: "70px" } } />&nbsp;&nbsp;&nbsp;
                    </>
                  } 

                  {/* <img src={imageURL} alt="image" />&nbsp;&nbsp;&nbsp; */ }
                  {/* <img src="/var/www/inno-claim-fnol/back_end/django-backend/Innovontech/Media/Claims_documents/7de3b9ee-dce4-42bb-8001-81003e045477.jpg" alt="ic-logo" />&nbsp;&nbsp;&nbsp; */ }
                  { !isMobile &&

                    <>
                      <Typography>
                        { user.policy_number }&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        { user.pol_first_name + " " + user.pol_middle_name + " " + user.pol_last_name } &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        { "Eff : " + user.pro_pol_eff_date }&nbsp;&nbsp;{ " " }
                        { "Exp : " + user.pro_pol_exp_date }
                      </Typography>
                      <Box sx={ { flexGrow: 1 } } />
                      { selectProperty === true && (
                        <Typography style={ { fontSize: "0.85rem", paddingRight: 30 } }>{ claimFormValue.loss_property }</Typography>
                      ) }
                    </>
                  }
                  { isMobile && <><Grid iteam xs={ 12 } style={ { textAlign: "right" } }> { user.policy_number } </Grid></> }
                </Toolbar>
              </Container>
            </AppBar>
          </Grid>

          <Grid sx={ { height: isMobile ? "auto" : "100%", width: '100%', display: "flex", flexDirection: isMobile ? "column" : "row" } }>


            <Box sx={ { height: isMobile ? "auto" : "100%", width: '100%', display: "flex", flexDirection: isMobile ? "column" : "row" } } >
              {/* Side Stepper */ }
              <Paper elevation={ 2 } sx={ { padding: 5, width: isMobile ? "100%" : "350px", height: isMobile ? "auto" : "100" } }>
                <Stepper
                  activeStep={ activeStep }
                  orientation="vertical"
                  sx={ {
                    "& .MuiStepConnector-root .MuiStepConnector-line": {
                      height: isMobile ? 50 : 90,
                      borderWidth: "0.1rem",
                    },
                    "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line":
                      {
                        borderColor: localCompany.ic_primary_color
                          ? localCompany.ic_primary_color
                          : "#010066",
                        height: isMobile ? 50 : 90,
                      },
                    "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line":
                      {
                        borderColor: localCompany.ic_primary_color
                          ? localCompany.ic_primary_color
                          : "#010066",
                        height: isMobile ? 50 : 90,
                      },
                    "& .MuiStepLabel-root.Mui-completed": {
                      "& .MuiStepLabel-label > span": {
                        cursor: "pointer",
                      },
                      backgroundColor: "#f0f0f0",
                    },
                  } }
                >
                  { steps.map( ( step, index ) =>
                  {
                    const { label, icon } = step;
                    const isCurrentStep = index === activeStep;
                    const isFutureStep = index > activeStep;
                    const isCompletedStep = stepsCompleted.includes( index );

                    return (
                      // <Step key={label} completed={isCompletedStep}
                      //   // onClick={() => handleStepClick(index)}
                      //   // Disable clicking on future steps if the current step is invalid
                      //   // onClick={() => isCurrentStepValid() && handleStepClick(index)}
                      //   onClick={() =>
                      //     isStepNavigationAllowed(index) && handleStepClick(index)
                      //   }
                      // >
                      <Step key={ label }
                        completed={ isStepValid( index ) }
                        onClick={ () => isStepNavigationAllowed( index ) && handleStepClick( index ) }
                      >
                        <StepLabel
                          StepIconProps={ {
                            style: {
                              color: shouldShowError( index )
                                ? "#010066" // Bright red color for the error icon
                                : ( isCompletedStep || isCurrentStep
                                  ? localCompany.ic_primary_color || "#010066"
                                  : "#A9A9A9" ),
                              borderColor: shouldShowError( index )
                                ? "#010066" // Bright red for error border
                                : ( isCompletedStep || isCurrentStep
                                  ? localCompany.ic_primary_color || "#010066"
                                  : "#A9A9A9" ),
                            },
                          } }
                          sx={ {
                            cursor: isCompletedStep ? 'pointer' : 'default',
                            '&:hover .step-content': {
                              boxShadow: isCompletedStep ? `0px 4px 20px rgba(0, 0, 0, 0.1)` : 'none',
                              backgroundColor: isCompletedStep ? '#f9f9f9' : 'inherit',
                            },
                            '& .MuiStepLabel-label': {
                              color: shouldShowError( index )
                                ? "#010066"  // Bright red for error text
                                : ( isCompletedStep || isCurrentStep
                                  ? localCompany.ic_primary_color || "#010066"
                                  : "rgba(0, 0, 0, 0.54)" ),
                            },
                          } }
                        >
                          <div className="step-content" style={ { display: 'flex', alignItems: 'center', padding: '5px', borderRadius: '1px' } }>
                            <Typography
                              style={ {
                                fontWeight: isCurrentStep ? "bold" : "normal",
                                color: shouldShowError( index )
                                  ? "#010066"  // Bright red for error text
                                  : ( isCompletedStep || isCurrentStep ? "inherit" : "rgba(0, 0, 0, 0.54)" ),
                                marginLeft: '20px'
                              } }
                            >
                              { shouldShowError( index ) ? (
                                <img src={ lightbulbicon } alt="lightbulbicon" style={ { height: '25px', width: '25px', color: "#FF4500", marginBottom: "0.5rem", marginRight: "0.5rem" } } /> // Show error icon
                              ) : (
                                <span>{ icon }</span>
                              ) }
                              <span style={ { marginLeft: "1rem", cursor: isCompletedStep ? 'pointer' : 'default' } } className="Nasaliza">
                                { label }
                              </span>
                            </Typography>
                          </div>
                        </StepLabel>
                      </Step>
                    );
                  } ) }
                </Stepper>
              </Paper>
              { activeStep === steps.length - 1 ? (
                <Grid
                  container
                  sx={ {
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0px 2px",
                    overflowX: "hidden",

                  } }
                >

                  <Grid item md={ 8 }>
                    <Card sx={ { boxShadow: 3, border: '1px solid blue', mt: 2 } } >
                      <Grid item xs={ 12 } sm={ activeStep === steps.length - 1 ? 12 : 6 } md={ activeStep === steps.length - 1 ? 9 : 6 } style={ { margin: "auto" } } >
                        {/* test */ }
                        <Typography
                          sx={ {
                            color: localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066",
                            fontWeight: '500',
                            marginTop: isMobile ? '1rem' : '2rem',
                            marginBottom: isMobile ? '1rem' : '2rem',
                            fontSize: isMobile ? '1.2rem' : '1.5rem',

                          } }
                          className="Nasaliza"
                        >
                          Review Claim Details
                        </Typography>
                        <Grid container spacing={ 2 } style={ { marginBottom: '7px', marginLeft: isMobile ? '0' : '15px', justifyContent: 'center' } }>
                          { displayFieldNames.slice( 0, 2 ).map( ( fieldName, index ) => (

                            <React.Fragment key={ fieldName }>

                              <Grid item xs={ 4.5 } sm={ 4 } md={ 3 } style={ { fontWeight: 550, fontSize: isMobile ? 11 : 13, textAlign: 'left', } }>
                                { fieldName }
                              </Grid>
                              <Grid item xs={ 1 } sm={ 1 } md={ 1 } style={ { textAlign: 'left' } }>
                                :
                              </Grid>
                              <Grid item xs={ 4.5 } sm={ 4 } md={ 5.5 } style={ { fontWeight: 500, fontSize: isMobile ? 11 : 13, textAlign: 'left', } }>
                                { claimFormValue[ fieldMapping[ fieldName ] ] }
                              </Grid>
                            </React.Fragment>
                          ) ) }
                        </Grid>
                        <Grid container className="reviewheadingscontainer">
                          <Grid md={ 12 } className="reviewstepheadings" style={ { marginLeft: '3rem' } } >
                            <Typography className="Nasaliza" style={ { fontSize: isMobile ? 18 : 21 } }>1. Loss Details
                              <Tooltip title="Edit" arrow placement="right">
                                <Button
                                  variant="contained"
                                  startIcon={ <EditIcon style={ { color: localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066", } } /> }
                                  onClick={ onEditStep1 }
                                />
                              </Tooltip>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={ 2 } style={ { marginBottom: '7px', marginLeft: isMobile ? '0' : '15px', justifyContent: 'center' } }>
                          { displayFieldNames.slice( 2, 5 ).map( ( fieldName, index ) => (

                            <React.Fragment key={ fieldName }>

                              <Grid item xs={ 4.5 } sm={ 4 } md={ 3 } style={ { fontWeight: 550, fontSize: isMobile ? 11 : 13, textAlign: 'left' } }>
                                { fieldName }
                              </Grid>
                              <Grid item xs={ 1 } sm={ 1 } md={ 1 } style={ { textAlign: 'left' } }>
                                :
                              </Grid>
                              <Grid item xs={ 4.5 } sm={ 4 } md={ 5.5 } style={ { fontWeight: 500, fontSize: isMobile ? 11 : 13, textAlign: 'left' } }>
                                { claimFormValue[ fieldMapping[ fieldName ] ] }
                              </Grid>
                            </React.Fragment>
                          ) ) }
                        </Grid>
                        <Grid container className="reviewheadingscontainer">

                          <Grid md={ 12 } className="reviewstepheadings">
                            <Typography className="Nasaliza" style={ { marginLeft: '3rem', fontSize: isMobile ? 18 : 21 } } >2. Loss Location
                              <Tooltip title="Edit" arrow placement="right">
                                <Button
                                  variant="contained"
                                  startIcon={ <EditIcon style={ { color: localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066", } } /> }
                                  onClick={ onEditStep2 }
                                />
                              </Tooltip>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={ 2 } style={ { marginBottom: '7px', marginLeft: isMobile ? '0' : '15px', justifyContent: 'center' } }>
                          { displayFieldNames.slice( 5, 6 ).map( ( fieldName, index ) => (

                            <React.Fragment key={ fieldName }>

                              <Grid item xs={ 4.5 } sm={ 4 } md={ 3 } style={ { fontWeight: 550, fontSize: isMobile ? 11 : 13, textAlign: 'left' } }>
                                { fieldName }
                              </Grid>
                              <Grid item xs={ 1 } sm={ 1 } md={ 1 } style={ { textAlign: 'left' } }>
                                :
                              </Grid>
                              <Grid item xs={ 4.5 } sm={ 4 } md={ 5.5 } style={ { fontWeight: 500, fontSize: isMobile ? 11 : 13, textAlign: 'left' } }>
                                {/* {claimFormValue[fieldMapping[fieldName]]} */ }
                                { fieldName === "Loss Location"
                                  ? showFormateedAddresOnREview( claimFormValue.street_number, claimFormValue.street_name, claimFormValue.loss_city, claimFormValue.loss_state, claimFormValue.loss_zip, claimFormValue.loss_country )
                                  : claimFormValue[ fieldMapping[ fieldName ] ]
                                }
                              </Grid>
                            </React.Fragment>
                          ) ) }
                        </Grid>
                        <Grid container className="reviewheadingscontainer">

                          <Grid md={ 12 } className="reviewstepheadings">
                            <Typography className="Nasaliza" style={ { marginLeft: '3rem', fontSize: isMobile ? 18 : 21 } }>3. Claim Reporter
                              <Tooltip title="Edit" arrow placement="right">
                                <Button
                                  variant="contained"
                                  startIcon={ <EditIcon style={ { color: localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066", } } /> }
                                  onClick={ onEditStep3 }
                                />
                              </Tooltip>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={ 2 } style={ { marginBottom: '7px', marginLeft: isMobile ? '0' : '15px', justifyContent: 'center' } }>
                          { displayFieldNames.slice( 6, 9 ).map( ( fieldName, index ) => (
                            <React.Fragment key={ fieldName }>

                              <Grid item xs={ 4.5 } sm={ 4 } md={ 3 } style={ { fontWeight: 550, fontSize: isMobile ? 11 : 13, textAlign: 'left' } }>
                                { fieldName }
                              </Grid>
                              <Grid item xs={ 1 } sm={ 1 } md={ 1 } style={ { textAlign: 'left' } }>
                                :
                              </Grid>
                              <Grid item xs={ 4.5 } sm={ 4 } md={ 5.5 } style={ { fontWeight: 500, fontSize: isMobile ? 11 : 13, textAlign: 'left' } }>
                                { renderValue( fieldName ) ? renderValue( fieldName ) : "-" }
                              </Grid>
                            </React.Fragment>
                          ) ) }
                        </Grid>
                        { isInsured === 'no' ?
                          <>
                            <Grid container className="reviewheadingscontainer">

                              <Grid md={ 9 } className="reviewstepheadings">
                                <Typography className="Nasaliza" style={ { marginLeft: '3rem', fontSize: isMobile ? 18 : 21 } }>4. Contact Details
                                  <Tooltip title="Edit" arrow placement="right">
                                    <Button
                                      variant="contained"
                                      startIcon={ <EditIcon style={ { color: localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066", } } /> }
                                      onClick={ onEditStep4 }
                                    />
                                  </Tooltip>
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container spacing={ 2 } style={ { marginBottom: '7px', marginLeft: isMobile ? '0' : '15px', justifyContent: 'center' } }>
                              { displayFieldNames.slice( 9 ).map( ( fieldName, index ) => (
                                <React.Fragment key={ fieldName }>

                                  <Grid item xs={ 4.5 } sm={ 4 } md={ 3 } style={ { fontWeight: 550, fontSize: isMobile ? 11 : 13, textAlign: 'left' } }>
                                    { fieldName }
                                  </Grid>
                                  <Grid item xs={ 1 } sm={ 1 } md={ 1 } style={ { textAlign: 'left' } }>
                                    :
                                  </Grid>
                                  <Grid item xs={ 4.5 } sm={ 4 } md={ 5.5 } style={ { fontWeight: 500, fontSize: isMobile ? 11 : 13, textAlign: 'left' } }>
                                    { renderContactValue( fieldName ) ? renderContactValue( fieldName ) : "-" }
                                  </Grid>
                                </React.Fragment>
                              ) ) }
                            </Grid>
                          </>
                          : ""
                        }
                        <Grid container spacing={ 2 } style={ { marginBottom: '7px', marginLeft: isMobile ? '0' : '15px', justifyContent: 'center' } }>

                          <React.Fragment>

                            <Grid item xs={ 4.5 } sm={ 4 } md={ 3 } style={ { fontWeight: 550, fontSize: 13, textAlign: 'left' } }>
                              Claim Documents
                            </Grid>
                            <Grid item xs={ 1 } sm={ 1 } md={ 1 } style={ { textAlign: 'left' } }>
                              :
                            </Grid>
                            <Grid item xs={ 4.5 } sm={ 4 } md={ 5.5 } style={ { fontWeight: 500, fontSize: 13, textAlign: 'left' } }>
                              { claimFormValue.claim_witness_document_names && claimFormValue.claim_witness_document_names.length > 0 ? (
                                claimFormValue.claim_witness_document_names.map( ( documentName, index ) => (
                                  <Typography key={ index } style={ { fontSize: "0.9rem", color: "gray" } }>
                                    &bull; { documentName || "-" }
                                  </Typography>
                                ) )
                              ) : (
                                <Typography style={ { fontSize: "0.9rem", color: "gray" } }>
                                  &nbsp; -
                                </Typography>
                              ) }
                            </Grid>
                          </React.Fragment>
                        </Grid>

                        <Grid container spacing={ 2 } sx={ { marginBottom: '4rem', marginTop: "3rem", justifyContent: 'center' } } className="reviewbuttons">
                          <Grid item xs={ 6 }>
                            <StyledButtonComponent
                              buttonWidth={ 100 }
                              onClick={ handleBack }
                              backgroundColor={ localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" }
                            >
                              Back
                            </StyledButtonComponent>
                          </Grid>

                          <Grid item xs={ 6 }>
                            <StyledButtonComponent
                              buttonWidth={ 100 }
                              onClick={ handleSubmit }
                              backgroundColor={ localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" }
                            >
                              Submit
                            </StyledButtonComponent>

                            {/* Backdrop can stay outside for now (unless you want to control its positioning more precisely) */ }
                            <Backdrop
                              sx={ { color: '#fff', zIndex: ( theme ) => theme.zIndex.drawer + 1 } }
                              open={ processSubmit }
                            >
                              <CircularProgress color="inherit" />
                            </Backdrop>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              ) : (
                <React.Fragment>
                  { activeStep === 0 ?
                    <Grid
                      container
                      sx={ {
                        flex: 1,
                        justifyContent: "center",

                        alignItems: "center",
                        padding: 2,
                        overflowX: "hidden",

                      } }
                    >
                      <Grid item xs={ 12 } sm={ activeStep === steps.length - 1 ? 12 : 6 } md={ activeStep === steps.length - 1 ? 12 : 6 } justifyContent='center' alignItems='center' margin="auto">
                        <Grid textAlign={ 'left' } marginBottom={ "2rem" }>
                          <Typography className="Nasaliza" variant="h5" style={ { color: localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" } } >
                            Loss Details
                          </Typography>
                        </Grid>
                        <Paper
                          elevation={ 2 }
                          sx={ {

                            padding: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",

                          } }
                        >
                          <Box>
                            <Grid container spacing={ 2 }>
                              <Grid item xs={ 12 }>

                                <FormControl fullWidth >
                                  <LocalizationProvider dateAdapter={ AdapterDayjs }>
                                  <DateTimePicker
  maxDateTime={dayjs().tz(selectedLocation).startOf('minute')} // Restrict future time to the current minute in the selected timezone
  views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
  label={'Loss date and time *'}
  openTo="year"
  id="loss_date_and_time"
  autoComplete="loss date and time"
  value={selectedDateTime}
  onChange={onDateTimeChange}
  renderInput={(params) => (
    <TextField
      {...params}
      fullWidth
      InputProps={{
        sx: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
            },
            "&:hover fieldset": {
              border: "none",
            },
            "&.Mui-focused fieldset": {
              border: "none",
            },
            borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
          },
        },
      }}
    />
  )}
/>
                                  </LocalizationProvider>
                                </FormControl>
                              </Grid>
                              <Grid item xs={ 12 }>
                                <Grid container spacing={ 2 }>
                                  <Grid item xs={ 12 }>
                                    <FormControl fullWidth margin="normal" variant="standard" error={ lossTypeError }>
                                      <InputLabel id="select-loss-type">Loss Type *</InputLabel>
                                      <Select
                                        labelId="select-loss-type"
                                        id="loss_type"
                                        value={ loss_type }
                                        onBlur={ onLossTypeSelectBlur }
                                        onFocus={ onLossTypeSelectFocus }
                                        required
                                        name="loss_type"
                                        autoComplete="loss type"
                                        onChange={ ( e ) =>
                                        {
                                          onInputChange( e );

                                        } }
                                        sx={ {
                                          '& .MuiInput-underline:before': {
                                            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                          },
                                          "& .MuiInput-underline:hover:before":
                                            {
                                              borderBottom:
                                                "1px solid rgba(0, 0, 0, 0.87)",
                                            },
                                          "& .MuiInput-underline:after": {
                                            borderBottom:
                                              "2px solid rgba(0, 0, 0, 0.87)",
                                          },
                                          '& .MuiSelect-select': { textAlign: 'left' },
                                        } }
                                        MenuProps={ {
                                          PaperProps: {
                                            sx: {
                                              maxHeight: 200, // Set the maximum height for the dropdown
                                            },
                                          },
                                        } }



                                      >
                                        <MenuItem value={ "Flood" }>Flood</MenuItem>
                                        <MenuItem value={ "Storm" }>Storm</MenuItem>
                                        <MenuItem value={ "Earthquake" }>Earthquake</MenuItem>
                                        <MenuItem value={ "Lightning" }>Lightning</MenuItem>
                                        <MenuItem value={ "Fire" }>Fire</MenuItem>
                                        <MenuItem value={ "Wind" }>Wind</MenuItem>
                                        <MenuItem value={ "Theft" }>Theft</MenuItem>
                                        <MenuItem value={ "Falling Objects" }>Falling Objects</MenuItem>
                                        <MenuItem value={ "Explosion" }>Explosion</MenuItem>
                                        <MenuItem value={ "Vandalism" }>Vandalism</MenuItem>
                                      </Select>
                                      <FormHelperText>{ lossTypeError ? 'Must select a loss type' : '' }</FormHelperText>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={ 12 }>
                                <FormControl fullWidth margin="normal" variant="standard" error={ insuredPropertyAddressError }>
                                  <InputLabel id="select-loss-property">Insured Property Address *</InputLabel>
                                  <Select
                                    labelId="select-loss-property"
                                    id="loss_property"
                                    value={ loss_property }
                                    onBlur={ onInsuredPropertyAddressSelectBlur }
                                    onFocus={ onInsuredPropertyAddressSelectFocus }
                                    required
                                    name="loss_property"
                                    onChange={ ( e ) =>
                                    {
                                      onInputChange( e );

                                    } }
                                    inputProps={ {
                                      sx: {
                                        "&:before": {
                                          borderBottom:
                                            "1px solid rgba(0, 0, 0, 0.42)",
                                        },
                                        "&:hover:not(.Mui-disabled):before": {
                                          borderBottom:
                                            "1px solid rgba(0, 0, 0, 0.87)",
                                        },
                                        "&.Mui-focused:after": {
                                          borderBottom:
                                            "2px solid rgba(0, 0, 0, 0.87)",
                                        },
                                      },
                                    } }
                                    sx={ {
                                      '& .MuiSelect-select': { textAlign: 'left' },
                                    } }
                                  >
                                    <MenuItem value={ user.pro_address1 + ",  " + user.pro_city + ",  " + user.pro_state + ",  " + user.pro_country + ",  " + user.pro_zip + "." }>
                                      { user.pro_address1 + ",  " + user.pro_city + ",  " + user.pro_state + ",  " + user.pro_country + ",  " + user.pro_zip + "." }
                                    </MenuItem>
                                  </Select>
                                  <FormHelperText>{ insuredPropertyAddressError ? 'Must select your Property Address' : '' }</FormHelperText>
                                </FormControl>
                              </Grid>
                              <Grid item xs={ 12 }>
                                <TextField
                                  fullWidth
                                  margin="normal"
                                  error={ textAreaError }
                                  id="loss_damage_description"
                                  variant="outlined"
                                  required
                                  name="loss_damage_description"
                                  autoComplete="loss_damage_description"
                                  value={ loss_damage_description }
                                  onChange={ ( e ) => onInputChange( e ) }
                                  label="Loss Damage Description"
                                  multiline
                                  onBlur={ handleOnTextAreaBlur }
                                  onFocus={ handleOnTextAreaFocus }
                                  rows={ 3 }
                                  sx={ {
                                    '& .MuiOutlinedInput-root': {
                                      paddingLeft: 0,  // Removes left padding in the input
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                    },
                                    '& .MuiInputLabel-root': {
                                      textAlign: 'left',
                                      width: '100%',
                                      transform: 'translate(0, -50%) scale(1)',
                                      left: 0,  // Aligns label to the far left
                                    },
                                  } }
                                  InputLabelProps={ {
                                    shrink: true,
                                    sx: {
                                      textAlign: 'left',
                                      transformOrigin: 'top left',
                                      left: 0,  // Ensures label aligns to the left with no spacing
                                    },
                                  } }
                                  InputProps={ {
                                    sx: {
                                      paddingLeft: 0,  // Removes left padding for the input text
                                      '& textarea': {
                                        textAlign: 'left',  // Aligns multiline input to the left
                                      },
                                    },
                                  } }
                                  helperText={
                                    textAreaError
                                      ? 'Description is between 10-500 Characters required'
                                      : ''
                                  }
                                />
                              </Grid>
                              <Grid item xs={ 12 } sx={ { marginTop: 2, display: 'flex', justifyContent: 'space-between' } }>
                                <Grid item>
                                  <StyledButtonComponent
                                    buttonWidth={ 100 }
                                    disableColor={ "#CCCCCC" }

                                    onClick={ () =>
                                    {
                                      handleFieldChange( 0 );  // Call handleFieldChange with a step index and value
                                      handleBack();               // Call handleGotoReview after
                                    } }
                                    disabled={ activeStep === 0 }
                                    backgroundColor={ activeStep > 0
                                      ? ( localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" )
                                      : "#999999" } // Pass calculated background color here
                                  >
                                    Back
                                  </StyledButtonComponent>
                                </Grid>

                                <Grid item sx={ { display: 'flex', gap: 2 } }>
                                  <StyledButtonComponent
                                    buttonWidth={ 100 }
                                    disableColor={ "#CCCCCC" }
                                    onClick={ () =>
                                    {
                                      // Call handleFieldChange with a step index and value
                                      handleGotoReview();               // Call handleGotoReview after
                                    } }
                                    disabled={ !allStepsValid }
                                    backgroundColor={ allStepsValid
                                      ? ( localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" )
                                      : "#999999" }

                                  >
                                    Review
                                  </StyledButtonComponent>

                                  <StyledButtonComponent
                                    buttonWidth={ 100 }
                                    disableColor={ "#CCCCCC" }
                                    onClick={ handleNext }
                                    disabled={ !canSaveStep1 }
                                    backgroundColor={ canSaveStep1
                                      ? ( localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" )
                                      : "#999999" }  // Pass calculated background color here
                                  >
                                    { activeStep === steps.length - 1 ? 'Review' : 'Next' }
                                  </StyledButtonComponent>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                        </Paper>
                      </Grid>
                    </Grid>
                    :
                    ( activeStep === 1 ?
                      <Grid
                        container
                        sx={ {
                          flex: 1,
                          justifyContent: "center",

                          alignItems: 'center',
                          padding: 2,
                          overflowX: "hidden",

                        } }
                      >
                        <Grid item xs={ 12 } sm={ activeStep === steps.length - 1 ? 12 : 6 } md={ activeStep === steps.length - 1 ? 12 : 6 } justifyContent='center' alignItems='center' margin="auto">
                          <Grid textAlign={ 'left' } marginBottom={ "2rem" }>
                            <Typography className="Nasaliza" variant="h5" style={ { color: localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" } } >
                              Loss Location Details
                            </Typography>
                          </Grid>
                          <Paper
                            elevation={ 2 }
                            sx={ {
                              padding: 2,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",

                            } }
                          >
                            <Box item xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 }>
                              <Grid container>

                                <Grid container spacing={ 2 }>
                                  {/* Section for the Address Confirmation */ }
                                  <Grid item xs={ 12 } sm={ 12 } sx={ { mb: 2 } }>
                                    { showAddress && suggestedAddress && (
                                      <Typography variant="body2">
                                        { locationDataState ? "Below is the Current Address, Click to use it" : "Below is the Validated Address, Click to use it" } &nbsp;&nbsp;
                                        <Button
                                          size="small"
                                          sx={ {
                                            color: '#0B70FF',
                                            fontWeight: 'bold',
                                            border: '1px solid #0B70FF',
                                            padding: '2px 8px', // Smaller padding for a sleek look
                                            fontSize: '0.75rem', // Smaller font size
                                            borderRadius: '4px', // Rounded edges
                                            minWidth: 'auto', // Remove minimum width to make it smaller
                                            textTransform: 'none', // Disable uppercase text transformation
                                          } }
                                          onClick={ () => handleConfirmAddress( spittedAddress ) }
                                        >
                                          Yes
                                        </Button>
                                      </Typography>
                                    ) }
                                    { showAddress && suggestedAddress && (
                                      <Typography
                                        style={ { color: '#0B70FF', fontSize: '0.85rem', marginTop: "0.5rem" } }
                                      >
                                        "{ suggestedAddress }"
                                      </Typography>
                                    ) }
                                  </Grid>


                                </Grid>

                                <Grid container item xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 } spacing={ 2 } style={ { marginTop: locationConfirmed === null ? '1rem' : 0 } }>                                  <Grid item xs={ 12 } sm={ 6 } md={ 6 } lg={ 6 }>
                                  <TextField
                                    error={ streetNumberError }
                                    id="street_number"
                                    label="Street Number"
                                    variant="outlined"
                                    required
                                    name="street_number"
                                    autoComplete="street_number"
                                    value={ street_number }
                                    onChange={ ( e ) =>
                                    {
                                      onInputChange( e );
                                    } }
                                    onFocus={ handleOnStreetNumberFocus }
                                    onBlur={ handleOnStreetNumberBlur }
                                    helperText={ validStreetNumber ? "" : <LossAddressHelperText /> }
                                    fullWidth
                                    // disabled={confirmAddress && !editAddress}
                                    sx={ {
                                      '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                          border: 'none',
                                        },
                                        "&:hover fieldset": {
                                          border: "none",
                                        },
                                        "&.Mui-focused fieldset": {
                                          border: "none",
                                        },
                                        borderBottom:
                                          "1px solid rgba(0, 0, 0, 0.42)",
                                      },
                                    } }
                                  />
                                </Grid>
                                  <Grid item xs={ 12 } sm={ 6 } md={ 6 } lg={ 6 }>
                                    <TextField
                                      error={ streetNameError }
                                      id="street_name"
                                      label="Street Name *"
                                      variant="outlined"
                                      name="street_name"
                                      autoComplete="street_name"
                                      value={ street_name }
                                      onChange={ ( e ) =>
                                      {
                                        onInputChange( e );
                                        // Mark step 0 as touched
                                      } }
                                      onFocus={ handleOnStreetNameFocus }
                                      onBlur={ handleOnStreetNameBlur }
                                      // disabled={confirmAddress && !editAddress}
                                      // onFocus={() => setStreetNameTouched(true)}
                                      // onBlur={() => setStreetNameTouched(false)}
                                      helperText={ validStreetName ? "" : <LossStreetHelperText /> }
                                      fullWidth
                                      sx={ {
                                        '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            border: 'none',
                                          },
                                          '&:hover fieldset': {
                                            border: 'none',
                                          },
                                          '&.Mui-focused fieldset': {
                                            border: 'none',
                                          },
                                          borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                        },
                                      } }
                                    />
                                  </Grid>

                                  <Grid item xs={ 12 } sm={ 6 } md={ 6 } lg={ 6 }>
                                    <TextField
                                      error={ cityError }
                                      id="loss_city"
                                      label="Loss City"
                                      variant="outlined"
                                      required
                                      name="loss_city"
                                      autoComplete="loss_city"
                                      value={ loss_city }
                                      onChange={ ( e ) =>
                                      {
                                        onInputChange( e );
                                      } }
                                      onFocus={ handleOnCityFocus }
                                      onBlur={ handleOnCityBlur }
                                      helperText={ validCity ? "" : <LossCityHelperText /> }
                                      fullWidth
                                      // disabled={confirmAddress && !editAddress}
                                      sx={ {
                                        '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            border: 'none',
                                          },
                                          '&:hover fieldset': {
                                            border: 'none',
                                          },
                                          '&.Mui-focused fieldset': {
                                            border: 'none',
                                          },
                                          borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                        },
                                      } }
                                    />
                                  </Grid>
                                  <Grid item xs={ 12 } sm={ 6 } md={ 6 } lg={ 6 }>
                                    <FormControl fullWidth variant="standard" sx={ { marginTop: '0.4rem' } } error={ StateError }>
                                      <InputLabel id="select-state-label">Loss State *</InputLabel>
                                      <Select
                                        labelId="select-state-label"
                                        id="loss_state"
                                        value={ loss_state }
                                        required
                                        name="loss_state"
                                        autoComplete="loss_state"
                                        onChange={ ( e ) =>
                                        {
                                          setLossState( e.target.value );
                                          onInputChange( e );

                                        } }

                                        onFocus={ onLossStateSelectFocus }
                                        onBlur={ onLossStateSelectBlur }
                                        // disabled={confirmAddress && !editAddress}
                                        inputProps={ {
                                          sx: {
                                            '&:before': {
                                              borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                            },
                                            '&:hover:not(.Mui-disabled):before': {
                                              borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
                                            },
                                            '&.Mui-focused:after': {
                                              borderBottom: '2px solid rgba(0, 0, 0, 0.87)',
                                            },
                                          },
                                        } }
                                        sx={ {
                                          '& .MuiSelect-select': { textAlign: 'left' },
                                        } }
                                        MenuProps={ {
                                          style: {
                                            maxHeight: 400,
                                          },
                                        } }
                                      >
                                        { states.map( ( state ) => (
                                          <MenuItem key={ state.select } value={ `${ state.select } (${ state.value })` }>
                                            { `${ state.select } (${ state.value })` }
                                          </MenuItem>
                                        ) ) }
                                        <MenuItem value={ lossState || "null" }>{ lossState || "null" }</MenuItem>
                                      </Select>
                                      <FormHelperText>{ validState ? '' : 'Select at most one State' }</FormHelperText>
                                    </FormControl>

                                  </Grid>
                                  <Grid item xs={ 12 } sm={ 6 } md={ 6 } lg={ 6 }>
                                    <TextField
                                      error={ zipError }
                                      id="loss_zip"
                                      label="Loss ZIP"
                                      variant="outlined"
                                      required
                                      name="loss_zip"
                                      autoComplete="loss_zip"
                                      value={ loss_zip }
                                      onChange={ ( e ) =>
                                      {
                                        onInputChange( e );

                                      } }
                                      onFocus={ handleOnZipFocus }
                                      onBlur={ handleOnZipBlur }
                                      helperText={ validZip ? "" : <LossZiptHelperText /> }
                                      fullWidth
                                      // disabled={confirmAddress && !editAddress}
                                      sx={ {
                                        '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            border: 'none',
                                          },
                                          '&:hover fieldset': {
                                            border: 'none',
                                          },
                                          '&.Mui-focused fieldset': {
                                            border: 'none',
                                          },
                                          borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                        },
                                      } }
                                    />
                                  </Grid>
                                  <Grid item xs={ 12 } sm={ 6 } md={ 6 } lg={ 6 }>
                                    <FormControl fullWidth variant="standard" sx={ { marginTop: '0.4rem' } } error={ countryError }>
                                      <InputLabel id="select-country-label">Loss Country *</InputLabel>
                                      <Select
                                        labelId="select-country-label"
                                        id="loss_country"
                                        value={ lossCountry }
                                        required
                                        name="loss_country"

                                        onChange={ ( e ) =>
                                        {
                                          setLossCountry( e.target.value );
                                          onInputChange( e );

                                        } }
                                        onFocus={ onLossCountrySelectFocus }
                                        onBlur={ onLossCountrySelectBlur }
                                        // disabled={confirmAddress && !editAddress}
                                        inputProps={ {
                                          sx: {
                                            '&:before': {
                                              borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                            },
                                            '&:hover:not(.Mui-disabled):before': {
                                              borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
                                            },
                                            '&.Mui-focused:after': {
                                              borderBottom: '2px solid rgba(0, 0, 0, 0.87)',
                                            },
                                          },
                                        } }
                                        sx={ {
                                          '& .MuiSelect-select': { textAlign: 'left' },
                                        } }
                                      >
                                        <MenuItem value={ "USA" }>USA</MenuItem>
                                        <MenuItem value={ lossCountry || "null" }>{ lossCountry || "null" }</MenuItem>
                                      </Select>
                                      <FormHelperText>{ validCountry ? "" : "Select a country" }</FormHelperText>
                                    </FormControl>

                                  </Grid>
                                </Grid>





                                <Grid item xs={ 12 } sx={ { marginTop: 2, display: 'flex', justifyContent: 'space-between' } }>
                                  <Grid item>
                                    <StyledButtonComponent
                                      buttonWidth={ 100 }
                                      disableColor={ "#CCCCCC" }
                                      disabled={ activeStep === 0 }
                                      onClick={ () =>
                                      {
                                        handleFieldChange( 1 );  // Call handleFieldChange with a step index and value
                                        handleBack();               // Call handleGotoReview after
                                      } }
                                      backgroundColor={ activeStep > 0
                                        ? ( localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" )
                                        : "#999999" }
                                    >
                                      Back
                                    </StyledButtonComponent>

                                  </Grid>

                                  <Grid item sx={ { display: 'flex', gap: 2 } }>
                                    <StyledButtonComponent
                                      buttonWidth={ 100 }
                                      disableColor={ "#CCCCCC" }
                                      onClick={ () =>
                                      {
                                        // Call handleFieldChange with a step index and value
                                        handleGotoReview();               // Call handleGotoReview after
                                      } }
                                      disabled={ !allStepsValid }
                                      backgroundColor={ allStepsValid
                                        ? ( localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" )
                                        : "#999999" }

                                    >
                                      Review
                                    </StyledButtonComponent>

                                    { !confirmAddress ? (
                                      <StyledButtonComponent
                                        buttonWidth={ 100 }
                                        disableColor={ "#CCCCCC" }
                                        onClick={ handleValidateAddress }
                                        disabled={
                                          !claimFormValue.street_number ||
                                          !claimFormValue.street_name ||
                                          !claimFormValue.loss_zip ||
                                          !validStreetNumber ||
                                          !validStreetName ||
                                          !validZip ||
                                          isValidatingAddress ||
                                          confirmAddress
                                        }

                                        backgroundColor={ localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" }


                                      >

                                        Validate

                                      </StyledButtonComponent>
                                    ) : (
                                      <StyledButtonComponent
                                        buttonWidth={ 100 }
                                        disableColor={ "#CCCCCC" }
                                        onClick={ handleNext }
                                        disabled={ !canSaveStep2 || editAddress }
                                        backgroundColor={ canSaveStep2
                                          ? ( localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" )
                                          : "#999999" }
                                      >
                                        { activeStep === steps.length - 1 ? 'Review' : 'Next' }
                                      </StyledButtonComponent>

                                    ) }


                                  </Grid>
                                </Grid>
                              </Grid>

                            </Box>
                          </Paper>
                        </Grid>
                      </Grid>

                      :
                      (
                        ( activeStep === 2 ? (
                          <Grid
                            container
                            sx={ {
                              flex: 1,
                              justifyContent: "center",
                              alignItems: 'center',
                              padding: 2,
                              overflowX: "hidden",

                            } }
                          >
                            <Grid item xs={ 12 } sm={ activeStep === steps.length - 1 ? 12 : 6 } md={ activeStep === steps.length - 1 ? 12 : 6 } justifyContent='center' alignItems='center' margin="auto">
                              <Grid textAlign={ 'left' } marginBottom={ "2rem" }>
                                <Typography className="Nasaliza" variant="h5" style={ { color: localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" } } >
                                  Claim Reporter Details
                                </Typography>
                              </Grid>
                              <Paper
                                elevation={ 2 }
                                sx={ {

                                  padding: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",

                                } }
                              >

                                <Box>
                                  < Grid container style={ { marginTop: "2%" } }>
                                    <Grid container xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 }>

                                      <Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 } display={ 'flex' } paddingLeft={ checked && isMobile ? '0' : '2rem' } alignItems={ 'center' } justifyContent={ 'center' }>
                                        <Switch
                                          checked={ checked }
                                          name="police_fire_contacted"
                                          value={ checked }
                                          sx={ {
                                            '& .MuiSwitch-switchBase.Mui-checked': {
                                              color: localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066",
                                            },
                                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                              backgroundColor: localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066",
                                            },
                                          } }

                                          textAlign={ checked && 'center' }
                                          onClick={ handleChangeBoolean }
                                          inputProps={ {
                                            'aria-label': 'controlled',
                                          } }

                                        />
                                        <Typography style={ { fontSize: checked ? "0.8rem" : '1rem' } }>

                                          Police or Fire Department Contacted
                                        </Typography>
                                        { checked && (
                                          <Grid container>

                                            <Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 }>
                                              <TextField id="outlined-basic" label="Report Number" variant="outlined"
                                                name="report_number"
                                                value={ report_number }

                                                onChange={ ( e ) =>
                                                {
                                                  onInputChange( e );
                                                } }
                                                sx={ {
                                                  '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                      border: 'none',
                                                    },
                                                    '&:hover fieldset': {
                                                      border: 'none',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                      border: 'none',
                                                    },
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                                  },
                                                } }
                                              />
                                            </Grid>
                                          </Grid>
                                        ) }
                                      </Grid>
                                      <Grid container sx={ { marginTop: "2%" } }>
                                        <Grid item xs={ 3 } sm={ 3 } md={ 3 } lg={ 3 } ></Grid>
                                        <Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 }>
                                          <Typography className="Nasaliza" style={ { marginTop: "1.5rem", color: localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066", } } >
                                            Upload claim supporting documents
                                          </Typography>
                                          <FileUpload
                                            id="portal"
                                            onFilesUpload={ handleFilesUploadToAWSByPortal }
                                            multiple={ true }
                                            allowedFormats={ [ 'png', 'jpg', 'jpeg', 'plain', 'pdf', 'docx', 'mp4' ] }
                                            setIsSubmitDisabled={ setIsSubmitDisabled }
                                            selectedFilesInParent={ selectedFiles }
                                            filePreviews={ previews }
                                            filesUploadedInChild={ filesUploadedInChild }
                                            uploadIn={ uploadIn }
                                            onFileRemove={ handlewitnessFileRemove }
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid marginBottom={ '5rem' } item xs={ 4.5 } sm={ 4.5 } md={ 4.5 } lg={ 4.5 }></Grid>
                                  </Grid>
                                  <Grid item xs={ 12 } sx={ { marginTop: 2, display: 'flex', justifyContent: 'space-between' } }>
                                    <Grid item>
                                      <StyledButtonComponent
                                        buttonWidth={ 100 }
                                        disableColor={ "#CCCCCC" }
                                        disabled={ activeStep === 0 }
                                        onClick={ () =>
                                        {
                                          handleFieldChange( 2 );  // Call handleFieldChange with a step index and value
                                          handleBack();               // Call handleGotoReview after
                                        } }
                                        backgroundColor={ activeStep > 0
                                          ? ( localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" )
                                          : "#999999" } // Pass calculated background color here
                                      >
                                        Back
                                      </StyledButtonComponent>
                                    </Grid>
                                    <Grid item sx={ { display: 'flex', gap: 2 } }>
                                      <StyledButtonComponent
                                        buttonWidth={ 100 }
                                        disableColor={ "#CCCCCC" }
                                        onClick={ () =>
                                        {
                                          handleFieldChange( 2 );  // Call handleFieldChange with a step index and value
                                          handleGotoReview();               // Call handleGotoReview after
                                        } }

                                        disabled={ !allStepsValid }
                                        backgroundColor={ allStepsValid
                                          ? ( localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" )
                                          : "#999999" }

                                      >
                                        Review
                                      </StyledButtonComponent>
                                      <StyledButtonComponent
                                        buttonWidth={ 100 }
                                        disableColor={ "#CCCCCC" }
                                        onClick={ () =>
                                        {
                                          handleFieldChange( 2 );  // Call handleFieldChange with a step index and value
                                          handleNext();               // Call handleGotoReview after
                                        } }

                                        disabled={ nextButtonDisabled } // Use nextButtonDisabled state to disable
                                        backgroundColor={ !nextButtonDisabled
                                          ? ( localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" )
                                          : "#999999" }
                                      >
                                        { activeStep === steps.length - 1 ? 'Review' : 'Next' }
                                      </StyledButtonComponent>
                                    </Grid>
                                  </Grid>

                                </Box>
                              </Paper>
                            </Grid>
                          </Grid>
                        ) : (
                          <>
                            <Box display="flex" flexDirection="column" alignItems="center" p={ isMobile ? 2 : 5 } mt={ 2 } style={ { width: '100%', maxWidth: '1200px', margin: 'auto' } }>
                              <Grid container spacing={ 2 }>
                                <Grid textAlign={ 'left' } marginBottom={ "2rem" }>
                                  <Typography className="Nasaliza" variant="h5" style={ { color: localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" } } >
                                    Contact Details
                                  </Typography>
                                </Grid>

                                <Grid container item xs={ 12 } spacing={ 2 }>

                                  <Grid item xs={ 12 } sm={ 12 } md={ 12 }>
                                    <Accordion elevation={ 0 }>
                                      <AccordionSummary expandIcon={ <ExpandMoreIcon style={ { color: PersonalDetailsError ? 'red' : ( localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" ) } } /> } aria-controls="personal-details-content" id="personal-details-header">
                                        <Typography color={ localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" } variant="h6" className="Nasaliza" fontSize="1.2rem"><AccountCircleIcon fontSize="medium" style={ { marginRight: '10px' } } />  Personal Details</Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Grid container spacing={ 2 }>
                                          <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                                            <TextField
                                              label="First Name"
                                              variant="outlined"
                                              id="claimant_first_name"
                                              name="claimant_first_name"
                                              value={ claimant_first_name }
                                              onChange={ onInputChange }
                                              onBlur={ onBlur }
                                              error={ !!errors.claimant_first_name && touched.claimant_first_name }
                                              helperText={ touched.claimant_first_name && errors.claimant_first_name }
                                              required
                                              fullWidth
                                              sx={ {
                                                '& .MuiOutlinedInput-root': {
                                                  '& fieldset': {
                                                    border: 'none',
                                                  },
                                                  '&:hover fieldset': {
                                                    border: 'none',
                                                  },
                                                  '&.Mui-focused fieldset': {
                                                    border: 'none',
                                                  },
                                                  borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                                },
                                              } }
                                            />
                                          </Grid>
                                          <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                                            <TextField
                                              label="Middle Name"
                                              variant="outlined"
                                              id="claimant_middle_name"
                                              name="claimant_middle_name"
                                              value={ claimant_middle_name }
                                              onChange={ onInputChange }
                                              fullWidth
                                              sx={ {
                                                '& .MuiOutlinedInput-root': {
                                                  '& fieldset': {
                                                    border: 'none',
                                                  },
                                                  '&:hover fieldset': {
                                                    border: 'none',
                                                  },
                                                  '&.Mui-focused fieldset': {
                                                    border: 'none',
                                                  },
                                                  borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                                },
                                              } }
                                            />
                                          </Grid>
                                          <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                                            <TextField
                                              label="Last Name"
                                              variant="outlined"
                                              id="claimant_last_name"
                                              name="claimant_last_name"
                                              value={ claimant_last_name }
                                              onChange={ onInputChange }
                                              onBlur={ onBlur }
                                              error={ !!errors.claimant_last_name && touched.claimant_last_name }
                                              helperText={ touched.claimant_last_name && errors.claimant_last_name }
                                              required
                                              fullWidth
                                              sx={ {
                                                '& .MuiOutlinedInput-root': {
                                                  '& fieldset': {
                                                    border: 'none',
                                                  },
                                                  '&:hover fieldset': {
                                                    border: 'none',
                                                  },
                                                  '&.Mui-focused fieldset': {
                                                    border: 'none',
                                                  },
                                                  borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                                },
                                              } }
                                            />
                                          </Grid>
                                        </Grid>
                                      </AccordionDetails>
                                    </Accordion>
                                  </Grid>
                                </Grid>


                                <Grid container item xs={ 12 } spacing={ 2 }>
                                  <Grid item xs={ 12 } sm={ 12 } md={ 12 }>
                                    <Accordion elevation={ 0 }>
                                      <AccordionSummary expandIcon={ <ExpandMoreIcon style={ { color: AddressDetailsError ? 'red' : ( localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" ) } } /> } aria-controls="personal-details-content" id="personal-details-header">
                                        <Typography color={ localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" } variant="h6" className="Nasaliza" fontSize="1.2rem"><LocationOnIcon fontSize="medium" style={ { marginRight: '10px' } } /> Address Details</Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>

                                        <Grid container item xs={ 12 } spacing={ 2 }>
                                          <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                                            <TextField
                                              label="Street Number"
                                              variant="outlined"
                                              id="claimant_street_number"
                                              name="claimant_street_number"
                                              value={ claimant_street_number }
                                              onChange={ onInputChange }
                                              onBlur={ onBlur }
                                              error={ !!errors.claimant_street_number && touched.claimant_street_number }
                                              helperText={ touched.claimant_street_number && errors.claimant_street_number }
                                              required
                                              fullWidth
                                              sx={ {
                                                '& .MuiOutlinedInput-root': {
                                                  '& fieldset': {
                                                    border: 'none',
                                                  },
                                                  '&:hover fieldset': {
                                                    border: 'none',
                                                  },
                                                  '&.Mui-focused fieldset': {
                                                    border: 'none',
                                                  },
                                                  borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                                },
                                              } }
                                            />
                                          </Grid>
                                          <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                                            <TextField
                                              label="Street Name"
                                              variant="outlined"
                                              id="claimant_street_name"
                                              name="claimant_street_name"
                                              value={ claimant_street_name }
                                              onChange={ onInputChange }
                                              onBlur={ onBlur }
                                              error={ !!errors.claimant_street_name && touched.claimant_street_name }
                                              helperText={ touched.claimant_street_name && errors.claimant_street_name }
                                              required
                                              fullWidth
                                              sx={ {
                                                '& .MuiOutlinedInput-root': {
                                                  '& fieldset': {
                                                    border: 'none',
                                                  },
                                                  '&:hover fieldset': {
                                                    border: 'none',
                                                  },
                                                  '&.Mui-focused fieldset': {
                                                    border: 'none',
                                                  },
                                                  borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                                },
                                              } }
                                            />
                                          </Grid>
                                          <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                                            <TextField
                                              label="City"
                                              variant="outlined"
                                              id="claimant_city"
                                              name="claimant_city"
                                              value={ claimant_city }
                                              onChange={ onInputChange }
                                              onBlur={ onBlur }
                                              error={ !!errors.claimant_city && touched.claimant_city }
                                              helperText={ touched.claimant_city && errors.claimant_city }
                                              required
                                              fullWidth
                                              sx={ {
                                                '& .MuiOutlinedInput-root': {
                                                  '& fieldset': {
                                                    border: 'none',
                                                  },
                                                  '&:hover fieldset': {
                                                    border: 'none',
                                                  },
                                                  '&.Mui-focused fieldset': {
                                                    border: 'none',
                                                  },
                                                  borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                                },
                                              } }
                                            />

                                          </Grid>
                                          <Grid container item xs={ 12 } spacing={ 2 }>
                                            <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                                              <FormControl required error={ !!errors.claimant_state && touched.claimant_state } fullWidth variant="standard">
                                                <InputLabel id="state-label">State</InputLabel>
                                                <Select
                                                  labelId="state-label"
                                                  id="claimant_state"
                                                  name="claimant_state"
                                                  value={ claimant_state }
                                                  onChange={ onSelectChange }
                                                  onBlur={ onBlur }
                                                  label="State"
                                                  MenuProps={ {
                                                    style: {
                                                      maxHeight: 280,
                                                    },
                                                  } }
                                                  sx={ {
                                                    '&:before': {
                                                      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                                    },
                                                    '&:hover:not(.Mui-disabled):before': {
                                                      borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
                                                    },
                                                    '&.Mui-focused:after': {
                                                      borderBottom: '2px solid rgba(0, 0, 0, 0.87)',
                                                    },
                                                    '& .MuiSelect-select': { textAlign: 'left' },
                                                  } }
                                                >
                                                  { states.map( ( state ) => (
                                                    <MenuItem key={ state.select } value={ state.select }>
                                                      { state.select }
                                                    </MenuItem>
                                                  ) ) }
                                                  <MenuItem value="null">null</MenuItem>
                                                </Select>
                                                { touched.claimant_state && errors.claimant_state && <p style={ { color: "red", margin: 0 } }>{ errors.claimant_state }</p> }
                                              </FormControl>

                                            </Grid>
                                            <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                                              <TextField
                                                label="ZIP"
                                                variant="outlined"
                                                id="claimant_zip"
                                                name="claimant_zip"
                                                value={ claimant_zip }
                                                onChange={ onInputChange }
                                                onBlur={ onBlur }
                                                error={ !!errors.claimant_zip && touched.claimant_zip }
                                                helperText={ touched.claimant_zip && errors.claimant_zip }
                                                required
                                                fullWidth
                                                sx={ {
                                                  '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                      border: 'none',
                                                    },
                                                    '&:hover fieldset': {
                                                      border: 'none',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                      border: 'none',
                                                    },
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                                  },
                                                } }
                                              />

                                            </Grid>
                                            <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                                              <FormControl required error={ !!errors.claimant_country && touched.claimant_country } fullWidth variant="standard">
                                                <InputLabel id="country-label">Country</InputLabel>
                                                <Select
                                                  labelId="country-label"
                                                  id="claimant_country"
                                                  name="claimant_country"
                                                  value={ claimant_country }
                                                  onChange={ onSelectChange }
                                                  onBlur={ onBlur }
                                                  label="Country"
                                                  sx={ {
                                                    '&:before': {
                                                      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                                    },
                                                    '&:hover:not(.Mui-disabled):before': {
                                                      borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
                                                    },
                                                    '&.Mui-focused:after': {
                                                      borderBottom: '2px solid rgba(0, 0, 0, 0.87)',
                                                    },
                                                    '& .MuiSelect-select': { textAlign: 'left' },
                                                  } }
                                                >
                                                  <MenuItem value="USA">USA</MenuItem>
                                                </Select>
                                                { touched.claimant_country && errors.claimant_country && (
                                                  <p style={ { color: "red", margin: 0 } }>{ errors.claimant_country }</p>
                                                ) }
                                              </FormControl>

                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </AccordionDetails>
                                    </Accordion>
                                  </Grid>
                                </Grid>
                                <Grid container item xs={ 12 } spacing={ 2 }>
                                  <Grid item xs={ 12 } sm={ 12 } md={ 12 }>
                                    <Accordion elevation={ 0 }>
                                      <AccordionSummary expandIcon={ <ExpandMoreIcon style={ { color: ContactDetailsError ? 'red' : ( localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" ) } } /> } aria-controls="personal-details-content" id="personal-details-header">
                                        <Typography color={ localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" } variant="h6" className="Nasaliza" fontSize="1.2rem"> <ContactPhoneIcon fontSize="medium" style={ { marginRight: '10px' } } /> Contact Details</Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>

                                        <Grid container item xs={ 12 } spacing={ 2 }>
                                          <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                                            <TextField
                                              label="Email Id"
                                              variant="outlined"
                                              id="claimant_email"
                                              name="claimant_email"
                                              value={ nonInsuredEmail }
                                              disabled
                                              fullWidth
                                              sx={ {
                                                '& .MuiOutlinedInput-root': {
                                                  '& fieldset': {
                                                    border: 'none',
                                                  },
                                                  '&:hover fieldset': {
                                                    border: 'none',
                                                  },
                                                  '&.Mui-focused fieldset': {
                                                    border: 'none',
                                                  },
                                                  borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                                },
                                              } }
                                            />
                                          </Grid>
                                          <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                                            <TextField
                                              label="Phone Number"
                                              variant="outlined"
                                              id="claimant_phone"
                                              name="claimant_phone"
                                              value={ claimant_phone }
                                              onChange={ onInputChange }
                                              onBlur={ onBlur }
                                              error={ !!errors.claimant_phone && touched.claimant_phone }
                                              helperText={ touched.claimant_phone && errors.claimant_phone }
                                              required
                                              fullWidth
                                              sx={ {
                                                '& .MuiOutlinedInput-root': {
                                                  '& fieldset': {
                                                    border: 'none',
                                                  },
                                                  '&:hover fieldset': {
                                                    border: 'none',
                                                  },
                                                  '&.Mui-focused fieldset': {
                                                    border: 'none',
                                                  },
                                                  borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                                },
                                              } }
                                            />
                                          </Grid>
                                        </Grid>
                                      </AccordionDetails>
                                    </Accordion>
                                  </Grid>
                                </Grid>
                                <Grid container item xs={ 12 } spacing={ 2 }>
                                  <Grid item xs={ 12 } sm={ 12 } md={ 12 }>
                                    <Accordion elevation={ 0 }>
                                      <AccordionSummary expandIcon={ <ExpandMoreIcon style={ { color: hasRelationshipError ? 'red' : ( localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" ) } } /> } aria-controls="personal-details-content" id="personal-details-header">
                                        <Typography color={ localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" } variant="h6" className="Nasaliza" fontSize="1.2rem">
                                          <GroupIcon fontSize="medium" style={ { marginRight: '10px' } } /> Relationship With Insured
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Grid container item xs={ 12 } spacing={ 2 }>
                                          <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                                            <FormControl fullWidth variant="outlined" error={ hasRelationshipError } required>
                                              <InputLabel id="relationship-label">Relationship to Insured</InputLabel>
                                              <Select
                                                labelId="relationship-label"
                                                id="claimant_relationship_with_insured"
                                                name="claimant_relationship_with_insured"
                                                value={ claimant_relationship_with_insured }
                                                onChange={ onInputChange }
                                                onBlur={ onBlur }
                                                label="Relationship to Insured"
                                                MenuProps={ {
                                                  style: {
                                                    maxHeight: 255,
                                                  },
                                                } }
                                                input={ <OutlinedInput label="Relationship to Insured" /> }
                                                sx={ {
                                                  '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                      border: 'none',
                                                    },
                                                    '&:hover fieldset': {
                                                      border: 'none',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                      border: 'none',
                                                    },
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                                  },
                                                  '& .MuiSelect-select': {
                                                    textAlign: 'left',
                                                  },
                                                } }
                                              >
                                                <MenuItem value="Spouse">Spouse</MenuItem>
                                                <MenuItem value="Child">Child</MenuItem>
                                                <MenuItem value="Parent">Parent</MenuItem>
                                                <MenuItem value="Sibling">Sibling</MenuItem>
                                                <MenuItem value="Friend">Friend</MenuItem>
                                                <MenuItem value="Other">Other</MenuItem>
                                              </Select>
                                              { hasRelationshipError && (
                                                <Typography variant="caption" color="error">
                                                  { errors.claimant_relationship_with_insured }
                                                </Typography>
                                              ) }
                                            </FormControl>

                                          </Grid>
                                          { claimant_relationship_with_insured === "Other" && (
                                            <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                                              <TextField
                                                label="Please specify"
                                                variant="outlined"
                                                id="claimant_other_relationship_with_insured"
                                                name="claimant_other_relationship_with_insured"
                                                value={ claimant_other_relationship_with_insured }
                                                onChange={ onInputChange }
                                                onBlur={ onBlur }
                                                error={ !!errors.claimant_relationship_with_insured && touched.claimant_relationship_with_insured }
                                                helperText={ touched.claimant_relationship_with_insured && errors.claimant_relationship_with_insured }
                                                required
                                                fullWidth
                                                sx={ {
                                                  '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                      border: 'none',
                                                    },
                                                    '&:hover fieldset': {
                                                      border: 'none',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                      border: 'none',
                                                    },
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                                  },
                                                } }
                                              />
                                            </Grid>
                                          ) }
                                        </Grid>
                                      </AccordionDetails>
                                    </Accordion>
                                  </Grid>
                                </Grid>
                                <Grid container item xs={ 12 } spacing={ 2 }>
                                  <Grid item xs={ 12 } sm={ 12 } md={ 12 }>
                                    <Accordion elevation={ 0 }>
                                      <AccordionSummary expandIcon={ <ExpandMoreIcon style={ { color: IdentityDetailsError ? 'red' : ( localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" ) } } /> } aria-controls="personal-details-content" id="personal-details-header">
                                        <Typography variant="h6" className="Nasaliza" fontSize="1.2rem"
                                          color={ localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" }

                                        >
                                          <PersonIcon fontSize="medium" style={ { marginRight: '10px' } } /> Identity Details</Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Grid container item xs={ 12 } spacing={ 2 }>
                                          <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                                            <FormControl required error={ !!errors.claimant_proof_of_identity && touched.claimant_proof_of_identity } fullWidth>
                                              <InputLabel id="proof-of-identity-label">Proof of Identity</InputLabel>
                                              <Select
                                                labelId="proof-of-identity-label"
                                                id="claimant_proof_of_identity"
                                                name="claimant_proof_of_identity"
                                                value={ claimant_proof_of_identity }
                                                onChange={ onSelectChange }
                                                onBlur={ onBlur }
                                                label="Proof of Identity"
                                                input={ <OutlinedInput label="Proof of Identity" /> }
                                                sx={ {
                                                  '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                      border: 'none',
                                                    },
                                                    '&:hover fieldset': {
                                                      border: 'none',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                      border: 'none',
                                                    },
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                                  },
                                                  '& .MuiSelect-select': { textAlign: 'left' },
                                                } }
                                              >
                                                <MenuItem value="Driver's License">Driver's License</MenuItem>
                                                <MenuItem value="Passport">Passport</MenuItem>
                                                <MenuItem value="Green Card">Green Card</MenuItem>
                                                <MenuItem value="State Id">State Id</MenuItem>
                                                <MenuItem value="Social Security Number (SSN)">Social Security Number (SSN)</MenuItem>
                                              </Select>
                                              { touched.claimant_proof_of_identity && errors.claimant_proof_of_identity && (
                                                <Typography variant="caption" color="error">
                                                  { errors.claimant_proof_of_identity }
                                                </Typography>
                                              ) }
                                            </FormControl>

                                          </Grid>
                                          <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                                            <TextField
                                              label="Proof of Identity Number"
                                              variant="outlined"
                                              id="claimant_proof_of_identity_number"
                                              name="claimant_proof_of_identity_number"
                                              value={ claimant_proof_of_identity_number }
                                              onChange={ onInputChange }
                                              onBlur={ onBlur }
                                              error={ !!errors.claimant_proof_of_identity_number && touched.claimant_proof_of_identity_number }
                                              helperText={ touched.claimant_proof_of_identity_number && errors.claimant_proof_of_identity_number }
                                              required
                                              fullWidth
                                              sx={ {
                                                '& .MuiOutlinedInput-root': {
                                                  '& fieldset': {
                                                    border: 'none',
                                                  },
                                                  '&:hover fieldset': {
                                                    border: 'none',
                                                  },
                                                  '&.Mui-focused fieldset': {
                                                    border: 'none',
                                                  },
                                                  borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                                },
                                              } }
                                            />
                                          </Grid>
                                        </Grid>
                                      </AccordionDetails>
                                    </Accordion>
                                  </Grid>
                                </Grid>
                                <Grid item xs={ 12 } sx={ { marginTop: 2, display: 'flex', justifyContent: 'space-between' } }>
                                  <Grid item>
                                    <StyledButtonComponent
                                      buttonWidth={ 100 }
                                      disableColor={ "#CCCCCC" }
                                      disabled={ activeStep === 0 }
                                      onClick={ () =>
                                      {
                                        handleFieldChange( 3 );  // Call handleFieldChange with a step index and value
                                        handleBack();               // Call handleGotoReview after
                                      } }
                                      backgroundColor={ activeStep > 0
                                        ? ( localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" )
                                        : "#999999" } // Pass calculated background color here
                                    >
                                      Back
                                    </StyledButtonComponent>
                                  </Grid>
                                  <Grid item sx={ { display: 'flex', gap: 2 } }>
                                    <StyledButtonComponent
                                      buttonWidth={ 100 }
                                      disableColor={ "#CCCCCC" }
                                      onClick={ handleGotoReview }
                                      disabled={ !allStepsValid }
                                      backgroundColor={ allStepsValid
                                        ? ( localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" )
                                        : "#999999" }

                                    >
                                      Review
                                    </StyledButtonComponent>
                                    <StyledButtonComponent
                                      buttonWidth={ 100 }
                                      disableColor={ "#CCCCCC" }
                                      onClick={ handleNext }
                                      disabled={ !canSaveStep4 }
                                      backgroundColor={ canSaveStep4
                                        ? ( localCompany.ic_primary_color ? localCompany.ic_primary_color : "#010066" )
                                        : "#999999" }  // Pass calculated background color here
                                    >
                                      { activeStep === steps.length - 1 ? 'Review' : 'Next' }
                                    </StyledButtonComponent>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          </>
                        ) )
                      ) )
                  }
                </React.Fragment>
              ) }
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
      <Snackbar
        open={ snackbarOpen }
        autoHideDuration={ 10000 }
        onClose={ () => setSnackbarOpen( false ) }
        anchorOrigin={ { vertical: 'top', horizontal: 'right' } }
      >
        <Alert onClose={ () => setSnackbarOpen( false ) } severity={ snackbarSeverity }>
          { snackbarMessage }
        </Alert>
      </Snackbar>

      <Footer />
    </>
  );
};
export default Claimcapture;
