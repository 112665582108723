import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { Card } from "@mui/material";
import VuiBox from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/VuiBox";
import VuiTypography from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/VuiTypography";
import MDTypography from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDTypography";
import MDBox from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDBox";
import DashboardLayout from "../../../CompanyDashboardChartsCardsLayouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../CompanyDashboardChartsCardsLayouts/Navbars/DashboardNavbar";
import ReportsBarChart from "../../../CompanyDashboardChartsCardsLayouts/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "../../../CompanyDashboardChartsCardsLayouts/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "../../../CompanyDashboardChartsCardsLayouts/Cards/StatisticsCards/ComplexStatisticsCard";
import VerticalBarChart from "../../../CompanyDashboardChartsCardsLayouts/Charts/BarCharts/VerticalBarChart";
import LineChart from "../../../CompanyDashboardChartsCardsLayouts/Charts/LineCharts copy/LineChart";
import { lineChartOptionsDashboard } from "../../../CompanyDashboardChartsCardsLayouts/Charts/LineCharts copy/lineChartOptions";
import axios from "axios";
import SmartQuotePortalCard from "../BatchPolicyDashboard/SmartQuotePortalCard";
import DocAIQuoteCard from "../BatchPolicyDashboard/DocAIQuoteCard";
import Mail2QuoteCard from "../BatchPolicyDashboard/Mail2QuoteCard";
import DocAIQuoteBatchProcessCard from "../BatchPolicyDashboard/DocAIQuoteBatchProcessCard";

function PoliciesDashboard() {
  const [policiesData, setPoliciesData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const company = JSON.parse(localStorage.getItem("carrier_admin_company"));
  const companyId = company ? company.ic_id : null; 
  const ic_id = localStorage.getItem("ic_id_for_dashboard")
  const ic_name = localStorage.getItem("ic_name_for_dashboard")
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_URL,
    withCredentials: true
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formData = new FormData();
        formData.append("ic_id", ic_id || companyId);
        const response = await axiosInstance.post(
          'get_all_policy_details/',
          formData
        );
        const transformedData = transformAPIData(response.data);
        setPoliciesData(transformedData);
        setIsLoading(false);
      } catch (err) {
        setError("Failed to fetch policies data");
        setIsLoading(false);
      }
    };
    fetchData();
  }, [ic_id, companyId]);

  const transformAPIData = (apiData) => {
    return {
      premiumAmountByYear: {
        labels: Object.keys(apiData.premium_by_year),
        datasets: {
          label: "Premium Amount",
          data: Object.values(apiData.premium_by_year),
        },
      },
      dashboardSummary: {
        Total_Premium: `$${apiData["Total_Premium"]}`,
        policies: apiData.Total_policies,
        activePolicies: apiData.active_policies,
        activePremium: `$${apiData["active_policies_premium"]}`,
        policies_percentage: apiData.percentage_changes.policies,
        active_policies_percentage: apiData.percentage_changes.active_policies,
        active_premium_percentage: apiData.percentage_changes.active_premium,
        premium_percentage: apiData.percentage_changes.premium,
        policies_per_month_ongoing_year: [
          {
            name: "Policies",
            data: Object.values(apiData.monthly_policies),
          },
        ],
        premium_per_month_ongoing_year: [
          {
            name: "Premium",
            data: Object.values(apiData.monthly_premium),
          },
        ],
        Premium_by_H0_Category: {
          labels: ["H01", "H02", "H03", "H04", "H05", "H06", "H07", "H08"],
          datasets: {
            label: "Premium Amount",
            data: Object.values(apiData.Premium_by_type),
          },
        },
        Policies_by_H0_Category: {
          labels: ["H01", "H02", "H03", "H04", "H05", "H06", "H07", "H08"],
          datasets: [
            {
              label: "Total Policies",
              data: Object.values(apiData.No_of_policies_by_HO),
            },
          ],
        },
      },
    };
  };

  if (isLoading) {
    return (
      <DashboardLayout>
        <MDTypography style={{ color: "#0B70FF", textAlign: "left", fontWeight: "bold", fontSize: "1.5rem" }} >Policies - {ic_name}</MDTypography>
        <DashboardNavbar />
        <div>Loading policies data...</div>
      </DashboardLayout>
    );
  }

  if (error) {
    return (
      <DashboardLayout>
        <MDTypography style={{ color: "#0B70FF", textAlign: "left", fontWeight: "bold", fontSize: "1.5rem" }} >Policies - {ic_name}</MDTypography>
        <DashboardNavbar />
        <div>Error: {error}</div>
      </DashboardLayout>
    );
  }
  // Access transformed data
  const {
    premiumAmountByYear,
    dashboardSummary } = policiesData;

  return (
    <DashboardLayout>
      <MDTypography style={{ color: "#0B70FF", textAlign: "left", fontWeight: "bold", fontSize: "1.5rem" }} >Policies - {ic_name}</MDTypography>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12} xl={12} md={12}>
            <SmartQuotePortalCard title="SmartQuote Portal" />
          </Grid>
          <Grid item xs={12} lg={12} xl={12} md={12}>
            <DocAIQuoteCard title="DocAI Quote" />
          </Grid>
          <Grid item xs={12} lg={12} xl={12} md={12}>
            <Mail2QuoteCard title="Mail2Quote" />
          </Grid>
          <Grid item xs={12} lg={12} xl={12} md={12}>
            <DocAIQuoteBatchProcessCard title="DocAI Quote Batch Process" />
          </Grid>
          <Grid item xs={12} lg={12} xl={12} md={12}>
            <MDTypography style={{ color: "#0B70FF", textAlign: "left", fontWeight: "bold", fontSize: "1.5rem" }} >Policies - Analytics</MDTypography>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5} mt={3}>
              <ComplexStatisticsCard
                color="dark"
                icon="description"
                title="Total Policies"
                count={dashboardSummary.policies}
                percentage={{
                  color: dashboardSummary.policies_percentage >= 0 ? 'success' : 'error',
                  amount: `${Math.trunc(dashboardSummary.policies_percentage)}%`,
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5} mt={3}>
              <ComplexStatisticsCard
                color="success"
                icon="monetization_on"
                title="Total Premium Amount"
                count={dashboardSummary.Total_Premium}
                percentage={{
                  color: dashboardSummary.premium_percentage >= 0 ? 'success' : 'error',
                  amount: `${Math.trunc(dashboardSummary.premium_percentage)}%`,
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5} mt={3}>
              <ComplexStatisticsCard
                color="info"
                icon="description"
                title="Total Active Policies"
                count={dashboardSummary.activePolicies}
                percentage={{
                  color: dashboardSummary.active_policies_percentage >= 0 ? 'success' : 'error',
                  amount: `${Math.trunc(dashboardSummary.active_policies_percentage)}%`,
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5} mt={3}>
              <ComplexStatisticsCard
                color="info"
                icon="monetization_on"
                title="Active Premium Amount"
                count={dashboardSummary.activePremium}
                percentage={{
                  color: dashboardSummary.active_premium_percentage >= 0 ? 'success' : 'error',
                  amount: `${Math.trunc(dashboardSummary.active_premium_percentage)}%`,
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={6.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="success"
                  title="Premium Amount"
                  description="Per each year"
                  chart={premiumAmountByYear}
                  height="21.2rem"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <VerticalBarChart
                  icon={{ color: "info", component: "trending_up" }}
                  title="Total Policies by HO Category"
                  description=""
                  height="20.5rem"
                  chart={dashboardSummary.Policies_by_H0_Category}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6} xl={6} md={6}>
              <Card style={{ padding: "20px" }}>
                <VuiBox sx={{ height: "100%" }}>
                  <VuiTypography variant="lg" color="#010066" fontWeight="bold">
                    Total Policies per Month of Ongoing Year
                  </VuiTypography>
                  <VuiBox sx={{ height: "320px" }}>
                    <LineChart
                      lineChartData={dashboardSummary.policies_per_month_ongoing_year}
                      lineChartOptions={lineChartOptionsDashboard}
                    />
                  </VuiBox>
                </VuiBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="info"
                  title="Premium amount by HO Category"
                  description="Home Owners (H01 ... H08)"
                  date="just updated"
                  chart={dashboardSummary.Premium_by_H0_Category}
                  height="20.2rem"
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12} xl={12} md={12}>
              <Card style={{ padding: "20px" }}>
                <VuiBox sx={{ height: "100%" }}>
                  <VuiTypography variant="lg" color="#010066" fontWeight="bold" mt="55px">
                    Total Premium per Month of Ongoing Year
                  </VuiTypography>
                  <VuiBox sx={{ height: "310px" }}>
                    <LineChart
                      lineChartData={dashboardSummary.premium_per_month_ongoing_year}
                      lineChartOptions={lineChartOptionsDashboard}
                    />
                  </VuiBox>
                </VuiBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default PoliciesDashboard;
