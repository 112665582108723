import React from "react";
import { Helmet } from 'react-helmet';
import { Box, Typography, Button } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Ivan from '../assets/InsurAI_Agent.png'; // Ensure this path is correct

const NotFound = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '500px',
        textAlign: 'center',
        backgroundColor: '#f9f9f9', // light background for comfort
        padding: 3,
        borderRadius: '12px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // subtle shadow for better UI
      }}
    >
      <Helmet>
        <title>404 - Page Not Found</title>
        <meta name="description" content="Sorry, the page you're looking for doesn't exist." />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <img 
        src={Ivan} 
        alt="Ivan - Friendly Agent" 
        height={'180px'} 
        style={{ marginBottom: '16px' }}
      />

      <Typography 
        variant="h1" 
        sx={{ 
          fontWeight: 'bold', 
          fontSize: '2.5rem', 
          color: '#001660', 
          mb: 1,
          animation: 'fadeIn 1.5s ease' // Adding smooth animation
        }}
      >
        Oops! 404 - Page Not Found
      </Typography>

      <Typography 
        variant="body1" 
        sx={{ mb: 3, color: '#666', maxWidth: '400px' }}
      >
        It looks like the page you're trying to reach doesn't exist or has been moved.
      </Typography>

      <Button 
        variant="contained" 
        color="primary" 
        href="/" 
        sx={{ 
          textTransform: 'none', 
          padding: '10px 20px', 
          fontSize: '1rem', 
          borderRadius: '8px',
          backgroundColor: '#1976d2',
          '&:hover': {
            backgroundColor: '#1565c0',
          },
        }}
      >
        <ErrorOutlineIcon sx={{ mr: 1 }} />
        Go Back Home
      </Button>

      <style>{`
        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }
      `}</style>
    </Box>
  );
};

export default NotFound;
