import { useState } from "react";
import PropTypes from "prop-types";
import { Card, Grid, useTheme, useMediaQuery, } from "@mui/material";
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";
import MDBox from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDBox";
import MDTypography from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDTypography";
import MDButton from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDButton";
import EmailtoFNOLClaimsView from "./EmailtoFNOLClaimsView";
import axios from "axios";
import { CircularProgress } from "@mui/material";

function EmailtoFNOLBatchCard({ title }) {
  const [expanded, setExpanded] = useState(false);
  const [processCount, setProcessCount] = useState({ success: 0, failure: 0, editedSuccess: 0 });
  const [claims, setClaims] = useState({ success: [], failure: [], editedSuccess: [] });
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_URL,
    withCredentials: true
  });

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleFetchClaims = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        'get_email_to_fnol_claims/'
      );
      setClaims({
        success: Object.values(response.data.success_data),
        failure: Object.values(response.data.failure_data),
        editedSuccess: Object.values(response.data.edited_success_data),
      });
      setProcessCount({
        success: response.data.no_of_success_mails,
        failure: response.data.no_of_failure_mails,
        editedSuccess: response.data.no_of_edited_success_mails
      });
    } catch (error) {
      console.error("Error fetching claims:", error);
    }
    finally {
      setIsLoading(false);
    }
  };
  return (
    <Card id="delete-account">
      <MDBox pt={2} pb={2} px={2}>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between">
          <Grid item xs={isMobile ? 6 : 4} container alignItems="center">
            <MDTypography variant="h6" fontWeight="medium">
              {title}
            </MDTypography>
          </Grid>
          <Grid item xs={isMobile ? 6 : 2} container alignItems="left">
            <MDButton
              variant="gradient"
              color="info"
              onClick={handleFetchClaims}
              sx={{ ml: 2, display: 'flex', alignItems: 'left' }}
              disabled={isLoading}
            >
              {isLoading && <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />}
              {isLoading ? "Fetching..." : "Fetch Claims"}
            </MDButton>
          </Grid>
          <Grid item xs={isMobile ? 6 : 3} container alignItems="left">
            <MDTypography variant="body2" color="textSecondary" fontWeight="medium" sx={{ ml: 2, fontSize: '14px'}}>
              Success: {processCount.success}, Failure: {processCount.failure}, Edited Success: {processCount.editedSuccess}
            </MDTypography>
          </Grid>
          <Grid item xs={isMobile ? 6 : 3} container justifyContent="flex-end">
            <MDButton variant="gradient" color="dark" onClick={toggleExpand} sx={{ width: { xs: '100%', sm: 'auto', md: '72%' } }}>
              <Icon sx={{ fontWeight: "bold" }}>{expanded ? "expand_less" : "expand_more"}</Icon>
              &nbsp;{expanded ? "Hide Email Claims" : "View Email Claims"}
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <MDBox p={2}>
          <EmailtoFNOLClaimsView claimsData={claims} />
        </MDBox>
      </Collapse>
    </Card>
  );
}

// Setting default values for the props of EmailtoFNOLBatchCard
EmailtoFNOLBatchCard.defaultProps = {
  title: "Email Claims",
};

// Typechecking props for the EmailtoFNOLBatchCard
EmailtoFNOLBatchCard.propTypes = {
  title: PropTypes.string,
};

export default EmailtoFNOLBatchCard;
