import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Card, Grid, useTheme, useMediaQuery, } from "@mui/material";
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";
import MDBox from '../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDBox';
import MDTypography from '../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDTypography';
import MDButton from '../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDButton';
import { CircularProgress } from "@mui/material";
import axios from 'axios';
import DocAIQuotesView from "./DocAIQuotesView";


function DocAIQuoteCard({ title }) {
  const [expanded, setExpanded] = useState(false);
  const [docAIquotes, setDocAIQuotes] = useState([]);
  const [docAIQuotesCount, setDocAIQuotesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_URL,
    withCredentials: true
  });

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleFetchDocAIQuotes = async () => {
    setIsLoading(true);
    try {
      const ic_id = localStorage.getItem("ic_id_for_dashboard")
      const company = JSON.parse(localStorage.getItem("carrier_admin_company"));
      const formData = new FormData();
      formData.append("ic_id", ic_id || company.ic_id);
      formData.append("channel", "DocAI Quote");
      const response = await axiosInstance.post(
        'Policy/get_quotes_by_channel/',
        formData
      );
      setDocAIQuotes(response.data);
      setDocAIQuotesCount(response.data.length);
    } catch (error) {
      console.error("Error fetching claims:", error);
    }
    finally {
      setIsLoading(false);
    }
  };

  return (
    <Card id="delete-account">
      <MDBox pt={2} pb={2} px={2}>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between">
          <Grid item xs={isMobile ? 6 : 4} container alignItems="center">
            <MDTypography variant="h6" fontWeight="medium">
              {title}
            </MDTypography>
          </Grid>
          <Grid item xs={isMobile ? 6 : 2} container alignItems="left">
            <MDButton
              variant="gradient"
              color="info"
              onClick={handleFetchDocAIQuotes}
              sx={{ ml: 2, display: 'flex', alignItems: 'left' }}
              disabled={isLoading}
            >
              {isLoading && <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />} {/* Add spacing */}
              {isLoading ? 'Fetching...' : 'Fetch Quotes'}
            </MDButton>
          </Grid>
          <Grid item xs={isMobile ? 6 : 3} container alignItems="left">
            <MDTypography variant="body2" color="textSecondary" fontWeight="medium" sx={{ ml: 2, fontSize: '14px' }}>
              Success Quotes: {docAIQuotesCount}  
            </MDTypography>
          </Grid>
          <Grid item xs={isMobile ? 6 : 3} container justifyContent="flex-end">
            <MDButton variant="gradient" color="dark" onClick={toggleExpand} sx={{ width: { xs: '100%', sm: 'auto', md: '84%' } }}>
              <Icon sx={{ fontWeight: "bold" }}>{expanded ? "expand_less" : "expand_more"}</Icon>
              &nbsp;{expanded ? "Hide DocAI Quotes" : "View DocAI Quotes"}
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <MDBox p={2}>
          <DocAIQuotesView quotesData={docAIquotes} /> {/* Pass claims data to FNOLClaimsView */}
        </MDBox>
      </Collapse>
    </Card>
  );
}

// Setting default values for the props of FNOLCard
DocAIQuoteCard.defaultProps = {
  title: "Success Claims",
};

// Typechecking props for the FNOLCard
DocAIQuoteCard.propTypes = {
  title: PropTypes.string,
};


export default DocAIQuoteCard