import React from 'react';
import Card from "@mui/material/Card";
import MDBox from '../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDBox';
import MDTypography from '../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDTypography';


function SmartQuotesView({ quotesData }) {
  console.log("quotes in view", quotesData);
  
  return (
    <Card id="billing-info">
      <MDBox pt={3} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Quotes Information
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} sx={{ maxHeight: "422px", overflowY: "auto" }}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <table style={{ width: "100%", tableLayout: "fixed" }}>
            <thead>
              <tr>
                <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Quote Number</th>
                <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Quote Amount</th>
                <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Policy Holder Name</th>
                <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Policy Holder Email</th>
                <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Selected Policy</th>
                <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Quote Created At</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(quotesData) && quotesData.length > 0 ? (
                quotesData.map((quote, index) => (
                  <tr key={quote.quote_number}> {/* Use a unique key */}
                    <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{quote.quote_number}</td>
                    <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>${quote.quote_amount}</td>
                    <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{quote.policy_holder_name}</td>
                    <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{quote.policy_holder_email}</td>
                    <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{quote.selected_policy}</td>
                    <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{quote.policy_created_at}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" style={{ padding: "8px", textAlign: "center" }}>
                    No Quotes found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default SmartQuotesView