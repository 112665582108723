import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDBox";
import MDTypography from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDTypography";
import MDButton from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDButton";
import { ChevronLeft, Paperclip, Clock, Tag } from 'lucide-react';
import { Edit as EditIcon } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from "axios";
import { Tooltip as ToolTip } from '@mui/material';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Snackbar, // Import Snackbar
  Alert, // Import Alert 
  Typography,
  Box,
  Grid,
  TextField,
  Backdrop,
  useTheme, useMediaQuery,IconButton, Checkbox
} from "@mui/material";
import { CheckCircle as ValidateIcon, Warning as WarningIcon } from "@mui/icons-material"
import DownloadIcon from '@mui/icons-material/Download';
import StyledButtonComponent from "../../../components/StyledButton";

// Custom Email template
function removeCidReferencesAndLinks(text) {
  text = text.replace(/\[cid:[^\]]+\]|<[^>]+cid:[^>]+>/g, ''); // Remove cid references
  text = text.replace(/<a\s+href=[^>]+>[^<]+<\/a>/gi, ''); // Remove <a> tags (links)
  text = text.replace(/mailto:[^\s]+/gi, ''); // Remove mailto links
  // Remove links starting with http, https, or www., capturing only the link part
  text = text.replace(/(https?:\/\/|www\.)[^\s]+/gi, (match) => {
    return ''; // Replace the matched link with an empty string
  });
  return text;
}

const EmailView = ({ emailDetails, handleOpenDocumentViewer }) => { // Receive props
  console.log("details email", emailDetails,);
  const cleanBody = removeCidReferencesAndLinks(emailDetails.body);
  const [email, setEmail] = useState({
    subject: emailDetails.subject,
    sender: emailDetails.sender_name || 'Unknown Sender',
    senderEmail: emailDetails.email_id || 'unknown@example.com', // Use sender_email 
    time: emailDetails.email_time,
    body: cleanBody, // Use the cleaned body
    attachments: emailDetails.document_name || [],
    tags: emailDetails.tags || []
  });

  return (
    <div className="email-view">
      {/* Header */}
      <header className="header">
        <div className="header-content">
          <div className="header-left">
            <ChevronLeft className="back-button" />
            <h1 className="subject">{email.subject}</h1>
          </div>
        </div>
      </header>

      {/* Email Content */}
      <main className="main-content">
        <div className="email-container">
          {/* Sender Info */}
          <div className="sender-info">
            <div>
              <p className="sender-email">{`<${email.senderEmail}>`}</p>
            </div>
            <div className="email-time">
              <Clock className="time-icon" />
              <p>{new Date(email.time).toLocaleString()}</p>
            </div>
          </div>

          {/* Tags */}
          <div className="tags">
            {email.tags.map((tag, index) => (
              <div key={index} className="tag">
                <Tag className="tag-icon" />
                <span className="tag-text">{tag}</span>
              </div>
            ))}
          </div>

          {/* Email Body */}
          <div className="email-body">{email.body}</div>

          {/* Attachments */}
          {/* Attachments */}
          {email.attachments && email.attachments.length > 0 ? ( // Only show if there are attachments
            <div className="attachments">
              <h3 className="attachments-title">Attachments:</h3>
              <div className="attachments-list">
                {email.attachments ? (
                  <div
                    className="attachment"
                    onClick={handleOpenDocumentViewer}
                    style={{ cursor: 'pointer' }}
                  >
                    <Paperclip className="attachment-icon" />
                    <span className="attachment-name">{email.attachments}</span>
                  </div>
                ) : (
                  <div className="attachment">
                    <span className="attachment-name">No document found</span>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              <h3 className="attachments-title" style={{ marginTop: "2rem" }}>Attachments:</h3>
              <div className="attachment">
                <span className="attachment-name">No document found</span>
              </div>
            </>
          )}
        </div>
      </main>
    </div>
  );
};

const initialValues = {
  policy_number: "",
  claim_reported_by: "",
  loss_date_and_time: "",
  loss_type: "",
  loss_address: "",
  loss_street: "",
  loss_city: "",
  loss_state: "",
  loss_zip: "",
  loss_country: "",
  police_fire_contacted: false,
  report_number: null,
  loss_damage_description: "",
};

function EmailtoFNOLClaimsView({ claimsData }) {
  const [view, setView] = useState("success");
  const [openDocument, setOpenDocument] = useState(false);
  const [documentContent, setDocumentContent] = useState(null);
  const [documentType, setDocumentType] = useState(null);
  const [documentName, setDocumentName] = useState("");
  const [loadingDocuments, setLoadingDocuments] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [openEmailView, setOpenEmailView] = useState(false);
  const [selectedEmailData, setSelectedEmailData] = useState(null);
  const [loadingEmailView, setLoadingEmailView] = useState({}); // Separate loading for email view
  const [emailStatusType, setEmailStatusType] = useState(""); // Add emailStatusType here

  // const [editMode, setEditMode] = useState(false);
  // const [editEmail, setEditEmail] = useState(null); // Email being edited
  const [pdfUrl, setPdfUrl] = useState(null);
  // const [missingFields, setMissingFields] = useState([]);
  const [content, setContent] = useState('');
  const [contentType, setContentType] = useState(null);
  const [displayValues, setDisplayValues] = useState({});
  const [queryvalues, setQueryvalues] = useState(initialValues);
  const [processSubmit, setProcessSubmit] = useState(false);
  const [enableFields, setEnableFields] = useState(false);
  const [hasEmptyOrInvalidValues, setHasEmptyOrInvalidValues] = useState(true);
  // const [errorMessage, setErrorMessage] = useState("");
  const [openPdf, setOpenPdf] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [updateDisplay, setUpdateDisplay] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState('');
  // const [Edit, setEdit] = useState(false)
  const [loadingEdit, setLoadingEdit] = useState({}); // Loading state for edit button
  const userAccessString = localStorage.getItem('userAccess');
  const userAccess = userAccessString ? JSON.parse(userAccessString) : [];
  const isUnderwriter = userAccess.includes("underwriter");
  const [propertyAddressValidation, setPropertyAddressValidation] = useState("");
  const [validatingAddress, setValidatingAddress] = useState(false);
  const [spittedAddress, setSpittedAddress] = useState(null);
  const [suggestedAddress, setSuggestedAddress] = useState(null);
  const [showAddress, setShowAddress] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const [validatedAddressKey, setValidatedAddressKey] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [editingAddress, setEditingAddress] = useState(false);

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_URL,
    withCredentials: true
  });





  // email edit functionality

  const handleEditClaim = (email, status) => {
    setLoadingEdit((prevLoading) => ({ ...prevLoading, [email]: true })); // Start loading
    // setEditEmail(email);
    setEmailStatusType(status); // Set emailStatusType for editing
    fetchEditData(email, status);
    // setEditMode(true);
  };

  const fetchEditData = (email, status) => {
    console.log("clikced the edit email", email, status);

    const formData = new FormData();
    formData.append('email', email);
    formData.append('status', status);

    axiosInstance.post('AI/get_document_by_email/', formData)
      .then(response => {
        console.log("Fetch Edit Data Response:", response.data);
        if (response.data.success) {
          if (response.data.content_type === "pdf") {
            const pdfBytes = Uint8Array.from(atob(response.data.content), c => c.charCodeAt(0));
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            setPdfUrl(url);
          }
          if (response && response.data && response.data.Errors && response.data.Errors.length > 0) {
            for (const error of response.data.Errors) {
              if ("Invalid Address" in error) {
                setPropertyAddressValidation(null);
                break; // Remove this 'break' if you want to process all errors
              }
            }
          }       


          setContentType(response.data.content_type);
          setContent(response.data.content);

          // setEmailBody(response.data.body);
          // setMissingFields(response.data.Errors);
          setDocumentName(response.data.document_name);
          const extractedResponseData = response.data.Extracted_data;
          setQueryvalues(extractedResponseData);
          const displayExtractedData = mapResponseToDisplayFields(extractedResponseData);
          setDisplayValues(displayExtractedData);
          setSelectedEmail(email);
          setOpenPdf(true);
        } else {
          console.error("Error fetching data for editing:", response.data.error);
        }
      })
      .catch(error => {
        console.log("Error fetching data for editing:", error);

        // Handle error and show Snackbar
        setSnackbarSeverity("error");
        if (error.response && error.response.data && error.response.data.error) {
          setSnackbarMessage(error.response.data.error);
        } else {
          setSnackbarMessage("An error occurred while fetching the document.");
        }
        setSnackbarOpen(true);
      })
      .finally(() => { // Correct syntax for finally block
        setLoadingEdit((prevLoading) => ({ ...prevLoading, [email]: false })); // Stop loading
      });
  };

  const mapResponseToDisplayFields = (extractedResponseData) => {
    return {
      "Policy Number": extractedResponseData.policy_number,
      "Reported By": extractedResponseData.claim_reported_by,
      "Loss Date and Time": extractedResponseData.loss_date_and_time,
      "Type of Loss": extractedResponseData.loss_type,
      "Loss Location": `${extractedResponseData.street_number} ${extractedResponseData.street_name}, ${extractedResponseData.loss_city}, ${extractedResponseData.loss_state}, ${extractedResponseData.loss_zip}, ${extractedResponseData.loss_country}`,
      "Police/Fire Department Contacted?": extractedResponseData.police_fire_contacted ? 'True' : 'False',
      "Report Number": extractedResponseData.report_number,
      "Loss Damage Description": extractedResponseData.loss_damage_description,
    };
  };

  const handleExtractClaimSubmit = (displayValues, queryvalues) => {
    console.log(displayValues, queryvalues, selectedEmail);
    // setEdit(true)
    setProcessSubmit(true)
    const formData = new FormData();
    formData.append('policy_number', displayValues["Policy Number"] || queryvalues.policy);
    formData.append('loss_date_and_time', displayValues["Loss Date and Time"] || queryvalues.loss_date_and_time);
    formData.append('loss_type', displayValues["Type of Loss"] || queryvalues.loss_type);
    formData.append('loss_damage_description', displayValues["Loss Damage Description"] || queryvalues.loss_damage_description);
    formData.append('loss_address', queryvalues.street_number ?? displayValues["Loss Location"]);
    formData.append('loss_street', queryvalues.street_name);
    formData.append('loss_city', queryvalues.loss_city);
    formData.append('loss_state', queryvalues.loss_state);
    formData.append('loss_zip', queryvalues.loss_zip);
    formData.append('loss_country', queryvalues.loss_country);
    formData.append('police_fire_contacted', displayValues["Police/Fire Department Contacted?"] || queryvalues.police_fire_contacted);
    formData.append('report_number', displayValues["Report Number"] || queryvalues.report_number);
    formData.append('claim_reported_by', displayValues["Reported By"] || queryvalues.reported_by || queryvalues.claim_reported_by);
    formData.append('email', selectedEmail);

    submitClaimDetails(formData);
  }


  const submitClaimDetails = async (formData) => {
    console.log(formData);
    await axiosInstance.post('AI/email_to_fnol_edit/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        console.log(response);
        localStorage.setItem("claimIDofEditedEmail", JSON.stringify(response.data.Claim_id));
        localStorage.setItem("companyofEditedEmail", JSON.stringify(response.data.company_details));
        setQueryvalues(initialValues);
        setDisplayValues({})
        setProcessSubmit(false);
        setOpenPdf(false);
        setSuccessPopup(true);

      }).catch(error => {
        console.log(error);
        setProcessSubmit(false);
        setValidationError("An error occurred. Please try again later.");
        setOpenSnackbar(true);
      });
  };

  const handleInputChange = (field, value) => {
    console.log(field, value);
    setDisplayValues(prevValues => ({
      ...prevValues,
      [field]: value
    }));
    setQueryvalues(prevValues => ({
      ...prevValues,
      [field.toLowerCase().replace(/ /g, '_')]: value // Ensure field names match
    }));
  };

  console.log("display values , queryvalues", displayValues, queryvalues);

  const handleSave = () => {
    console.log(Object.values(displayValues).some(value => !value));
    console.log(displayValues);
    setEnableFields(false);
    setShowAddress(false);


  };

  useEffect(() => {
    setHasEmptyOrInvalidValues(
      Object.values(displayValues).some(value => !value) || propertyAddressValidation === null);
  }, [updateDisplay, displayValues, propertyAddressValidation]);

  useEffect(() => {
    setHasEmptyOrInvalidValues(Object.values(displayValues).some(value => !value) || propertyAddressValidation === null)
  }, [displayValues, propertyAddressValidation]);

  const handleValidateAddress = async (key, sectionName) => {
    setValidatingAddress(true);
    setPropertyAddressValidation(null);
    const addressToValidate = displayValues[key];
    try {
      const response = await axiosInstance.post('validate_address/',
        {
          address: addressToValidate,
        }
      );
      if (response.data.validated_address && response.data.splitted_address) {
        setSuggestedAddress(response.data.validated_address);
        setSpittedAddress(response.data.splitted_address);
        setShowAddress(true);
        setValidationError(null);
        setValidatedAddressKey(key);
      } else {
        if (key === "Loss Location") {
          setPropertyAddressValidation(null);
        }
        setValidationError(
          "Address is not valid. Please check your address."
        );
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Address validation error:", error);
      if (key === "Loss Location") {
        setPropertyAddressValidation(null);
      }
      setValidationError(`${error.response.data.error} Please Check you Address again` ||
        "An error occurred during validation. Please try again later."
      );
      setOpenSnackbar(true);
    } finally {
      setValidatingAddress(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    // setSnackbarOpen(false);
    setOpenSnackbar(false)
  };

  const handleConfirmAddress = (spittedAddress, keys) => {
    if (keys === "Loss Location") {
      setQueryvalues(prevValues => ({
        ...prevValues,
        loss_address: spittedAddress.street_number || '',
        loss_street: spittedAddress.street_name || '',
        loss_city: spittedAddress.city || '',
        loss_state: spittedAddress.state || '',
        loss_zip: spittedAddress.zip_code || '',
        loss_country: spittedAddress.country || '',
      }));
      setDisplayValues(prevValues => ({
        ...prevValues,
        [keys]: suggestedAddress
      }));
      setPropertyAddressValidation("");
      setEditingAddress(false);
    }
    setShowAddress(false);
  };






  const handlePopupClose = () => {
    setSuccessPopup(false);
    setSelectedEmail('');
    setOpenPdf(false);
    localStorage.removeItem("companyofEditedEmail");
    localStorage.removeItem("claimIDofEditedEmail")
    setPropertyAddressValidation("");

  };


  const handleEmailDocumentClose = () => {
    setOpenPdf(false);
    setEnableFields(false);
  }

  // end of email edit functionality

  const handleViewChange = (newView) => {
    setView(newView);
  };

  const handleDocumentView = async (email) => {
    console.log("Email View for email:", email);
    setLoadingDocuments((prevLoading) => ({ ...prevLoading, [email]: true })); // Correct loading state

    try {
      const response = await axiosInstance.post(
        'get_email_to_fnol_documents/',
        {
          email: email,
          status: view, // Pass the current view (success, failure, etc.)
        }
      );

      console.log("Document API Response: email document", response.data);

      if (response.data.content) {
        setDocumentContent(response.data.content);
        setDocumentType(response.data.content_type);
        setDocumentName(response.data.document_name);
        setOpenDocument(true);
      } else {
        console.error("Error fetching document: No content received");
      }
    } catch (error) {
      console.error("Error fetching document:", error);
      // Display error message in Snackbar
      setSnackbarSeverity("error");
      if (error.response && error.response.data && error.response.data.error) {
        setSnackbarMessage(error.response.data.error);
      } else {
        setSnackbarMessage("An error occurred while fetching the document.");
      }
      setSnackbarOpen(true);
    } finally {
      setLoadingDocuments((prevLoading) => ({ ...prevLoading, [email]: false })); // Correct loading state
    }
  };

  const handleCloseDocument = () => {
    setOpenDocument(false);
    setDocumentContent(null);
    setDocumentType(null);
    setDocumentName("");
    setPropertyAddressValidation("");
  };

  const getDocumentUrl = () => {
    if (documentType === 'pdf') {
      const pdfBytes = Uint8Array.from(atob(documentContent), c => c.charCodeAt(0));
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      return URL.createObjectURL(blob);
    } else if (documentType === 'html') {
      return `data:text/html;base64,${btoa(documentContent)}`;
    } else {
      return null;
    }
  };

  const handleEmailView = async (email, email_time, subject, body) => {
    console.log("Email View for email:", email, email_time, subject, body);
    setLoadingEmailView((prevLoading) => ({ ...prevLoading, [email]: true }));
    try {
      const response = await axiosInstance.post(
        'get_email_to_fnol_documents/',
        {
          email: email,
          status: emailStatusType, // Use emailStatusType for context
        }
      );

      console.log("Document API Response:", response.data);

      // Always open Email View, even if document fetch fails
      setSelectedEmailData({
        email_id: email,
        email_time: email_time,
        subject: subject,
        body: body,
        document_name: response.data.success ? response.data.document_name : null, // Document name if successful, otherwise null
        content: response.data.success ? response.data.content : null, // Content if successful, otherwise null
        content_type: response.data.success ? response.data.content_type : null, // Content type if successful, otherwise null
      });
      setOpenEmailView(true);

    } catch (error) {
      console.error("Error fetching document:", error);

      // Still open Email View
      setSelectedEmailData({
        email_id: email,
        email_time: email_time,
        subject: subject,
        body: body,
        document_name: null, // No document in case of error
        content: null,
        content_type: null,
      });
      setOpenEmailView(true);

    } finally {
      setLoadingEmailView((prevLoading) => ({ ...prevLoading, [email]: false })); // Set back to false for the email
    }
  };

  const handleCloseEmailView = () => {
    setOpenEmailView(false);
    setSelectedEmailData(null);
  };

  const restrictedFields = [
    "Loss Location",
  ];

  const renderRows = (data) => {
    console.log("email data", data);

    if (Array.isArray(data) && data.length > 0) {
      return data.map((claim, index) => (
        <tr key={index}>
          <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>
            {claim.email_id}
          </td>
          <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>
            {claim.subject}
          </td>
          <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>
            {claim.email_time}
          </td>
          <td style={{ padding: "8px", textAlign: "left" }}>
        
            <MDBox display="flex" gap={1}>
            <ToolTip title="View Document" arrow placement="left">
            
              <MDButton
                variant="outlined"
                color="info"
                size="small"
                onClick={() => handleDocumentView(claim.email_id)} // Pass email_id 
                disabled={loadingDocuments[claim.email_id] || false}
                startIcon={
                  loadingDocuments[claim.email_id] && (
                    <CircularProgress size={16} color="inherit" />
                  )
                }
              >
                <Icon>description</Icon>
              </MDButton>
            </ToolTip>

              {view !== "failure" ? (
                  <ToolTip title="View Mail" arrow placement="right">
            
                <MDButton
                  variant="outlined"
                  color="info"
                  size="small"
                  onClick={() => handleEmailView(claim.email_id, claim.email_time, claim.subject, claim.body)}
                  disabled={loadingEmailView[claim.email_id] || false} // Use email loading
                  startIcon={
                    loadingEmailView[claim.email_id] && ( // Email loading indicator
                      <CircularProgress size={16} color="inherit" />
                    )
                  }
                >
                  <Icon>email</Icon>
                </MDButton>
                  </ToolTip>
              ) : (
                !isUnderwriter && (
                  <ToolTip title="Edit" arrow placement="right">
            
                  <MDButton
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={() => handleEditClaim(claim.email_id, view)}
                    disabled={loadingEdit[claim.email_id] || false} // Disable while loading
                    startIcon={
                      loadingEdit[claim.email_id] && ( // Loading indicator for edit button
                        <CircularProgress size={16} color="inherit" />
                      )
                    }
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                  </ToolTip>
                )
              )}
            </MDBox>
          </td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td
            colSpan="5"
            style={{ padding: "8px", textAlign: "center" }}
          >
            No Claims found.
          </td>
        </tr>
      );
    }
  };
  const getData = () => {
    if (view === "success") return claimsData.success;
    if (view === "failure") return claimsData.failure;
    if (view === "editedSuccess") return claimsData.editedSuccess;
    return [];
  };

  const companyData = JSON.parse(localStorage.getItem("companyofEditedEmail"));
  const claimID = localStorage.getItem("claimIDofEditedEmail")
  let imageSrc = `data:image/${companyData && companyData.image_type};base64,${companyData && companyData.image_data}`;

  return (
    <Card id="billing-info">
      <MDBox pt={3} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Claims Information
        </MDTypography>
        <MDBox display="flex" gap={2}>
          <MDButton
            variant="gradient"
            color={view === "success" ? "success" : "secondary"}
            onClick={() => handleViewChange("success")}
          >
            <Icon>check_circle</Icon> Success
          </MDButton>
          <MDButton
            variant="gradient"
            color={view === "failure" ? "error" : "secondary"}
            onClick={() => handleViewChange("failure")}
          >
            <Icon>error</Icon> Failure
          </MDButton>
          <MDButton
            variant="gradient"
            color={view === "editedSuccess" ? "warning" : "secondary"}
            onClick={() => handleViewChange("editedSuccess")}
          >
            <Icon>edit</Icon> Edited Success
          </MDButton>
        </MDBox>
      </MDBox>
      <MDBox pt={3} pb={2} px={2} sx={{ maxHeight: "300px", overflowY: "auto" }}> {/* Set max height and enable vertical scroll */}
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <table style={{ width: "100%", tableLayout: "fixed" }}>
            <thead>
              <tr>
                <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Email ID</th>
                <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Subject</th>
                <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Email Time</th>
                <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {renderRows(getData())}
            </tbody>
          </table>
        </MDBox>
      </MDBox>
      {/* email document view dialog */}
      <Dialog
        open={openDocument}
        onClose={handleCloseDocument}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>{documentName}</DialogTitle>
        <DialogContent>
          {documentType === "pdf" && (
            <embed
              src={getDocumentUrl()}
              width="100%"
              height="600px"
              type="application/pdf"
            />
          )}
          {documentType === "html" && (
            <div dangerouslySetInnerHTML={{ __html: documentContent }} />
          )}


        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDocument} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* // Add Snackbar component */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Adjust duration as needed
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Position at top right
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {/* Email View Dialog */}
      <Dialog
        open={openEmailView}
        onClose={handleCloseEmailView}
        fullWidth
        maxWidth="md"
        disableBackdropClick={true} // Prevent closing by clicking outside
      >
        <DialogTitle>Email View</DialogTitle>
        <DialogContent>
          {loadingEmailView[selectedEmailData?.email_id] && ( // Check loading for specific email
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
              <CircularProgress />
            </Box>
          )}

          {/* Only show EmailView when NOT loading for the specific email */}
          {!loadingEmailView[selectedEmailData?.email_id] && selectedEmailData && (
            <EmailView
              emailDetails={selectedEmailData}
              handleOpenDocumentViewer={() => {
                setDocumentContent(selectedEmailData.content);
                setDocumentType(selectedEmailData.content_type);
                setDocumentName(selectedEmailData.document_name);
                setOpenDocument(true);
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEmailView} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* start edit email dialog */}
      {/* PDF Viewer Dialog */}
      <Dialog open={openPdf} onClose={handleEmailDocumentClose} fullWidth maxWidth="xl">

        <Grid container style={{ display: "flex", justifyContent: "space-between" }}>
          <Grid item md={6}>
            <DialogTitle>{documentName}</DialogTitle>
            <DialogContent>
              {contentType === 'pdf' && (
                <embed src={pdfUrl} width="100%" height="600px" type="application/pdf" />
              )}
              {contentType === 'html' && (
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEmailDocumentClose} color="primary">
                Close
              </Button>
              {contentType === 'pdf' && pdfUrl && (
                <Button
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = pdfUrl;
                    link.download = documentName || 'document.pdf';
                    link.click();
                  }}
                  color="primary"
                >
                  <DownloadIcon /> Download PDF
                </Button>
              )}
            </DialogActions>
          </Grid>
          <Grid item md={6}>
            <DialogContent>

              <Grid container>

                <Grid className="idp-fetch-container">
                  <Typography
                    style={{
                      fontSize: "1.2rem",
                      color: "#010066",
                      fontWeight: "bold",
                      textAlign: 'center',
                      marginTop: '35px'
                    }}
                  >
                    Extracted claim FNOL details.
                  </Typography>
                  {hasEmptyOrInvalidValues && (
                    <Typography style={{ color: "red", marginBottom: "10px", textAlign: 'center' }}>
                      Please provide mandatory details in the document to claim
                      the policy.
                    </Typography>
                  )}


                  <Grid className="fetch-idp-data">

                    {!updateDisplay ? (
                      <>

                        <Typography variant="h5" className="ipd-titles Nasaliza">Policy Number</Typography>
                        <Grid container spacing={2} style={{ marginBottom: '7px', marginLeft: isMobile ? '0' : '15px' }}>
                          {Object.entries(displayValues)
                            .filter(([key]) => key === "Policy Number" || key === "Property Address")
                            .map(([key, value]) => (
                              <React.Fragment key={key}>
                                <Grid item xs={5} sm={5} md={5.5} style={{ fontWeight: 550, fontSize: 13 }}>
                                  {key}
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                  :
                                </Grid>
                                <Grid item xs={6} sm={6} md={5.5}>
                                  <span style={{ fontWeight: 500, fontSize: 13 }}>
                                    {value ? (
                                      value
                                    ) : (
                                      <span style={{ color: "red", fontWeight: 500, fontSize: 13 }}>
                                        {`${key} required`}
                                      </span>
                                    )}
                                  </span>
                                </Grid>
                              </React.Fragment>
                            ))}
                        </Grid>
                        <Typography variant="h5" className="ipd-titles Nasaliza">Loss Details</Typography>
                        <Grid container spacing={2} style={{ marginBottom: '7px', marginLeft: isMobile ? '0' : '15px' }}>
                          {Object.entries(displayValues)
                            .filter(([key]) => ["Loss Date and Time", "Loss Location", "Type of Loss", "Loss Damage Description"].includes(key))
                            .map(([keys, value]) => {
                              const isRestricted = restrictedFields.includes(keys);
                              const showSuggestedAddress = showAddress && suggestedAddress && validatedAddressKey === keys;
                              return (
                                <React.Fragment key={keys}>
                            <Grid item xs={5} sm={5} md={5.5} style={{ fontWeight: 550, fontSize: 13 }}>
                                  {keys}
                                  {isRestricted && keys === "Loss Location" && (
                                          <>
                                            {!editingAddress && propertyAddressValidation !== true && (
                                              <ToolTip title="Edit" arrow placement="bottom">
                                                <IconButton
                                                  size="small"
                                                  style={{ marginLeft: "0.5rem", color: "#010066" }}
                                                  onClick={() => {
                                                    setEditingAddress(true);
                                                    setPropertyAddressValidation(null);
                                                  }}
                                                >
                                                  <EditIcon fontSize="small" />
                                                </IconButton>
                                              </ToolTip>
                                            )}
                                            <ToolTip
                                              title={
                                                validatingAddress
                                                  ? "Validating..."
                                                  : propertyAddressValidation === "" && value
                                                    ? "Validated"
                                                    : "Not Validated"
                                              }
                                              arrow
                                              placement="bottom"
                                            >
                                              <IconButton size="small" style={{ marginLeft: "0.5rem", color: "#010066" }} >
                                                {validatingAddress ? (
                                                  <CircularProgress size={20} color="inherit" />
                                                ) : propertyAddressValidation === "" && value ? (
                                                  <ValidateIcon fontSize="small" color="success" />
                                                ) : (
                                                  <WarningIcon fontSize="small" color="warning" />
                                                )}
                                              </IconButton>

                                            </ToolTip>
                                            {editingAddress && propertyAddressValidation !== true && (
                                              <StyledButtonComponent
                                                buttonWidth={80}
                                                size="small"
                                                sx={{ marginLeft: 2 }}
                                                onClick={() => handleValidateAddress(keys, "Loss Location")}
                                                disabled={value === null}
                                              >
                                                Validate
                                              </StyledButtonComponent>
                                            )}
                                          </>
                                        )}
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                  :
                                </Grid>
                                <Grid item xs={6} sm={6} md={5.5}>
                                {keys === "Loss Location" ? (
                                          editingAddress ? (
                                            <TextField
                                              sx={{
                                                '& .MuiOutlinedInput-root': {
                                                  height: '35px',
                                                  backgroundColor: "none"
                                                },
                                              }}
                                              variant="outlined"
                                              required
                                              name={keys}
                                              value={displayValues[keys]}
                                              onChange={(e) => handleInputChange(keys, e.target.value)}
                                            />
                                          ) : (
                                            <span style={{textAlign: "left", fontWeight: 500, fontSize: 13 }}>
                                              {value ? (
                                                <>
                                                  {value}
                                                  {keys === "Loss Location" && propertyAddressValidation === null && !editingAddress ? (
                                                    <span style={{ color: "red", fontWeight: 500, fontSize: 12 }}>
                                                      <br />
                                                      Address not validated
                                                    </span>
                                                  ) : null}
                                                </>
                                              ) : (
                                                <span style={{ textAlign: "left", color: "red", fontWeight: 500, fontSize: 12 }}>
                                                  required
                                                </span>
                                              )}
                                            </span>
                                          )
                                        ) : enableFields ? (
                                          <TextField
                                            sx={{
                                              '& .MuiOutlinedInput-root': {
                                                height: '35px',
                                                backgroundColor: "none"
                                              },
                                            }}
                                            variant="outlined"
                                            required
                                            name={keys}
                                            value={displayValues[keys]}
                                            onChange={(e) => handleInputChange(keys, e.target.value)}
                                          />
                                        ) : (
                                          <span style={{ textAlign: "left", fontWeight: 500, fontSize: 13 }}>
                                            {value ? (
                                              value
                                            ) : (
                                              <span style={{ color: "red", fontWeight: 500, fontSize: 12 }}>
                                                required
                                              </span>
                                            )}
                                          </span>
                                        )
                                        }

                                      </Grid>
                                      {showSuggestedAddress && (
                                        <Grid container sx={{ mt: 1, alignItems: 'center', marginLeft: isMobile ? '0' : '20px' }}>
                                          <Grid item xs={6.5}></Grid>
                                          <Grid item xs={5.5} sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography variant="caption" sx={{ color: "#0B70FF", mr: 1 }}>
                                              {suggestedAddress}
                                            </Typography>
                                            <Checkbox
                                              color="primary"
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  handleConfirmAddress(spittedAddress, keys);
                                                }
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      )}
                                    </React.Fragment>
                                  )
                                })}
                        </Grid>
                        <Typography variant="h5" className="ipd-titles Nasaliza">Report Details</Typography>
                        <Grid container spacing={2} style={{ marginBottom: '7px', marginLeft: isMobile ? '0' : '15px' }}>
                          {Object.entries(displayValues)
                            .filter(([key]) => ["Reported By", "Report Number", "Police/Fire Department Contacted?"].includes(key))
                            .map(([key, value]) => (
                              <React.Fragment key={key}>
                                <Grid item xs={5} sm={5} md={5.5} style={{ fontWeight: 550, fontSize: 13 }}>
                                  {key}
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                  :
                                </Grid>
                                <Grid item xs={6} sm={6} md={5.5}>
                                  {enableFields ? (
                                    <TextField
                                      sx={{
                                        '& .MuiOutlinedInput-root': {
                                          height: '35px',
                                          backgroundColor: "none"
                                        },
                                      }}
                                      variant="outlined"
                                      required
                                      name={key}
                                      value={displayValues[key]}
                                      onChange={(e) => handleInputChange(key, e.target.value)}
                                    />
                                  ) : (
                                    <span style={{ fontWeight: 500, fontSize: 13 }}>
                                      {value ? (
                                        value
                                      ) : (
                                        <span style={{ color: "red", fontWeight: 500, fontSize: 13 }}>
                                          {`${key} required`}
                                        </span>
                                      )}
                                    </span>
                                  )}
                                </Grid>
                              </React.Fragment>
                            ))}
                        </Grid>

                      </>

                    ) : (
                      <></>
                    )}


                    <Grid container justifyContent="center" alignItems="center" style={{ margin: "3rem 1px" }} spacing={1}>
                      <Grid item>
                        {enableFields ? (
                          <ToolTip title="Save" arrow placement="right">

                            <StyledButtonComponent buttonWidth={100}
                              onClick={handleSave}
                            >
                              Save
                            </StyledButtonComponent>
                          </ToolTip>
                        ) : (
                          <ToolTip title="Edit" arrow placement="right">
                            <StyledButtonComponent buttonWidth={100}
                              onClick={() => setEnableFields(true)}
                              startIcon={<EditIcon />}
                            >
                              Edit
                            </StyledButtonComponent>
                          </ToolTip>
                        )}
                      </Grid>
                      <Grid item>
                        <StyledButtonComponent buttonWidth={150} disableColor={"#B6E3FF"}
                          onClick={() => handleExtractClaimSubmit(displayValues, queryvalues)}
                          disabled={hasEmptyOrInvalidValues || enableFields || editingAddress}>
                          Submit Claim
                        </StyledButtonComponent>
                      </Grid>
                      <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={processSubmit}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
        </Grid>

      </Dialog>
      {/* end of the edit email dialog */}

      {/* start of the success popup  */}
      {successPopup &&
        <Dialog open={successPopup} onClose={handlePopupClose} fullWidth maxWidth="md" PaperProps={{ style: { maxHeight: '80vh' } }}>
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ alignItems: 'center', mb: 10, mt: 4 }}>
              {companyData.ic_logo_path && (
                <img
                  src={imageSrc}
                  alt="Insurance Company Logo"
                  style={{ width: isMobile ? '80%' : '35%' }} // Adjust width for mobile
                />
              )}
            </Box>

            {isMobile && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <CheckCircleIcon sx={{ fontSize: 60, color: 'green' }} />
              </Box>
            )}

            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                  {!isMobile && (
                    <CheckCircleIcon sx={{ fontSize: 40, mr: 2, color: 'green' }} />
                  )}
                  <Typography className='Nasaliza' style={{ fontSize: isMobile ? '1rem' : '1.5rem' }}>
                    Claim Submitted Successfully!
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6' sx={{ mb: 2, textAlign: 'center' }} className='Nasaliza'>
                  Claim ID:{' '}
                  <span style={{ color: companyData?.ic_primary_color || '#0B70FF' }}>
                    {claimID && claimID}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <DialogActions>
            <Button onClick={handlePopupClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      }
      {/* end of the success popup */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
          {validationError}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </Card>
  );
}

export default EmailtoFNOLClaimsView;
