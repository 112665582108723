import React, { useState } from 'react';
import PropTypes from "prop-types";
import {Card, Grid,  useTheme, useMediaQuery,} from "@mui/material";
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";
import MDBox from '../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDBox';
import MDTypography from '../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDTypography';
import MDButton from '../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDButton';
import { CircularProgress } from "@mui/material"; // Import CircularProgress
import axios from 'axios';
import DocAIQuoteBatchProcessView from './DocAIQuoteBatchProcessView';
import DocAIQuoteBatchQuotesView from './DocAIQuoteBatchQuotesView';

function DocAIQuoteBatchProcessCard({ title }) {
    const [expanded, setExpanded] = useState(false);
    const [quotesExpanded, setQuotesExpanded] = useState(false);
    const [batchDocAIQuotes, setBatchDocAIQuotes] = useState({ success: [], failure: [], editedSuccess: [] });
    const [batchDocAIQuotesCount, setBatchDocAIQuotesCount] = useState({ success: 0, failure: 0, editedSuccess: 0 });
    const [reportData, setReportData] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isBatchLoading, setBatchIsLoading] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_URL,
        withCredentials: true
      });
  
    const toggleExpand = () => {
        if(quotesExpanded === true){
            setQuotesExpanded(false);
        }         
        setExpanded(!expanded);
    };

    const toggleQuotesExpans = () => {
        if(expanded === true){
            setExpanded(false);
        }         
        setQuotesExpanded(!quotesExpanded);
    }

    const handleProcessBatchDocAIQuotes = async () => {
        setBatchIsLoading(true);
        try {
            const ic_id = localStorage.getItem("ic_id_for_dashboard");
            if (!ic_id) {
                console.error("ic_id is missing in localStorage");
                setBatchIsLoading(false);
                return;
            }
            const formData = new FormData();
            formData.append("ic_id", ic_id);
            const response = await axiosInstance.post(
                'Batch_processes/DocAI_Batch_Quote/', formData
            );
            console.log("after batch process response", response, response.data);
            if (response && response.data) {
                setReportData(response.data);
            } else {
                console.error("Unexpected response structure:", response);
            }
        } catch (error) {
            console.error("Error processing batch DocAI quotes:", error.message, error.response?.data);
        } finally {
            setBatchIsLoading(false);
            setExpanded(prevExpanded => !prevExpanded);
        }
    };

    const handleFetchBatchDocAIQuotes = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.post('Batch_processes/fetch_docai_batch_quote/');
            console.log("batch process quotes", response, response.data, response.data.success_quotes.length);
            if (response.status === 200) {
                setBatchDocAIQuotes({
                    success: response.data.success_quotes,
                    failure: response.data.failure_quotes,
                    editedSuccess: response.data.edited_success_quotes,
                });
                const success_count = response.data.success_quotes.length;
                const failure_count = response.data.failure_quotes.length;
                const edited_success_count = response.data.edited_success_quotes.length;
                setBatchDocAIQuotesCount({
                    success: success_count,
                    failure: failure_count,
                    editedSuccess: edited_success_count,
                });
            } else {
                console.error("Error fetching batch quotes (non-200 status):", response);
            }
        } catch (error) {
            console.error("Error fetching batch quotes:", error);
        } finally {
            setIsLoading(false);
        }
    };



    return (
        <Card id="delete-account">
            <MDBox pt={2} pb={2} px={2}>
                <Grid container spacing={2} alignItems="center">
                    {/* Title aligned to the left */}
                    <Grid item xs={isMobile ? 12 : 3}>
                        <MDTypography variant="h6" fontWeight="medium" sx={{ fontSize: '16px', textAlign: 'left' }}>
                            {title}
                        </MDTypography>
                    </Grid>

                    {/* Buttons aligned to the left */}
                    <Grid item xs={isMobile ? 12 : 6}>
                        <MDBox display="flex" flexDirection={isMobile ? "column" : "row"} alignItems="center" gap={1}>
                            <MDButton
                                variant="gradient"
                                color="info"
                                onClick={handleProcessBatchDocAIQuotes}
                                sx={{ display: 'flex', alignItems: 'center', minWidth: isMobile ? 250 : 100, fontSize: '11px', padding: '6px 8px' }}
                                disabled={isBatchLoading}
                            >
                                {isBatchLoading && <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />}
                                {isBatchLoading ? 'Processing...' : 'Process Batch'}
                            </MDButton>

                            <MDButton
                                variant="gradient"
                                color="dark"
                                onClick={toggleExpand}
                                sx={{ display: 'flex', alignItems: 'center', minWidth: isMobile ? 250 : 100, fontSize: '11px', padding: '6px 8px', textTransform: 'none' }}
                            >
                                <Icon sx={{ fontSize: '20px' }}>{expanded ? "expand_less" : "expand_more"}</Icon>
                                {expanded ? "Hide Result" : "View Result"}
                            </MDButton>

                            <MDButton
                                variant="gradient"
                                color="info"
                                onClick={handleFetchBatchDocAIQuotes}
                                sx={{ display: 'flex', alignItems: 'center', minWidth: isMobile ? 250 : 100, fontSize: '11px', padding: '6px 8px' }}
                                disabled={isLoading}
                            >
                                {isLoading && <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />}
                                {isLoading ? 'Fetching...' : 'Fetch Quotes'}
                            </MDButton>

                            <MDButton
                                variant="gradient"
                                color="dark"
                                onClick={toggleQuotesExpans}
                                sx={{ display: 'flex', alignItems: 'center', minWidth: isMobile ? 250 : 100, fontSize: '11px', padding: '6px 8px', textTransform: 'none' }}
                            >
                                <Icon sx={{ fontWeight: "bold", fontSize: '12px' }}>{quotesExpanded ? "expand_less" : "expand_more"}</Icon>
                                &nbsp; {quotesExpanded ? "Hide Quotes" : "View Quotes"}
                            </MDButton>
                        </MDBox>
                    </Grid>

                    {/* Success, Failure, and Edited Success aligned to the right */}
                    <Grid item xs={isMobile ? 12 : 3}>
                        <MDTypography variant="body2" color="textSecondary" fontWeight="medium" sx={{ fontSize: '13px', textAlign: isMobile ? "left" : 'right' }}>
                            Success: {batchDocAIQuotesCount.success} &nbsp; Failure: {batchDocAIQuotesCount.failure} &nbsp; Edited Success: {batchDocAIQuotesCount.editedSuccess}
                        </MDTypography>
                    </Grid>
                </Grid>
            </MDBox>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <MDBox p={2}>
                    <DocAIQuoteBatchProcessView reportData={reportData} />
                </MDBox>
            </Collapse>
            <Collapse in={quotesExpanded} timeout="auto" unmountOnExit>
                <MDBox p={2}>
                    <DocAIQuoteBatchQuotesView quotesData={batchDocAIQuotes} />
                </MDBox>
            </Collapse>
        </Card>
    );
}

DocAIQuoteBatchProcessCard.defaultProps = {
    title: "DocAI Quote Batch Process",
};

DocAIQuoteBatchProcessCard.propTypes = {
    title: PropTypes.string,
};

export default DocAIQuoteBatchProcessCard;
