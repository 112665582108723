import React, { useState, useCallback, useRef } from 'react';
import { Grid, Box, Paper, Typography, CircularProgress, useMediaQuery, useTheme, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Tooltip, Dialog, DialogActions, DialogContent, Button, DialogTitle, Alert, Snackbar} from '@mui/material';
import FileUpload from '../../components/fileupload';
import StyledButtonComponent from '../../components/StyledButton';
import processclaim from "../../assets/processclaim.png";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import useNetworkStatus from '../../components/ErrorPages/UseNetworkStatus.js';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import GetAppIcon from '@mui/icons-material/GetApp';

const CustomActiveShapePieChart = ({ successDocuments, workQueueDocuments, anonymousWorkQueueDocuments, uploadedDocuments, totalProcessedDocuments }) => {
    console.log(successDocuments, workQueueDocuments, uploadedDocuments, totalProcessedDocuments);
    const [activeIndex, setActiveIndex] = useState(0);
    const successPercentage = totalProcessedDocuments ? (successDocuments && successDocuments / totalProcessedDocuments) * 100 : 0;
    const workQueuePercentage = totalProcessedDocuments ? (workQueueDocuments / totalProcessedDocuments) * 100 : 0;
    const anonymousWorkQueuePercentage = totalProcessedDocuments ? (anonymousWorkQueueDocuments / totalProcessedDocuments) * 100 : 0;
    const data = totalProcessedDocuments > 0
        ? [
            { name: 'Success', value: successPercentage, count: successDocuments },
            { name: 'WorkQueue', value: workQueuePercentage, count: workQueueDocuments },
            { name: 'Anonymous WorkQueue', value: anonymousWorkQueuePercentage, count: anonymousWorkQueueDocuments }, // Include Anonymous WorkQueue
        ]
        : [{ name: 'Not Uploaded Documents', value: 100, count: 0 }];

    const COLORS = totalProcessedDocuments > 0 ? ['#4CAF50', '#ffc107', '#F44336'] : ['#2196F3'];
    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const {
            cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
            fill, payload, value,
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';
        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                {totalProcessedDocuments > 0 ? (
                    <>
                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value.toFixed(2)}%`}</text>
                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                            {`(${payload.count} docs)`}
                        </text>
                    </>
                ) : (
                    <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#999">
                        {`(${payload.count} docs)`}
                    </text>
                )}
            </g>
        );
    };
    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };
    return (
        <Box>
            <Typography variant="h6" style={{ textAlign: 'center', marginBottom: 16, color: '#001660', fontSize: '1.3rem' }} className="Nasaliza">
                DocAI Classify Status Distribution
            </Typography>
            <Grid container justifyContent="center" alignItems="center" spacing={2} style={{ marginBottom: 16 }}>
                <Grid item>
                    <Typography style={{ fontSize: "0.8rem" }} color="#010066" fontWeight="bold">
                        Uploaded : {uploadedDocuments}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography style={{ fontSize: "0.8rem" }} color="#2196f3" fontWeight="bold">
                        Success : {successDocuments}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography style={{ fontSize: "0.8rem" }} color="#ffc107" fontWeight="bold">
                        WorkQueue : {workQueueDocuments}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography style={{ fontSize: "0.8rem" }} color="#f44336" fontWeight="bold">
                        Anonymous WorkQueue : {anonymousWorkQueueDocuments}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography style={{ fontSize: "0.8rem" }} color="yellowgreen" fontWeight="bold">
                        Total Processed : {totalProcessedDocuments}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Grid item>
                    <Box>
                        <ResponsiveContainer width={400} height={280}>
                            <PieChart>
                                <Pie
                                    activeIndex={activeIndex}
                                    activeShape={renderActiveShape}
                                    data={data}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={50}
                                    outerRadius={70}
                                    fill="#8884d8"
                                    dataKey="value"
                                    onMouseEnter={onPieEnter}
                                >
                                    {data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} stroke="none" />
                                    ))}
                                </Pie>
                                <Tooltip formatter={(value, name, props) => {
                                    if (name === 'Not Uploaded Documents') {
                                        return [`${props.payload.count} docs`, name];
                                    }
                                    return [`${value.toFixed(2)}% (${props.payload.count} docs)`, name];
                                }} />
                            </PieChart>
                        </ResponsiveContainer>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

const DocAIClassifyFullFun = () => {
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [showSnackbar, setShowSnackbar] = useState(false); // For success message
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeviarity, setSnackbarSeviarity] = useState("success");
    const [loading, setLoading] = useState(false);
    const [uploadedCount, setUploadedCount] = useState(0); // New state for tracking uploaded count
    const [processedData, setProcessedData] = useState(null);
    const [open, setOpen] = useState(false);  // Document dialog state
    const [selectedFileContent, setSelectedFileContent] = useState(null); // For content
    const [documentName, setDocumentName] = useState("");   // Name of the document
    const [contentType, setContentType] = useState(null);   // Content type (pdf, html)
    const fileUploadRef = useRef(null); // For the FileUpload component    const fileUploadRef = useRef(null);
    const [docAIStats, setDocAIStats] = useState({  // Use state for chart data
        successDocuments: 0,
        workQueueDocuments: 0,
        totalProcessedDocuments: 0,
        anonymousWorkQueueDocuments: 0,
    }); const navigate = useNavigate();
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_URL,
        withCredentials: true
    });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleFilesUpload = (selectedFiles, previews) => {
        setUploadedDocuments(selectedFiles);
        setUploadedCount(selectedFiles.length); // Set the uploaded count
        setIsSubmitDisabled(false);
        setProcessedData(null); // Clear previous data
        setDocAIStats({
            successDocuments: 0,
            workQueueDocuments: 0,
            totalProcessedDocuments: 0,
            anonymousWorkQueueDocuments: 0,
        });
    }
    const handleFileRemove = (fileToRemove) => {
        const updatedFiles = uploadedDocuments.filter(file => file !== fileToRemove);
        setUploadedDocuments(updatedFiles);
        setUploadedCount(updatedFiles.length); // Update the uploaded count
        if (updatedFiles.length === 0) {
            setIsSubmitDisabled(true);
        }
    }
    const handleNetworkError = useCallback((path) => {
        navigate(path);
    }, [navigate]);
    const { setNetworkError } = useNetworkStatus({}, handleNetworkError);
    const processDocuments = async () => {
        if (!uploadedDocuments || uploadedDocuments.length === 0) {
            alert("Please upload the documents first.");
            return;
        }
        try {
            setLoading(true);
            const formData = new FormData();
            for (const file of uploadedDocuments) {
                formData.append('files', file);
            }
            
            const response = await axiosInstance.post('AI/process_docaiclassify_files/', formData);
            
            if (response.data) {
                setProcessedData(response.data);
                setDocAIStats({
                    successDocuments: response.data.success_records.length,
                    workQueueDocuments: response.data.workqueue_records.length,
                    anonymousWorkQueueDocuments: response.data.anonymous_workqueue_records.length,
                    totalProcessedDocuments: response.data.success_records.length + 
                                           response.data.workqueue_records.length + 
                                           response.data.anonymous_workqueue_records.length,
                });
                setSnackbarMessage("Documents processed successfully!");
                setSnackbarSeviarity("success");
                setShowSnackbar(true);
            } else {
                setSnackbarMessage("Error while processing documents");
                setSnackbarSeviarity("error");
                setShowSnackbar(true);
            }
        } catch (error) {
            console.error("error in processing documents", error);
            setSnackbarMessage("Error processing documents");
            setSnackbarSeviarity("error");
            setShowSnackbar(true);
        } finally {
            setLoading(false);
            setIsSubmitDisabled(true);
            // Clear uploaded files from state AND the UI
            setUploadedDocuments([]);
            if (fileUploadRef.current) {
                fileUploadRef.current.handleClearAllFiles();
            }
        }
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedFileContent(null); // Clear content on close
        setDocumentName("");
        setContentType(null);
    };


    const KeyValueTable = ({ record, status }) => {



        const { file_name, file_classification_name, index_data, document_content, content_type } = record;

        const formattedIndexData = Object.entries(index_data).map(([key, value]) => ({
            key: key.replace(/_/g, ' '),
            value
        }));

        const statusIcon = (
            <Tooltip title={status}> {/* Tooltip for status icon */}
                {status === "Success" && <CheckCircleOutlineIcon sx={{ color: 'green' }} />}
                {status === "WorkQueue" && <WarningAmberIcon sx={{ color: 'orange' }} />}
                {status === "Anonymous WorkQueue" && <ErrorOutlineIcon sx={{ color: 'red' }} />}
            </Tooltip>
        );

        const handleViewDocument = () => {
            setSelectedFileContent(document_content); // Store content directly
            setDocumentName(file_name);
            setContentType(content_type);
            setOpen(true);
        };

        return (
            <TableRow>
                <TableCell sx={{ maxWidth: '50px', verticalAlign: 'middle' }}>
                    {statusIcon}  {/* Render the status icon with tooltip */}
                </TableCell>
                <TableCell sx={{ fontSize: '0.8rem', wordBreak: 'break-all', maxWidth: '150px', verticalAlign: 'middle', overflow: 'hidden', textOverflow: 'ellipsis' }}>{file_name}</TableCell>
                <TableCell sx={{ fontSize: '0.8rem', wordBreak: 'break-all', maxWidth: '150px', verticalAlign: 'middle', overflow: 'hidden', textOverflow: 'ellipsis' }}>{file_classification_name}</TableCell>
                {formattedIndexData.map(({ key, value }, index) => (
                    <TableCell key={index} sx={{ fontSize: value?.length > 15 ? '0.8rem' : '0.8rem', wordBreak: 'break-word', verticalAlign: 'middle', maxWidth: '150px' }}> {/* Adjusted styles */}
                        <Tooltip title={key}>
                            <span style={{ whiteSpace: 'pre-wrap' }}>{value}</span> {/* Preserve whitespace */}
                        </Tooltip>
                    </TableCell>
                ))}
                {[...Array(7 - formattedIndexData.length)].map((_, index) => (
                    <TableCell key={index + formattedIndexData.length} sx={{ verticalAlign: 'middle' }} > <Tooltip title="Not Applicable"> {/* Tooltip for "-" */}
                        -
                    </Tooltip></TableCell>
                ))}
                <TableCell sx={{ verticalAlign: 'middle' }}>
                    <Tooltip title="View Document">
                        <IconButton onClick={handleViewDocument}>
                            <DescriptionIcon sx={{ color: '#2196f3' }} />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        );
    };



    return (
        <Box sx={{ padding: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Paper
                        elevation={3}
                        sx={{
                            width: "100%",
                            maxWidth: 1000,
                            margin: "auto",
                            height: isMobile ? "" : "430px",
                            mt: 9,
                            borderRadius: 3,
                            p: 4,
                            backdropFilter: "blur(10px)", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)",
                            border: "1px solid rgba(255, 255, 255, 0.2)",
                            background: 'transparent',
                        }}
                    >
                        <Typography sx={{ color: '#001660', fontSize: '1.3rem' }} className="Nasaliza">Upload Documents</Typography>
                        <FileUpload
                        ref={fileUploadRef}
                            id="portal"
                            onFilesUpload={handleFilesUpload}
                            multiple={true}
                            allowedFormats={["pdf"]}
                            setIsSubmitDisabled={setIsSubmitDisabled}
                            onFileRemove={handleFileRemove}
                        />
                        <Box sx={{ textAlign: 'center', marginTop: 2 }}>
                            <StyledButtonComponent
                                variant="contained"
                                color="primary"
                                onClick={processDocuments}
                                buttonWidth={150}
                                disabled={isSubmitDisabled || uploadedDocuments.length === 0 || loading}
                                startIcon={loading ? <CircularProgress size={24} color="inherit" /> : <img src={processclaim} alt="process documents" style={{ height: 24 }} />}
                            >
                                {loading ? 'Processing...' : 'Process'}
                            </StyledButtonComponent>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper
                        elevation={3}
                        sx={{
                            width: "100%",
                            maxWidth: 1000,
                            margin: "auto",
                            height: isMobile ? "" : "430px",
                            mt: 9,
                            borderRadius: 3,
                            p: 4,
                            backdropFilter: "blur(10px)", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)",
                            border: "1px solid rgba(255, 255, 255, 0.2)",
                            background: 'transparent',
                        }}
                    >
                        <CustomActiveShapePieChart
                            successDocuments={docAIStats.successDocuments}  // Use values from state
                            workQueueDocuments={docAIStats.workQueueDocuments}
                            uploadedDocuments={uploadedCount}
                            totalProcessedDocuments={docAIStats.totalProcessedDocuments}
                            anonymousWorkQueueDocuments={docAIStats.anonymousWorkQueueDocuments}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={12}>
                    {processedData && (
                        <Paper elevation={3} sx={{ p: 3, borderRadius: 3, boxShadow: 3, background: 'white' }}> {/* Improved background and box-shadow */}
                            <Typography variant="h5" align="center" className='Nasaliza' gutterBottom sx={{ color: '#010066', fontWeight: 'bold', mb: 3 }}> {/* Darker heading color, bold */}
                                Processed Documents Result
                            </Typography>


                            <TableContainer component={Paper} sx={{ background: 'transparent', boxShadow: "none", border: "none", mb: 4, maxWidth: '100%', overflowX: 'auto' }}> {/* Added overflowX */}
                                <Table sx={{ tableLayout: 'fixed', width: '100%' }}>  {/* Set width to 100% */}
                                    <TableHead sx={{ bgcolor: '#2196f3', color: 'white' }}>
                                        <TableRow>
                                            <TableCell sx={{ color: 'white', width: '5%' }}>Status</TableCell> {/* Set width for Status */}
                                            <TableCell sx={{ color: 'white', width: '10%' }}>File Name</TableCell> {/* Set width for File Name */}
                                            <TableCell sx={{ color: 'white', width: '10%' }}>Classification</TableCell> {/* Set width for Classification Type */}
                                            {[...Array(7)].map((_, index) => (
                                                <TableCell key={index} sx={{ color: 'white', width: '8%' }}>Index {index + 1}</TableCell> // Set width for Index columns
                                            ))}

                                            <TableCell sx={{ color: 'white', width: '5%' }}>View</TableCell> {/* Set width for View */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {processedData.success_records?.length > 0 && processedData.success_records.map((record, index) => (
                                            <KeyValueTable key={index} record={record} status="Success" />
                                        ))}

                                        {processedData.workqueue_records?.length > 0 && processedData.workqueue_records.map((record, index) => (
                                            <KeyValueTable key={index} record={record} status="WorkQueue" />
                                        ))}

                                        {processedData.anonymous_workqueue_records?.length > 0 && processedData.anonymous_workqueue_records.map((record, index) => (
                                            <KeyValueTable key={index} record={record} status="Anonymous WorkQueue" />
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>


                        </Paper>
                    )}
                </Grid>
            </Grid>


            {/* Document View Dialog */}
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>{documentName}</DialogTitle>
                <DialogContent>

                    {contentType === "pdf" && (
                        <iframe
                            src={`data:application/pdf;base64,${selectedFileContent}`}
                            title="Selected Document"
                            width="100%"
                            height="500px"
                        />
                    )}
                    {contentType === "html" && (
                        <div dangerouslySetInnerHTML={{ __html: selectedFileContent }} />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} startIcon={<CloseIcon />}>
                        Close
                    </Button>

                    <Button
                        onClick={() => {
                            const link = document.createElement('a');
                            link.href = `data:application/pdf;base64,${selectedFileContent}`; // Or data:text/html,... for HTML
                            link.download = documentName;
                            link.click();
                        }}
                        startIcon={<GetAppIcon />}
                    >
                        Download
                    </Button>

                </DialogActions>
            </Dialog>
             {/* Snackbar for success and failure message */}
             <Snackbar
                open={showSnackbar}
                autoHideDuration={6000}
                onClose={() => setShowSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Position at top right
            >
                <Alert onClose={() => setShowSnackbar(false)} severity="success">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box >
    )
}

export default DocAIClassifyFullFun