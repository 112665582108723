import axios from 'axios';
import { useEffect, useState, useCallback } from 'react';
import { Snackbar, Alert } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';// Import warning icon

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: 'http://localhost:8000/emailApp/', // Adjust base URL as needed
});

const useNetworkStatus = (initialState = {}, onNetworkError, navigate) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [appState, setAppState] = useState(() => {
    const savedState = localStorage.getItem('appState');
    return savedState ? JSON.parse(savedState) : initialState;
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      const savedState = localStorage.getItem('appState');
      if (savedState) {
        setAppState(JSON.parse(savedState));
      }
    };

    const handleOffline = () => {
      setIsOnline(false);
      localStorage.setItem('appState', JSON.stringify(appState));
    };

    const handleBeforeUnload = () => {
      localStorage.setItem('appState', JSON.stringify(appState));
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [appState]);

  const updateAppState = useCallback((newState) => {
    setAppState((prevState) => {
      const updatedState = { ...prevState, ...newState };
      localStorage.setItem('appState', JSON.stringify(updatedState));
      return updatedState;
    });
  }, []);

  const setNetworkError = useCallback(({ 
    errorMessage, 
    errorSource, 
    fileName, 
    fileType, 
    status, 
    emails = [], 
    documents = [] 
  }) => {
    const error = errorMessage;
  
    const userName = localStorage.getItem('userName') || 'Anonymous';
  
    updateAppState({ 
      networkError: error,
      failedEmails: emails,
      failedDocuments: documents
    });
    
    axiosInstance.post('sendemail/', 
      { 
        errorMessage: error, 
        errorSource,
        username: userName,
        fileName,
        fileType,
        failedEmails: emails,
        failedDocuments: documents,
        timestamp: new Date().toISOString()
      }
    )
    .then((response) => {
      console.log('Email sent successfully:', response.data);
    })
    .catch((error) => {
      console.error('Error sending email:', error);
      localStorage.setItem('failedEmailReport', JSON.stringify({
        emails,
        documents,
        error,
        timestamp: new Date().toISOString()
      }));
    });

    // Handle specific error statuses and trigger the snackbar
    if (status === 500) {
      onNetworkError('/databaseerror'); // Navigate to database error page
    } else if (status === 404) {
      setSnackbar({ open: true, message:  errorMessage});
      setTimeout(() => {
        setSnackbar({ open: false, message: '' });
      }, 10000);
    } else if (status === 400) {
      setSnackbar({ open: true, message: "Error 400: Invalid File Type." });
       
    } else if (status === 300) {
      setSnackbar({ open: true, message: "Status 300: Redirection needed." });
      // Simply return to allow the main component to continue processing
      return; 
    }else { 
      setSnackbar({ open: true, message: "Sorry for the inconvenience, Please Try after some time" }); 
    }
  }, [updateAppState, onNetworkError, navigate]);

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '' });
  };

  // Snackbar UI component
  const SnackbarComponent = () => (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={3000}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Position to top-center
    >
      <Alert
        onClose={handleCloseSnackbar}
        severity="warning"
        icon={<WarningAmberIcon />} // Use warning icon
        sx={{ backgroundColor: '#FFDE21', color: 'black' }}
      >
        {snackbar.message}
      </Alert>
    </Snackbar>
  );

  return { 
    isOnline, 
    appState, 
    updateAppState, 
    setNetworkError,
    SnackbarComponent // Export snackbar component to be rendered in the main component
  };
};

export default useNetworkStatus;
