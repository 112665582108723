
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDBox";
import MDTypography from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDTypography";


 

function InsurAIClaimsView({ claimsData }) {
  const renderRows = (data) => {
    if (Array.isArray(data) && data.length > 0) {
      return data.map((claim, index) => (
        <tr key={claim.claim_id}>
          <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{claim.claim_id}</td>
          <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{claim.claim_created_at}</td>
          <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{claim.policy_holder_name}</td>
          <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{claim.policy_number}</td>
          <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{claim.loss_date_and_time}</td>
          <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{claim.loss_location}</td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan="6" style={{ padding: "8px", textAlign: "center" }}>
            No Claims found.
          </td>
        </tr>
      );
    }
  };

  return (
    <Card id="billing-info">
    <MDBox pt={3} px={2} display="flex" justifyContent="space-between" alignItems="center">
      <MDTypography variant="h6" fontWeight="medium">
        Claims Information
      </MDTypography>
    </MDBox>
    <MDBox pt={1} pb={2} px={2} sx={{ maxHeight: "300px", overflowY: "auto" }}>
      <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
        <table style={{ width: "100%", tableLayout: "fixed" }}>
          <thead>
            <tr>
              <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Claim Number</th>
              <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Claim Created At</th>
              <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Policy Holder Name</th>
              <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Policy Number</th>
              <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Loss Date and Time</th>
              <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Loss Location</th>
            </tr>
          </thead>
          <tbody>
            {renderRows(claimsData)} 
          </tbody>
        </table>
      </MDBox>
    </MDBox>
  </Card>
  );
}

export default InsurAIClaimsView;
